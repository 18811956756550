import { Form, Select, Input, Button } from "antd";
import { useEffect, createRef } from "react";
import { toast } from "react-toastify";
import { addNotaCreditoApi } from "../../../../api/contabilidaApi";
import { tipoNc, meses } from "../../../../helpers/datos";

export default function AddNotasForm(props) {
  const { setIsModalOpened, setReloadPage, reloadPage } = props;

  const formRef = createRef();

  // console.log(registro);
  useEffect(() => {
    formRef.current.resetFields();
  }, [setIsModalOpened, formRef]);

  const onSubmit = (formData) => {
    // console.log(formData);
    if (!formData.concepto || !formData.mes || !formData.monto) {
      toast("Todos los campos son requeridos");
    } else {
      addNotaCreditoApi({
        descripcion: formData.concepto + " " + formData.mes,
        mes: formData.mes,
        monto: formData.monto,
        tipo: formData.concepto,
        subtipo: ["Home Care", "Personal Care", "Oral Care"],
      }).then((resp) => {
        toast("Se ha agregado correctmanete.");
        setIsModalOpened(false);
        setReloadPage(!reloadPage);
      });
    }
  };

  return (
    <div className="m-auto">
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size={"large"}
        onFinish={onSubmit}
        ref={formRef}
        className="m-2"
      >
        <Form.Item name="concepto" label="Concepto">
          <Select>
            {tipoNc?.map((item) => (
              <Select.Option key={item.value} value={item.label}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="mes" label="Mes">
          <Select>
            {meses?.map((item) => (
              <Select.Option key={item.value} value={item.label}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="monto" label="Monto">
          <Input />
        </Form.Item>
        <Form.Item className="m-0">
          <Button
            className="bg-blue-800 text-2xl font-bold"
            type="primary"
            block
            htmlType="submit"
          >
            Agregar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
