//layout
import PrivateRoute from "../layouts/LayoutAdmin";
import PublicRoute from "../layouts/LayoutPublic";

// import RegisterPage from "../pages/PublicPages/Register";

//Paginas
import { HomePages, ConsultaArticulo, QRGenerator } from "../pages/PublicPages";

// import {} from "../pages/PublicPages";

//Pagina Administrativa o de Gestion

import Nacho from "../pages/AdminPages/Distribuccion/Dashboard";
import QueVamosHAcer from "../components/Gestion/Distribuccion/QueVamosHacer";
import ListadoDeCliente from "../components/Gestion/Distribuccion/ListadoClientes";
import ListadoProductos from "../components/Gestion/Distribuccion/ListadoProductos";
import NacherosProcesar from "../components/Gestion/Distribuccion/ResumenProcesar";

import DashboardPage from "../pages/AdminPages/Dashboard/DashboardPage";
import AlmacenPage from "../pages/AdminPages/Almacen";
import AlmacenDashboard from "../pages/AdminPages/Almacen/Dashboard";
import AgendarPage from "../pages/AdminPages/Almacen/Agendar";
import FacturacionPage from "../pages/AdminPages/Almacen/Facturacion";
import DespacharPage from "../pages/AdminPages/Almacen/Despachar";
import RecibirPage from "../pages/AdminPages/Almacen/EnAlmacen";
import EnRuta from "../pages/AdminPages/Almacen/Enruta";
import Devolucion from "../pages/AdminPages/Almacen/Devoluciones";
import FichasPages from "../pages/AdminPages/Almacen/Mantenimientos/FichasPages";
import RepartidoresPages from "../pages/AdminPages/Almacen/Mantenimientos/RepartidoresPages";
import Contabilidad from "../pages/AdminPages/Contabilidad";
import Facturas from "../pages/AdminPages/Contabilidad/FacturasEnAlmacen/Facturas";
import { NotasCreditos } from "../pages/AdminPages/Contabilidad/NotasDeCreditos/NotasCreditos";
import NotasCreditosAplicada from "../pages/AdminPages/Contabilidad/NotasDeCreditos/NotasAplicadas/NotasAplicadas";

import LoginPage from "../pages/AdminPages/SignIn";

import UsersPage from "../pages/AdminPages/Usuarios/UserPage";
import Perfil from "../pages/AccountPage/";

//Other Not Found
import { NotFoundPage } from "../pages/NotFound";

const routesAdmin = [
  {
    path: "/admin",
    layout: PrivateRoute,
    component: DashboardPage,
  },
  {
    path: "/admin/login",
    layout: PrivateRoute,
    component: LoginPage,
    exact: true,
  },

  // {
  //   path: "/admin/dashboard",
  //   layout: PrivateRoute,
  //   component: DashboardPage,
  //   exact: true,
  // },
  {
    path: "/admin/almacen/consulta",
    layout: PrivateRoute,
    component: AlmacenPage,
    exact: true,
  },
  {
    path: "/admin/almacen/",
    layout: PrivateRoute,
    component: AlmacenDashboard,
    exact: true,
  },
  {
    path: "/admin/almacen/facturacion",
    layout: PrivateRoute,
    component: FacturacionPage,
    exact: true,
  },
  {
    path: "/admin/almacen/agendar",
    layout: PrivateRoute,
    component: AgendarPage,
    exact: true,
  },
  {
    path: "/admin/almacen/despacho",
    layout: PrivateRoute,
    component: DespacharPage,
    exact: true,
  },
  {
    path: "/admin/almacen/enruta", //En ruta
    layout: PrivateRoute,
    component: EnRuta,
    exact: true,
  },
  {
    path: "/admin/almacen/enalmacen", //Facturas LLegaron almacen
    layout: PrivateRoute,
    component: RecibirPage,
    exact: true,
  },
  {
    path: "/admin/almacen/devolucion", //En ruta
    layout: PrivateRoute,
    component: Devolucion,
    exact: true,
  },
  {
    path: "/admin/almacen/fichas",
    layout: PrivateRoute,
    component: FichasPages,
    exact: true,
  },
  {
    path: "/admin/almacen/repartidores",
    layout: PrivateRoute,
    component: RepartidoresPages,
    exact: true,
  },
  {
    path: "/admin/contab",
    layout: PrivateRoute,
    component: Contabilidad,
    exact: true,
  },
  {
    path: "/admin/contab/facturacion",
    layout: PrivateRoute,
    component: Facturas,
    exact: true,
  },
  {
    path: "/admin/contab/notasc",
    layout: PrivateRoute,
    component: NotasCreditos,
    exact: true,
  },

  {
    path: "/admin/contab/notasc/aplicada/:id",
    layout: PrivateRoute,
    component: NotasCreditosAplicada,
    exact: true,
  },
  {
    path: "admin/nacheros",
    layout: PrivateRoute,
    component: Nacho,
    exact: true,
  },
  {
    path: "admin/nacheros/vamos-hacer",
    layout: PrivateRoute,
    component: QueVamosHAcer,
    exact: true,
  },
  {
    path: "admin/nacheros/vamos-hacer/clientes",
    layout: PrivateRoute,
    component: ListadoDeCliente,
    exact: true,
  },
  {
    path: "admin/nacheros/vamos-hacer/productos",
    layout: PrivateRoute,
    component: ListadoProductos,
    exact: true,
  },
  {
    path: "admin/nacheros/vamos-hacer/procesar",
    layout: PrivateRoute,
    component: NacherosProcesar,
    exact: true,
  },
  {
    path: "/admin/usuarios",
    layout: PrivateRoute,
    component: UsersPage,
    exact: true,
  },
  {
    path: "/admin/perfil",
    layout: PrivateRoute,
    component: Perfil,
    exact: true,
  },
  {
    path: "*",
    layout: PublicRoute,
    component: NotFoundPage,
  },
];

const routesPublic = [
  {
    path: "/",
    layout: PublicRoute,
    component: HomePages,
    exact: true,
  },
  {
    path: "/consulta",
    layout: PublicRoute,
    component: ConsultaArticulo,
    exact: true,
  },
  {
    path: "/qr-code",
    layout: PublicRoute,
    component: QRGenerator,
    exact: true,
  },
  {
    path: "/*",
    layout: PublicRoute,
    component: NotFoundPage,
  },
];
const routes = [...routesAdmin, ...routesPublic];
export default routes;
