import { Card, Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { v4 as uuidv4 } from "uuid";

import Loader from "../../../tools/Loader";

export default function NotasAplicadas(props) {
  const { datas, loading } = props;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Row>
          {datas.map((nota) => (
            <Col key={uuidv4()} xs={12} sm={12} md={6} lg={4} xl={4}>
              <Card
                className="my-3 rounded text-center shadow-sm p-3 mb-2 bg-info rounded"
                style={{ border: "none" }}
              >
                <Card.Title style={{ fontSize: 25 }}>
                  {nota.CLIENTE} - {nota.NOMBRE}
                </Card.Title>
                <Card.Body>
                  <Card.Text
                    className="font-weight-bold"
                    style={{ fontSize: 38 }}
                  >
                    <NumericFormat
                      thousandsGroupStyle="thousand"
                      value={nota.MONTO}
                      prefix="$"
                      decimalSeparator="."
                      displayType="text"
                      // type="text"
                      thousandSeparator={true}
                      allowNegative={true}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}
