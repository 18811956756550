// services/reportGenerator.js

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const generatePDF = (datos, modulo) => {
  // initialize jsPDF
  const doc = new jsPDF();
  console.log(datos);

  // define the columns we want and their titles
  //   const tableColumn = ["Cliente", "Nombre", "Factura", "Monto", "Fecha"];
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  datos.map((item) =>
    tableRows.push({
      cliente: item.cliente,
      nombre: item.nombre_cliente,
      factura: item.factura,
      monto: item.total_factura,
      fecha: moment(item.fecha_agendada).utc().format("YYYY-MM-DD"),
      fecha_recibida: moment(item.createdAt).utc().format("YYYY-MM-DD"),
      repartidor: item.nombre_repartidor,
      ficha: item.ficha,
      accion: item.accion,
    })
  );

  if (modulo === "agendado") {
    doc.autoTable({
      columns: [
        { header: "Cliente", dataKey: "cliente" },
        { header: "Nombre", dataKey: "nombre" },
        { header: "Factura", dataKey: "factura" },
        { header: "Monto", dataKey: "monto" },
        { header: "Fecha Despacho", dataKey: "fecha" },
        { header: "Repartidor", dataKey: "repartidor" },
        { header: "Ficha", dataKey: "ficha" },
      ],
      body: tableRows,
      margin: { top: 35 },
      didDrawPage: function (data) {
        // console.log(data);
        doc.text(`Reporte ${modulo} `, 20, 30);
      },
    });
  } else if (modulo === "enalmacen") {
    doc.autoTable({
      columns: [
        { header: "Cliente", dataKey: "cliente" },
        { header: "Nombre", dataKey: "nombre" },
        { header: "Factura", dataKey: "factura" },
        { header: "Monto", dataKey: "monto" },
        { header: "Accion", dataKey: "accion" },
        { header: "Registrada", dataKey: "fecha_recibida" },
      ],
      body: tableRows,
      margin: { top: 35 },
      didDrawPage: function (data) {
        // console.log(data);
        doc.text(`Reporte ${modulo} `, 20, 30);
      },
    });
  } else if (modulo === "devolucion") {
    doc.autoTable({
      columns: [
        { header: "Cliente", dataKey: "cliente" },
        { header: "Nombre", dataKey: "nombre" },
        { header: "Factura", dataKey: "factura" },
        { header: "Monto", dataKey: "monto" },
        { header: "Repartidor", dataKey: "repartidor" },
        { header: "Accion", dataKey: "accion" },
      ],
      body: tableRows,
      margin: { top: 35 },
      didDrawPage: function (data) {
        // console.log(data);
        doc.text(`Reporte ${modulo} `, 20, 30);
      },
    });
  } else {
    doc.autoTable({
      columns: [
        { header: "Cliente", dataKey: "cliente" },
        { header: "Nombre", dataKey: "nombre" },
        { header: "Factura", dataKey: "factura" },
        { header: "Monto", dataKey: "monto" },
        { header: "Repartidor", dataKey: "repartidor" },
      ],
      body: tableRows,
      margin: { top: 35 },
      didDrawPage: function (data) {
        // console.log(data);
        doc.text(`Reporte ${modulo} `, 20, 30);
      },
    });
  }

  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  doc.save(`report_${dateStr}.pdf`);
};
export default generatePDF;
