import { useEffect, useState } from "react";
import moment from "moment";
import { EditUser } from "../../../components/Gestion/Usuarios";
import TableU from "../../../components/Table";
import Modal from "../../../components/modal";
import { BiEdit, BiEraser, BiCheckSquare } from "react-icons/bi";

// import Buscador from "../../../components/tools/Buscador";

import { User } from "../../../api";
import { CustomButton } from "../../../components/Buttons";
import { useForm, useAuth } from "../../../hooks";

const userController = new User();

export default function UserPage() {
  const { accessToken } = useAuth();
  const token = accessToken;
  const [users, setUsers] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setLoadings(true);
    (async () => {
      setUsers([]);
      const response = await userController.getUsers(token, true);
      setUsers(response.docs);
      setLoadings(false);
      try {
      } catch (error) {
        console.error(error);
      }
    })();
  }, [token, reloadPage]);

  const handleEdit = (item) => {
    setEditModal(!editModal);
    setSelected(item);
  };

  const handleDelete = (item) => {
    // Lógica para eliminar el elemento
    console.log(`Eliminar: ${item}`);
  };

  /* Inicio de Tabla*/
  const columns = [
    { field: "name", header: "Nombre" },
    { field: "lastname", header: "Apellido" },
  ];

  const actions = [
    {
      label: "Editar",
      onClick: handleEdit,
      icon: BiEdit,
      color: "text-gray-600",
    },
    {
      label: "Eliminar",
      onClick: handleDelete,
      icon: BiEraser,
      color: "text-red-600",
    },
    {
      label: "Inactivar",
      onClick: handleDelete,
      icon: BiCheckSquare,
      color: "text-blue-600",
    },
  ];

  return (
    <div className="p-5 h-screen bg-gray-100">
      <h1 className="text-xl mb-2">Usuarios</h1>
      <CustomButton to={"/"}></CustomButton>

      <div className="overflow-auto rounded-lg shadow">
        <TableU
          data={users}
          columns={columns}
          hover={true}
          striped={true}
          actions={actions}
          loading={loadings}
        />
      </div>
      <Modal isVisible={editModal} onClose={() => setEditModal(!editModal)}>
        <EditUser registro={selected} setIsModalOpened={setEditModal} />
      </Modal>
    </div>
  );
}
