import { useEffect, useState } from "react";
import { Layout, Row, Col, Button, Table } from "antd";
import {
  DeleteOutlined,
  SendOutlined,
  CalendarTwoTone,
} from "@ant-design/icons";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import {
  getCargaFacturasApi,
  getFacturasAllByStateApi,
} from "../../../api/facturasApi";
import AnularDeleteForm from "../../../components/Gestion/Almacen/Facturacion/AnularDeleteForm";
import CustomModal from "../../../components/modal/CustomModal";
import { useModalWithData } from "../../../hooks/useModal";

import AddAgendaForm from "../../../components/Gestion/Almacen/Agendar/AddReAgendarForm";
import { HeaderModulo } from "../../../components/Buttons";
import AddFacturacionForm from "../../../components/Gestion/Almacen/Facturacion/AddFacturacionForm";
import Buscar from "../../../components/tools/Buscar";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks";

export default function Facturacion() {
  const { accessToken, user } = useAuth();
  const token = accessToken;

  const [facturas, setFacturas] = useState([]);
  // const [datos, setDatos] = useState([]);
  const [loading, setLoader] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [buscar, setBuscar] = useState({
    tipo_busqueda: "factura",
    buscar: "",
  });
  const [accionMostrar, setAccionMostrar] = useState("");
  const [setIsModalOpened, isModalOpened, modalData, setModalData] =
    useModalWithData();

  const onChanche = (sorter) => {
    console.log(sorter);
  };

  const onChangeCarga = async () => {
    setLoader(true);
    getCargaFacturasApi(token).then((resp) => {
      // console.log(resp);
      if (resp.code === 404) {
        toast(resp);
        setLoader(false);
      } else {
        // console.log(resp);
        toast(resp.message);
        setLoader(false);
      }
    });
  };

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoader(true);
    getFacturasAllByStateApi(token, {
      modulo: "facturacion",
      query: buscar,
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setFacturas([]);
        setLoader(false);
      } else {
        // console.log(resp.datos);
        setFacturas(
          resp.datos?.map((row) => ({
            _id: row._id,
            cliente: row.cliente,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            total_factura: row.total_factura,
            fecha_factura: row.fecha,
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            nombre_vendedor: row.nombre_vendedor,
            comprobante: row.comprobante,
            comentario: row.comentario,
          }))
        );
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    getFacturasAllByStateApi(token, {
      modulo: "facturacion",
      query: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setFacturas([]);
        setLoader(false);
      } else {
        setFacturas(
          resp.datos?.map((row) => ({
            _id: row._id,
            cliente: row.cliente,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            total_factura: row.total_factura,
            fecha_factura: row.fecha,
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            nombre_vendedor: row.nombre_vendedor,
            comprobante: row.comprobante,
            comentario: row.comentario,
          }))
        );
        setLoader(false);
      }
    });
  }, [reloadPage]);

  // useEffect(() => {
  //   setFacturas(datos);
  // }, [datos]); //FQ0138730

  const onAccion = (record, accion) => {
    setModalData(record);
    setIsModalOpened(true);
    setAccionMostrar(accion);
  };

  const columns = [
    {
      title: "Cliente",
      width: 60,
      dataIndex: "nombre_cliente",
      key: "nombre_cliente",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Factura",
      width: 30,
      dataIndex: "factura",
      key: "factura",
      // fixed: "left",
      // responsive: ["lg"]
    },

    {
      title: "Fecha",
      width: 40,
      dataIndex: "fecha",
      key: "fecha",
      // fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      responsive: ["sm"],
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      width: 40,
      // responsive: ["sm"]
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 40,
      render: (record) => (
        <>
          <Button
            size="large"
            style={{
              backgroundColor: "#27ae60",
              // marginRight: 3,
              // paddgin: 0,
              padding: 5,
            }}
            onClick={() => onAccion(record, user.role)}
            icon={
              <SendOutlined
                style={{ fontSize: "20px", color: "#ffffff" }}
                theme="outlined"
              />
            }
          ></Button>
          <Button
            size="large"
            style={{
              backgroundColor: "#e8f3f8",
              // margin: 5,
              padding: 5,
            }}
            onClick={() => onAccion(record, "agendar")}
            icon={
              <CalendarTwoTone
                style={{ fontSize: "20px", color: "#08c" }}
                theme="outlined"
              />
            }
          ></Button>
          <Button
            size="large"
            style={{
              backgroundColor: "#e8f3f8",
              padding: 5,
            }}
            onClick={() => onAccion(record, "")}
            icon={
              <DeleteOutlined
                style={{ fontSize: "20px", color: "#f90000" }}
                theme="outlined"
              />
            }
          ></Button>
        </>
      ),
    },
  ];

  const showModal = (accion) => {
    if (accion === "almacen" || accion === "ventas" || accion === "admin") {
      return (
        <CustomModal
          title="Agregar"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddFacturacionForm
            registro={modalData}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
          />
        </CustomModal>
      );
    } else if (accion === "") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AnularDeleteForm
            registro={modalData}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
          />
        </CustomModal>
      );
    } else if (accion === "agendar") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddAgendaForm
            registro={modalData}
            RegistroEstado={"agendado"}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
            modulo={"facturacion"}
          />
        </CustomModal>
      );
    }
  };

  return (
    <>
      {showModal(accionMostrar)}
      <div className="md:mx-10 mx-2">
        <div className="mb-1.5">
          <HeaderModulo
            modulo={"facturacion"}
            registroEstado={"agendado"}
            setReloadPage={setReloadPage}
            estado={false}
            reloadPage={reloadPage}
            onChangeCarga={onChangeCarga}
          />
          <Buscar
            setBuscar={setBuscar}
            modulo={"agendado"}
            buscar={buscar}
            onChangeSearch={onChangeSearch}
          />
        </div>
        <Row gutter={12} className="md:mx-10">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={loading}
              rowKey={(record) => record.factura}
              // rowSelection={(rowSelection, { columnWidth: 30 })}
              dataSource={facturas}
              columns={columns}
              size="small"
              pagination={{ position: ["bottomRight"], pageSize: 50 }}
              style={{ marginTop: 0 }}
              bordered
              sticky={true}
              scroll={{ y: 480 }}
              onChange={onChanche}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
