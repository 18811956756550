import { Modal as ModalAntd } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addRegistroApi, updateRegistroApi } from "../../../../api/despachoApi";
import { getParametros } from "../../../../api/facturasApi";
import { useAuth } from "../../../../hooks";

const { confirm } = ModalAntd;

export default function AddRegistro(props) {
  const { registro, setReloadPage, setIsModalOpened, reloadPage } = props;
  const [tipo_accion, setTipo_accion] = useState([]);
  const [datos, setDatos] = useState({
    accion: "",
    comentario: "",
  });

  const { accessToken, user } = useAuth();
  const token = accessToken;

  useEffect(() => {
    getParametros(token).then((resp) => {
      if (resp.tiposdev_accion.length >= 1) {
        setTipo_accion(
          resp.tiposdev_accion.filter((item) => {
            return item.tipo === "1";
          })
        );
      } else {
        setTipo_accion([]);
      }
    });
  }, [setIsModalOpened]);

  const onChangeHandled = (event) => {
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (formData) => {
    formData.preventDefault();

    setIsModalOpened(false);
    switch (datos.accion) {
      case "devparcial":
        await confirm({
          title: "¿Esta seguro registrar Documento?",
          content: `Se estara enviando el documento ${registro.factura} de ${registro.nombre_cliente} a contabilidad, ¿Lo entregaste?`,
          okText: "Correcto",
          okType: "link",
          // cancelText: "Cancelar"
          onOk() {
            addRegistroApi(token, {
              datos: {
                idFactura: registro.idFactura,
                factura: registro.factura,
                codigo_barra: registro.codigo_barra,
                total_factura: registro.total_factura,
                vendedor: registro.vendedor,
                nombre_vendedor: registro.nombre_vendedor,
                cliente: registro.cliente,
                nombre_cliente: registro.nombre_cliente,
                ficha: registro.ficha,
                nombre_repartidor: registro.repartidor,
                comprabante: registro.comprabante,
                modulo: "contabilidad",
                RegistroEstado: "",
                fecha_agendada: registro.fecha_agendada,
                TipoDevolucion: "",
                ConceptoDevolucion: "",
                fecha_factura: registro.fecha_factura,
                fechaRecibida: registro.fecha,
                fechaVencimiento: registro.fecha,
                usuario: user._id,
                _factura: registro._id,
                comentario: registro.comentario,
                responsable: registro.responsable,
                _idUltimo: registro._id,
                estado: false,
                accion: `Documento se le registro una   ${datos.accion}`,
              },
            }).then((resp) => {
              if (resp.code === 200) {
                updateRegistroApi(
                  token,
                  {
                    usuario: user._id,
                    estado: true,
                    accion: `Documento se le registro una  Devolucion Parcial`,
                  },
                  registro._id
                )
                  .then((resp) => {
                    setReloadPage(!reloadPage);
                    toast(resp.message);
                  })
                  .catch((err) => {
                    toast.error("Hustion!, Hustion!, Error: ", err);
                  });
              } else {
                toast.error(
                  "Hustion!, Hustion!, paso algo, pero ni cuenta nos dimos, revisar que el proceso concluyo correctamente"
                );
              }
            });
          },
        });
        break;
      case "devcompleta":
        await confirm({
          title: "¿Devolucion Completa",
          content: `Se le realizo devolucion completa al ${registro.factura} de  ${registro.nombre_cliente} notifica que se hizo en el sistema.`,
          okText: "Correcto",
          okType: "link",
          // cancelText: "Cancelar"
          onOk() {
            updateRegistroApi(
              token,
              {
                estado: true,
                accion: `Documento se le registro una  Devolucion Completa}`,
              },
              registro._id
            )
              .then((resp) => {
                setReloadPage(!reloadPage);
                toast(resp.message);
              })
              .catch((err) => {
                toast.error("Hustion!, Hustion!, Error: ", err);
              });
          },
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="form-container">
      <div className="form-container__title">{registro.nombre_cliente}</div>
      <form className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="accion" className="details">
              Realizar
            </span>
            <select
              onChange={onChangeHandled}
              name="accion"
              className="input"
              id="accion"
              // autoComplete
            >
              <option value="">Seleccione una accion</option>
              {tipo_accion?.map((item, index) => (
                <option
                  value={item.value}
                  style={{ fontSize: "18px" }}
                  key={index}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-container__details-input-box">
            <span htmlFor="comentario" className="details">
              Comentario
            </span>
            <textarea
              onChange={onChangeHandled}
              name="comentario"
              className="input textarea"
              id="fecha"
              rows="10"
              cols="50"
            ></textarea>
          </div>

          <div className="form__button">
            <input type="submit" value="Registrar" />
          </div>
        </div>
      </form>
    </div>
  );
}
