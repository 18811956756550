import conn from "../helpers/config";
const { basePathApi, apiVersion } = conn;

export function getRepartidorApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/repartidores/`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getRepartidoresEstadoApi(token, status) {
  const url = `${basePathApi}/${apiVersion}/repartidores-estado/${status}`;
  // console.log(status);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function agregarRepartidorApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/agregar-repartidor`;
  // console.log(data)
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function updateRepartidorActiveteApi(token, id, status) {
  const url = `${basePathApi}/${apiVersion}/update-activate/${id}`;

  // console.log(id);
  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //  Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      active: status,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function updateRepartidorApi(token, data, id) {
  const url = `${basePathApi}/${apiVersion}/update-repartidor/${id}`;

  // console.log(data);
  // console.log({ ultimo });

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
