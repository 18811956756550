import React, { useState } from "react";

import { toast } from "react-toastify";

import { useAuth } from "../../../hooks";
import { Auth } from "../../../api";

const authController = new Auth();

export default function LoginForm() {
  // const { Content } = Layout;
  const [busqueda, guardarBusqueda] = useState({
    email: "",
    password: "",
  });

  const [classList, setClassList] = useState(false);
  const [classListPassword, setClassListPassword] = useState(false);

  const { login } = useAuth();

  const handleChange = (field) => (e) => {
    const { value } = e.target;
    // console.log(field);
    guardarBusqueda({
      ...busqueda,
      [field]: value,
    });
  };

  setTimeout(() => {
    //remove shake class after 500ms
    setClassList(false);
    setClassListPassword(false);
  }, 5000);

  const onKeyChange = (e) => {
    if (e.key === "Enter") loginUser(e);
  };

  const loginUser = async () => {
    // e.preventDefault();
    if (busqueda.email === "") {
      setClassList(true);
    } else if (busqueda.password === "") {
      setClassListPassword(true);
    } else {
   
      const response = await authController.login(busqueda);

      authController.setAccessToken(response.access);
      authController.setRefreshToken(response.refresh);

      if (!response.msg) {
        login(response.access);
      } else {
        toast(response.msg);
      }
    }
  };

  return (
    <div className="wrapper">
      <header>LOGIN</header>
      <div className="form">
        <div className={classList ? "field email shake error" : "field email"}>
          <div className="input-area">
            <input
              type="text"
              name="email"
              required
              placeholder="Correo Electronico"
              onChange={handleChange("email")}
              onKeyDown={onKeyChange}
            />
            <i className="icon bx bx-envelope"></i>
            <i className="error error-icon bx bxs-confused"></i>
          </div>
          <div className="error error=txt">
            Debe introducir un correo electronico
          </div>
        </div>
        <div
          className={
            classListPassword ? "field password shake error" : "field password"
          }
        >
          <div className="input-area">
            <input
              name="password"
              type="password"
              required
              placeholder=""
              onChange={handleChange("password")}
              onKeyDown={onKeyChange}
            />
            <i className="icon bx bxs-lock"></i>
            <i className="error error-icon bx bxs-confused"></i>
          </div>
          <div className="error error=txt">El campo no puedo ser en blanco</div>
        </div>
        <button className="btn btn-w" onClick={() => loginUser()} type="submit">
          Login
        </button>
      </div>
    </div>
  );
}
