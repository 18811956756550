import { useEffect, useState } from "react";
import { Modal as ModalAntd } from "antd";
import { toast } from "react-toastify";
import { addRegistroApi, updateRegistroApi } from "../../../../api/despachoApi";
import { getFichasEstadoApi } from "../../../../api/fichasApi";
import { getRepartidoresEstadoApi } from "../../../../api/repartidorApi";
import { useAuth } from "../../../../hooks";

export default function AddFacturaForm(props) {
  const { registro, setReloadPage, setIsModalOpened, reloadPage } = props;
  const [datos, setDatos] = useState({});
  const [fichas, setFichas] = useState([]);
  const [repartidores, setRepartidores] = useState([]);

  const { accessToken, user } = useAuth();
  const token = accessToken;

  const { confirm } = ModalAntd;

  useEffect(() => {
    setDatos({
      repartidor: registro.repartidor,
      ficha: registro.ficha,
    });
  }, [registro]);

  useEffect(() => {
    getFichasEstadoApi(token, true).then((resp) => {
      setFichas(resp?.fichas);
    });
    getRepartidoresEstadoApi(token, true).then((resp) => {
      setRepartidores(resp?.repartidores);
      // console.log(resp.repartidores)
    });
  }, [setIsModalOpened]);

  const onChangeHandled = (event) => {
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (formData) => {
    formData.preventDefault();
    const token = accessToken;
    setIsModalOpened(false);
    await confirm({
      title: `¿Esta despachando el documento ${registro.factura} del cliente ${registro.nombre_cliente}?`,
      content: `¿Seguro que este documento lo entregara ${datos.repartidor}?`,
      okText: "Correcto!",
      okType: "link",
      // cancelText: "Cancelar"
      onOk() {
        addRegistroApi(token, {
          datos: {
            idFactura: registro.idFactura,
            factura: registro.factura,
            codigo_barra: registro.codigo_barra,
            total_factura: registro.total_factura,
            vendedor: registro.vendedor,
            cliente: registro.cliente,
            nombre_cliente: registro.nombre_cliente,
            comprobante: registro.comprobante,
            ficha: datos.ficha,
            nombre_repartidor: datos.repartidor,
            modulo: "enruta",
            RegistroEstado: "",
            fecha_agendada: registro.fecha_agendada,
            fecha_nueva_agendada: registro.fecha_agendada,
            TipoDevolucion: "",
            ConceptoDevolucion: "",
            fecha_factura: registro.fecha_factura,
            fechaRecibida: registro.fecha,
            fechaVencimiento: registro.fecha,
            usuario: user._id,
            _factura: registro._id,
            comentario: registro.comentario,
            responsable: user.name,
            _idUltimo: registro._id,
            accion: "Documento Despachado",
            estado: false,
          },
        }).then((resp) => {
          // console.log(resp);
          updateRegistroApi(
            token,
            {
              usuario: user._id,
              estado: true,
              nombre_repartidor: datos.repartidor,
              ficha: datos.ficha,
              accion: "Documento Enviado a Ruta",
            },
            registro._id
          )
            .then((resp) => {
              setReloadPage(!reloadPage);
              toast(resp.message);
            })
            .catch((err) => {
              toast.error("Hustion!, Hustion!, Error: ", err);
            });
        });
      },
    });
  };

  // console.log(registro, datos);

  return (
    <div className="form-container">
      <div className="form-container__title">Agregar</div>
      <form className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="repartidor" className="details">
              Repartidor
            </span>
            <select
              onChange={onChangeHandled}
              name="repartidor"
              className="input"
              id="repartidor"
              value={datos.repartidor}
              onChangeCapture={(e) =>
                setDatos({ ...datos, repartidor: e.target.value })
              }
            >
              {repartidores?.map((item) => (
                <option
                  key={item._id}
                  value={item.nombre + " " + item.apellido}
                >
                  {item.nombre + " " + item.apellido}
                </option>
              ))}
            </select>
          </div>
          <div className="form-container__details-input-box">
            <span htmlFor="ficha" className="details">
              Ficha
            </span>
            <select
              onChange={onChangeHandled}
              name="ficha"
              className="input"
              id="ficha"
              value={datos.ficha}
              onChangeCapture={(e) =>
                setDatos({ ...datos, ficha: e.target.value })
              }
            >
              {fichas?.map((item) => (
                <option key={item._id} value={item.ficha}>
                  {item.ficha}
                </option>
              ))}
            </select>
          </div>

          <div className="form__button">
            <input type="submit" value="Registrar" />
          </div>
        </div>
      </form>
    </div>
  );
}
