const SERVER_IP = `${window.location.hostname}:3977`;
console.log(`${window.location.hostname}`)

export const ENV = {
  BASE_PATH: `http://${SERVER_IP}`,
  BASE_API: `http://${SERVER_IP}/api/v1`,
  API_ROUTES: {
    REGISTER: "auth/register",
    LOGIN: "auth/login",
    REFRESH_ACCESS_TOKEN: "auth/refresh_access_token",
    USER_ME: "user/me",
    USER: "user",
    USERS: "users",
    RESPONSABLE: "responsable",
    MENU: "menu",
    COURSE: "course",
    NEWSLETTER: "newsletter",
    POST: "post",
    GET_CLIENTES: "nacheros/get-cliente",
    GET_ARTICULOS: "get-articulo-all",
    GET_RESUMEN: "resumen-factura",
  },
  JWT: {
    ACCESS: "access",
    REFRESH: "refresh",
  },
};
