import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import "./Search.scss";

function Buscar({ setBuscar, buscar, modulo, onChangeSearch }) {
  const handleChange = (field) => (e) => {
    const { value } = e.target;
    setBuscar({
      ...buscar,
      [field]: value.toLowerCase(),
    });
  };

  const search = useRef(null);
  //   console.log(buscar);

  const onSelect = (e) => {
    search.current.select();
    search.current.focus();
    // console.log(e.target);
  };

  const onKeyChange = (e) => {
    if (e.key === "Enter") onChangeSearch(e);
    // if (e.key === "Enter") {
    //   onChangeSearch(e);
    //   // console.log("Presionamos Enter");
    // } else {
    //   // console.log("Aqui no se preciono nada");
    // }
  };

  useEffect(() => {
    if (search.current) {
      search.current.focus();
    }
  }, [search]);

  // console.log(search);
  return (
    <div className="button-container bg-white rounded-lg">
      <div className="input-buscar">
        <i className="bx bx-search-alt-2"></i>
        <input
          type="text"
          ref={search}
          placeholder="Buscar..."
          onChange={handleChange("buscar")}
          onClick={onSelect}
          autoFocus
          onKeyDown={onKeyChange}
          className="rounded-lg"
        />
        <button className="btn-buscar" onClick={onChangeSearch} type="button">
          Buscar
        </button>
      </div>
    </div>
  );
}

export default Buscar;
