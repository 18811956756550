import { Form, Input, Button } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { agregarFichaApi } from "../../../../api/fichasApi";
import { useAuth } from "../../../../hooks";

export default function AddFicha(props) {
  const { setReloadPage, setIsModalOpened, reloadPage } = props;
  const [datos, setDatos] = useState({});
  const [error, setError] = useState(false);

  const { accessToken } = useAuth();
  const token = accessToken;

  // console.log(fichas.fichas);
  const onSubmit = (formData) => {
    agregarFichaApi(token, { datos: datos }).then((rest) => {
      if (rest === "Ficha existente, favor revisar.") {
        setError(!error);
      } else {
        toast(rest);
        setIsModalOpened();
        setReloadPage(!reloadPage);
      }
    });
    // }
  };

  return (
    <div className="m-auto">
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size={"large"}
        onFinish={onSubmit}
        className="m-2"
      >
        <Form.Item label="Numero de Ficha" className="m-0">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Ficha"
            value={datos.ficha}
            onChange={(e) => setDatos({ ...datos, ficha: e.target.value })}
          />
          {error ? (
            <span className="font-bold text-red-400">
              Favor revisar, datos existe
            </span>
          ) : (
            ""
          )}
        </Form.Item>
        <Form.Item label="Placa" className="m-0">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Placa"
            value={datos.placa}
            onChange={(e) => setDatos({ ...datos, placa: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Modelo" className="m-0">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Modelo"
            value={datos.modelo}
            onChange={(e) => setDatos({ ...datos, modelo: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Chasis" className="m-0">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Chasis"
            value={datos.chasis}
            onChange={(e) => setDatos({ ...datos, chasis: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Año" className="m-0">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Año"
            value={datos.anno}
            onChange={(e) => setDatos({ ...datos, anno: e.target.value })}
          />
        </Form.Item>
        <Form.Item className="mt-4">
          <Button
            className="bg-blue-800 text-2xl font-bold"
            type="primary"
            block
            htmlType="submit"
          >
            Agregar
          </Button>
        </Form.Item>
      </Form>
    </div>
    // <Modal show={isOpenModalAgregar} onHide={closeModalAgregar}>
    //   <Modal.Header closeButton>
    //     <Modal.Title> Agregar Ficha</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Form onSubmit={handleSubmit(onSubmit)}>
    //       <Form.Group>
    //         <Form.Label>Ficha</Form.Label>
    //         <Form.Control
    //           type="text"
    //           placeholder="Numero de Ficha"
    //           {...register("ficha")}
    //         />
    //         {errors?.ficha && (
    //           <Form.Text>
    //             <Alert variant="danger">{errors.ficha.message}</Alert>
    //           </Form.Text>
    //         )}
    //       </Form.Group>
    //       <Form.Group>
    //         <Form.Label>Placa</Form.Label>
    //         <Form.Control
    //           type="text"
    //           placeholder="Numero de Placa"
    //           {...register("placa")}
    //         />
    //         {errors?.placa && (
    //           <Form.Text>
    //             <Alert variant="danger">{errors.placa.message}</Alert>
    //           </Form.Text>
    //         )}
    //       </Form.Group>
    //       <Form.Group>
    //         <Form.Label>Modelo</Form.Label>
    //         <Form.Control
    //           type="text"
    //           placeholder="Numero de Modelo"
    //           {...register("modelo")}
    //         />
    //         {errors?.modelo && (
    //           <Form.Text>
    //             <Alert variant="danger">{errors.modelo.message}</Alert>
    //           </Form.Text>
    //         )}
    //       </Form.Group>
    //       <Form.Group>
    //         <Form.Label>Chasis</Form.Label>
    //         <Form.Control
    //           type="text"
    //           placeholder="Numero de Chasis"
    //           {...register("chasis")}
    //         />
    //         {errors?.chasis && (
    //           <Form.Text>
    //             <Alert variant="danger">{errors.chasis.message}</Alert>
    //           </Form.Text>
    //         )}
    //       </Form.Group>
    //       <Form.Group>
    //         <Form.Label>Año</Form.Label>
    //         <Form.Control
    //           type="number"
    //           placeholder="Año del vehiculo"
    //           {...register("anno")}
    //         />
    //         {errors?.anno && (
    //           <Form.Text>
    //             <Alert variant="danger">{errors.anno.message}</Alert>
    //           </Form.Text>
    //         )}
    //       </Form.Group>
    //     </Form>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="primary" onClick={handleSubmit(onSubmit)}>Agregar</Button>
    //     <Button variant="secondary" onClick={closeModalAgregar}>
    //       Salir
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
}
