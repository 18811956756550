import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./distribuccion.scss";

export default function QueVamosHacer() {
  const location = useLocation();
  const { retail } = location.state;

  console.log("Que Vamos Hacer - Menu");

  return (
    <div className="contenido-vamos">
      <div className="btnContent">
        <div className="btn-box">
          <Link to="clientes" state={{ opcion: "dist", retail: retail }}>
            Distribuccion Regular
          </Link>
        </div>
        <div className="btn-box">
          <Link
            to="clientes"
            state={{ opcion: "dist_bimensual", retail: retail }}
          >
            Distribuccion Bimensual
          </Link>
        </div>
        <div className="btn-box">
          <Link to="clientes" state={{ opcion: "surtidox1", retail: retail }}>
            Surtido X1
          </Link>
        </div>
        <div className="btn-box">
          <Link to="clientes" state={{ opcion: "surtido", retail: retail }}>
            Surtido (Con Ofertas)
          </Link>
        </div>
        <div className="btn-box btn-red">
          <Link to="/admin/nacheros">Salir</Link>
        </div>
      </div>
    </div>
  );
}
