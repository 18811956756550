import React, { useEffect, useState } from "react";

// import { getInfoArticuloApi } from "../../../api/ayudaApi";
import Buscar from "../../../components/tools/Buscar";
import conn from "../../../helpers/config";
import useFetch from "../../../hooks/useFetch";

import "./consultaarticulo.scss";

export function ConsultaArticulo() {
  const [buscar, setBuscar] = useState({
    tipo_busqueda: "codigo_barra",
    buscar: "",
  });

  const { basePathApi, apiVersion } = conn;
  const url = `${basePathApi}/${apiVersion}/get-articulo/`;

  const { request, data } = useFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(buscar),
  });

  const onChangeSearch = async (e) => {
    e.preventDefault();
    if (buscar === "") {
      console.log("Se requiere que todo los campos esten lleno.");
    } else {
      request();
      // e.target.reset();
    }
  };

  useEffect(() => {
    request();
  }, [buscar]);

  return (
    <div className="consulta-container">
      <div className="consulta">
        <h2>Consulta de Articulo</h2>
        <div className="consulta__search">
          <Buscar
            setBuscar={setBuscar}
            modulo={"despacho"}
            buscar={buscar}
            onChangeSearch={onChangeSearch}
          />
        </div>
        <div className="resultado">
          <div className="text">
            <table>
              <tr>
                <th>Codigo</th>
                <th>Descripcion</th>
                <th>Caja</th>
              </tr>
              {data ? (
                data.datos.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.ARTICULO}</td>
                      <td>{item.DESCRIPCION}</td>
                      <td>{item.ARTICULO_DEL_PROV}</td>
                    </tr>
                  );
                })
              ) : (
                <>No hay dato</>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
