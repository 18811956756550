import LoginPage from "../pages/AdminPages/SignIn";
import NavBarAdmin from "../components/menus/NavBarAdmin";
import TabBar from "../components/menus/MenuBar";
import { useAuth } from "../hooks";

export default function LayoutAdmin(props) {
  const { children } = props;

  const { user, isLoading } = useAuth();

  // console.log(user);

  if (!user && !isLoading) {
    return (
      <>
        <LoginPage />
      </>
    );
  }
  // className = "flex flex-col items-center mt-20";
  if (user && !isLoading) {
    return (
      <>
        <section>
          <NavBarAdmin user={user} />
          {children}
          {/* <TabBar /> */}
        </section>
      </>
    );
  }
}
