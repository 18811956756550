export default function DashboardPage() {
  return (
    <section className="h-screen bg-Hero bg-cover md:bg-top bg-center ">
      {/* <Navbar /> */}
      <div className="flex flex-col justify-center text-center items-center h-3/4">
        <h2 className="text-white text-3xl font-medium ">CIGUA APP</h2>
        <h1 className="md:text-5xl text-3xl text-white font-semibold py-2">
          ¡Lider en distribucción!
        </h1>
        <div className="text-xl">{/* <Button /> */}</div>
      </div>
    </section>
  );
}
