import conn from "../helpers/config";

const { basePathApi, apiVersion } = conn;

export function getInfoClienteApi(data) {
  const url = `${basePathApi}/${apiVersion}/get-cliente/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getInfoArticuloApi(data) {
  const url = `${basePathApi}/${apiVersion}/get-articulo/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getDatosRipeoApi(data) {
  const url = `${basePathApi}/${apiVersion}/get-datos/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getInfoBonifoApi(data) {
  const url = `${basePathApi}/${apiVersion}/validar-oferta/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getClienteNoVentasApi(data) {
  const url = `${basePathApi}/${apiVersion}/noventas/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getDatosNacherosApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/get-nacheros/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getConsecutivoApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/consecutivo/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getConsecutivoFacturaApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/conse-factura/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function insPedidos(token, data) {
  const url = `${basePathApi}/${apiVersion}/procesar-ripeo/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);

  console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
