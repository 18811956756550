import { Modal } from "antd";
// import useWindowDimensions from "../../helpers/useWindowDimensions";

import "./CustomModal.scss";

export default function CustomModal(props) {
  const { isActive, children, title, handleClose } = props;
  // const { width } = useWindowDimensions();
  // const sizePredeterminado = (w) => {
  //   if (w <= 550) {
  //     return "100%";
  //   } else if (w >= 800) {
  //     return "60%";
  //   }
  // };

  // console.log("Estoy aqui!");
  return (
    <Modal
      centered
      // width={sizePredeterminado(width)}
      title={title}
      open={isActive}
      onCancel={handleClose}
      footer={null}
    >
      {children}
    </Modal>
  );
}
