// import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";

import { Modal as ModalAntd } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";

import { addRegistroApi } from "../../../../api/despachoApi";
import { gestionarFacturaApi } from "../../../../api/facturasApi";
import { useAuth } from "../../../../hooks";

const { confirm } = ModalAntd;

export default function AddFacturacionForm(props) {
  const {
    setIsModalOpened,
    registro,
    setReloadPage,
    reloadPage,
    // modulo,
  } = props;

  const [datos, setDatos] = useState({
    accion: "",
    fecha: "",
    comentario: "",
  });

  // console.log(registro);

  const { accessToken, user } = useAuth();
  const token = accessToken;

  const currentDate = new Date();

  const onChangeHandled = (event) => {
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  // console.log(datos);

  const onSubmit = async (formData) => {
    formData.preventDefault();
    if (datos.accion !== "" && datos.fecha !== "") {
      setIsModalOpened(false);
      await confirm({
        title: "¿Esta seguro?",
        content: `Enviar el documento del cliente ${registro.nombre_cliente} con el numero de documento ${registro.factura}`,
        okText: "Correcto!",
        okType: "link",
        // cancelText: "Cancelar"
        onOk() {
          addRegistroApi(token, {
            datos: {
              idFactura: registro._id,
              factura: registro.factura,
              codigo_barra: registro.codigo_barra,
              total_factura: registro.total_factura,
              fecha_factura: registro.fecha_factura,
              vendedor: registro.vendedor,
              fecha_agendada: datos.fecha,
              fecha_nueva_agendada: datos.fecha,
              cliente: registro.cliente,
              nombre_cliente: registro.nombre_cliente,
              comprobante: registro.comprobante,
              ficha: "",
              nombre_repartidor: "",
              modulo: "agendado",
              RegistroEstado: "",
              TipoDevolucion: "",
              ConceptoDevolucion: "",
              fechaRecibida: registro.fecha || currentDate,
              fechaVencimiento: registro.fecha || currentDate,
              usuario: user._id,
              _factura: registro._id,
              comentario: datos.comentario,
              responsable: datos.responsable,
              estado: false,
              accion: `Documento agendado para Despachar en fecha o ${datos.accion} `,
            },
          }).then((resp) => {
            gestionarFacturaApi(token, registro._id, true, true)
              .then((resp) => {
                setReloadPage(!reloadPage);
                toast(resp);
              })
              .catch((err) => {
                toast.error(
                  "Hustion!, Hustion!, paso algo, pero ni cuenta nos dimos, revisar que el proceso concluyo correctamente"
                );
              });
          });
        },
      });
    } else {
      toast.warning("Debe llenar todos los campos requerido.");
    }
  };

  return (
    <div className="form-container">
      <div className="form-container__title">{registro.nombre_cliente}</div>
      <form className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="accion" className="details">
              Accion
            </span>
            <select
              onChange={onChangeHandled}
              name="accion"
              className="input"
              id="accion"
              defaultValue=""
            >
              <option value="">Seleccione una accion</option>
              <option value="Entregar">Entregar</option>
              <option value="Entregar Urgente">Urgente</option>
              <option value="Despachar en Fecha">Despachar en Fecha</option>
              <option value="En espera aprobacion">En espera Aprobacion</option>
            </select>
          </div>
          <div className="form-container__details-input-box">
            <span htmlFor="fecha" className="details">
              Fecha
            </span>
            <input
              onChange={onChangeHandled}
              name="fecha"
              className="input"
              id="fecha"
              type="date"
            />
          </div>
          <div className="form-container__details-input-box">
            <span htmlFor="comentario" className="details">
              Comentario
            </span>
            <textarea
              onChange={onChangeHandled}
              name="comentario"
              className="input textarea"
              id="comentario"
              rows="10"
              cols="50"
            ></textarea>
          </div>
        </div>
        <div className="form__button">
          <input type="submit" value="Registrar" />
        </div>
      </form>
    </div>
  );
}
