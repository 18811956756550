import { useState } from "react";
import useFormValidations from "./useValidated";

export const useForm = (initialData, validationConfig) => {
  const [form, setForm] = useState(initialData);
  const [loading, setLoading] = useState(false);

  console.log(form);

  const { errors, validateForm } = useFormValidations();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm(form, validationConfig)) {
      console.log("Valido");
    } else {
      console.log("Errror");
    }
  };

  return { form, loading, handleChange, handleSubmit, errors, setForm };
};
