import { Button, Alert, Space, Badge } from "antd";
import { toast } from "react-toastify";
import { upNotasCreditoApi } from "../../../../api/contabilidaApi";

export default function AnularNotasForm(props) {
  const { registro, setReloadPage, setEstadoModal, reloadPage } = props;
  // console.log(registro);

  console.log(!registro.estado);

  // console.log(datos);

  const onSubmit = () => {
    upNotasCreditoApi(
      {
        estado: !registro.estado,
      },
      registro._id
    ).then((resp) => {
      console.log(resp);
      if (resp.code === 200) {
        toast(resp.message);
      } else {
        toast(resp.message);
      }

      // setIsModalOpened(false);
      setEstadoModal(false);
      setReloadPage(!reloadPage);
    });

    // addNotaCreditoApi()
  };

  // console.log(datos);

  return (
    <>
      <Alert
        message="¿Esta seguro?"
        description={`¿Esta seguro que desea ${
          !registro.estado ? "activar" : "desactivar"
        }
          a ${registro.tipo} del mes ${registro.mes}?`}
        type="warning"
        showIcon
        action={
          <Space direction="vertical">
            <Button size="small" danger block onClick={() => onSubmit()}>
              <Badge>Eliminar</Badge>
            </Button>
          </Space>
        }
        // closable
      />
      {/* <hr /> */}
    </>
  );
}
