import { Button } from "antd";
import { changeFichaEstadoApi } from "../../../../api/fichasApi";
import { useAuth } from "../../../../hooks";
// import { toast } from "react-toastify";

export default function DeleteFichas(props) {
  const { setEstadoModal, reloadPage, registro, setReloadPage, estadoModal } =
    props;
  const { accessToken } = useAuth();
  const token = accessToken;

  const handleDelete = () => {
    changeFichaEstadoApi(token, registro._id, !registro.active).then((resp) => {
      setEstadoModal(!estadoModal);
      setReloadPage(!reloadPage);
    });
  };

  return (
    <>
      <div className="bg-yellow-100 w-100 h-32 rounded-lg p-10 flex items-center space-x-2">
        <h2 className="text-2xl font-semibold text-center">{`¿Esta seguro que desea ${
          !registro.active ? "activar" : "desactivar"
        } la ficha ${registro.ficha}?`}</h2>
        <div>
          <Button
            className="bg-blue-400 text-white font-semibold hover:text-blue-200 hover:bg-blue-900"
            onClick={() => handleDelete()}
          >
            Eliminar
          </Button>
        </div>
      </div>
    </>
  );
}
