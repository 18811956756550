import conn from "../helpers/config";
const { basePathApi, apiVersion } = conn;

export function getFichasEstadoApi(token, status) {
  const url = `${basePathApi}/${apiVersion}/fichas-estado/${status}`;

  // console.log(status);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFichasApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/fichas/`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function agregarFichaApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/agregar-ficha`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function upDateFichaApi(token, data, id) {
  const url = `${basePathApi}/${apiVersion}/editar-ficha/${id}`;

  // console.log(data);
  // console.log({ ultimo });

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function changeFichaEstadoApi(token, id, status) {
  const url = `${basePathApi}/${apiVersion}/activate-ficha/${id}`;

  // console.log(id);
  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //  Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      active: status,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}
