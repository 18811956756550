import { useState, createRef } from "react";
import { Modal as ModalAntd } from "antd";
import { toast } from "react-toastify";

import { addRegistroApi, updateRegistroApi } from "../../../../api/despachoApi";

import { useAuth } from "../../../../hooks";
// import { User } from "../../../../api";

// const userController = new User();

export default function AddRegistroForm(props) {
  const { registro, setReloadPage, reloadPage, setIsModalOpened } = props;
  const [datos, setDatos] = useState({});
  // const [users, setUsers] = useState([]);
  // const [responsables, setResponsables] = useState();
  const { user, accessToken } = useAuth();
  const token = accessToken;

  const { confirm } = ModalAntd;

  const { name, lastname } = user;

  // console.log(user);

  const formRef = createRef();

  // useEffect(() => {
  //   setDatos({
  //     repartidor: registro.repartidor,
  //     responsable: "",
  //   });

  //   (async () => {
  //     setResponsables([]);
  //     const response = await userController.getResponsables(token, true);
  //     const rs = response.filter((item) => item.role === "almacen");
  //     setResponsables(rs);
  //     // setLoading(false);
  //     try {
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, [setIsModalOpened, registro, token]);

  // useEffect(() => {}, []);

  const onChangeHandled = (event) => {
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (formData) => {
    formData.preventDefault();
    setIsModalOpened(false);
    await confirm({
      title: `¿Esta recibiendo el documento entregado por ${registro.repartidor}`,
      content: `¿El documento del cliente ${registro.nombre_cliente} con el numero de documento ${registro.factura}`,
      okText: "Correcto",
      okType: "link",
      // cancelText: "Cancelar"
      onOk() {
        addRegistroApi(token, {
          datos: {
            idFactura: registro.idFactura,
            factura: registro.factura,
            codigo_barra: registro.codigo_barra,
            total_factura: registro.total_factura,
            vendedor: registro.vendedor,
            cliente: registro.cliente,
            nombre_cliente: registro.nombre_cliente,
            ficha: registro.ficha,
            comprobante: registro.comprobante,
            nombre_repartidor: datos.repartidor || registro.repartidor,
            modulo: "enalmacen",
            RegistroEstado: "",
            fecha_agendada: registro.fecha_agendada,
            TipoDevolucion: "",
            ConceptoDevolucion: "",
            fecha_factura: registro.fecha_factura,
            fechaRecibida: registro.fecha,
            fechaVencimiento: registro.fecha,
            usuario: user._id,
            _factura: registro._id,
            accion: "Documento Entregado en Almacen",
            comentario: registro.comentario,
            responsable: name + " " + lastname,
            _idUltimo: registro._id,
            estado: false,
          },
        }).then((resp) => {
          if (resp.code === 200) {
            updateRegistroApi(
              token,
              {
                estado: true,
                usuario: user._id,
                responsable: datos.responsable,
                accion: `Documento Recibo en Almacen por ${datos.responsable}`,
              },
              registro._id
            )
              .then((resp) => {
                setReloadPage(!reloadPage);
                // console.log(resp);
                toast(resp.message);
              })
              .catch((err) => {
                toast.error("Hustion!, Hustion!, Error: ", err);
              });
          } else {
            toast.error(
              "Hustion!, Hustion!, paso algo, pero ni cuenta nos dimos, revisar que el proceso concluyo correctamente"
            );
          }
        });
      },
    });
  };

  // console.log(datos);

  return (
    // <div className="form-body">
    <div className="form-container">
      <div className="form-container__title">{registro.nombre_cliente}</div>
      <form ref={formRef} className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="repartidor" className="details">
              Repartidor
            </span>
            <input
              className="input"
              disabled
              name="repartidor"
              id="repartidor"
              value={registro.repartidor}
              onChange={onChangeHandled}
            />
          </div>
          {/* <div className="form-container__details-input-box">
            <span htmlFor="responsable" className="details">
              Responsable
            </span>
            <select
              onChange={onChangeHandled}
              name="responsable"
              className="input"
              id="responsable"
              // value={datos.responsable}
            >
              <option value="">Seleccione una accion</option>
              {responsables?.map((item) => (
                <option key={item._id} value={item.name + " " + item.lastname}>
                  {item.name + " " + item.lastname}
                </option>
              ))}
            </select>
          </div> */}

          <div className="form__button">
            <input type="submit" value="Registrar" />
          </div>
        </div>
      </form>
    </div>
    // </div>
  );
}
