import { useState } from "react";

function useFormValidation() {
  const [errors, setErrors] = useState({});

  const validateField = (field, value, validations) => {
    let fieldError = "";

    for (const validation of validations) {
      switch (validation) {
        case "required":
          if (!value) {
            fieldError = "Campo requerido";
          }
          break;
        case "email":
          const emailPattern = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
          if (!emailPattern.test(value)) {
            fieldError = "Correo electrónico no válido";
          }
          break;
        case "numeric":
          if (isNaN(value)) {
            fieldError = "Debe ser un número";
          }
          break;
        case "minLength":
          if (value.length < 10) {
            fieldError = "Debe tener al menos 10 caracteres";
          }
          break;
        case "maxLength":
          if (value.length > 200) {
            fieldError = "No debe exceder los 200 caracteres";
          }
          break;
        default:
          break;
      }
      if (fieldError) break;
    }

    return fieldError;
  };

  const validateForm = (formData, validationConfig) => {
    const newErrors = {};

    for (const field in formData) {
      const value = formData[field];
      const validations = validationConfig[field];

      if (validations) {
        const fieldError = validateField(field, value, validations);
        if (fieldError) {
          newErrors[field] = fieldError;
        }
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  return { errors, validateForm };
}

export default useFormValidation;
