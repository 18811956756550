import { useState } from "react";

export default function useFetch(url, { method, headers, body } = {}) {
  const [data, setData] = useState();
  const [erroStatus, setErrorStatus] = useState();

  //   console.log(body);

  function request() {
    fetch(url, {
      method: method,
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw response.status;
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {
        setErrorStatus(e);
      });
  }

  return { request, data, erroStatus };
}
