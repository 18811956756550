import React from "react";
import "./modal.scss";

function Modal({
  children,
  estadoModal,
  setEstadoModal,
  titulo = "Hola Mundo!",
  mostrarHeader,
}) {
  return (
    <>
      {estadoModal && (
        <div className="overlay">
          <div className="contenido-modal">
            {mostrarHeader && (
              <div className="encabezado-modal">
                <h3>{titulo}</h3>
              </div>
            )}

            <button
              className="btnCerrar-modal"
              onClick={() => setEstadoModal(!estadoModal)}
            >
              X
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
