import { useEffect, useRef, useState } from "react";
import { Button, Table } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useModalWithData } from "../../../../hooks/useModal";
import CustomModal from "../../../../components/modal/CustomModal";

import { getRepartidorApi } from "../../../../api/repartidorApi";
import EditRepartidor from "../../../../components/Gestion/Almacen/Repartidor/EditRepartidor";
import DeleteRepartidor from "../../../../components/Gestion/Almacen/Repartidor/DeleteRepartidor";
import { HeaderModulo } from "../../../../components/Buttons";
import Buscar from "../../../../components/tools/Buscar";
import { useAuth } from "../../../../hooks";

export default function RepartidoresPages() {
  const [repartidores, setRepartidor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reloadPage, setReloadPage] = useState(false);
  const [accionMostrar, setAccionMostrar] = useState("");
  const [setIsModalOpened, isModalOpened, modalData, setModalData] =
    useModalWithData();
  // const { page = 1, limit = 10 } = queryString.parse(location.search);
  // const [active, setActive] = useState(true);

  const { accessToken } = useAuth();
  const token = accessToken;

  const [buscar, setBuscar] = useState({
    tipo_busqueda: "fichas",
    buscar: "",
  });

  const search = useRef(null);

  // const { Content } = Layout;
  // console.log(active);

  //Focus
  useEffect(() => {
    if (search.current) {
      search.current.focus();
    }
  }, [search]);

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    getRepartidorApi(token, {
      modulo: "repartidores",
      query: buscar,
      RegistroEstado: "repartidores",
      activo: true,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRepartidor([]);
        setLoading(false);
      } else {
        // console.log(resp);
        setRepartidor(resp.datos);
        setLoading(false);
        // setReloadPage(!reloadPage);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getRepartidorApi(token, {
      modulo: "repartidores",
      query: "",
      RegistroEstado: "repartidores",
      activo: true,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRepartidor([]);
        setLoading(false);
      } else {
        // console.log(resp);
        setRepartidor(resp.datos);
        setLoading(false);
      }
    });
  }, [reloadPage]);

  // const handledChangeActive = (e) => {
  //   // console.log(e.target.checked)
  //   setActive(e.target.checked);
  // };

  const onAccion = (record, accion) => {
    setModalData(record);
    setIsModalOpened(true);
    setAccionMostrar(accion);
  };

  const columns = [
    // {
    //   title: "_id",
    //   width: 20,
    //   dataIndex: "_id",
    //   key: "_id",
    //   fixed: "left",
    //   showHeader: false,
    //   responsive: [""],
    // },
    {
      title: "Nombre",
      width: 40,
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Apellido",
      width: 45,
      dataIndex: "apellido",
      key: "apellito",
      fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Flota",
      width: 20,
      dataIndex: "flota",
      key: "flota",
      fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Telefono Personal",
      dataIndex: "telefono_personal",
      key: "telefono_personal",
      width: 20,
      // responsive: ["sm"]
    },
    {
      title: "Ficha",
      dataIndex: "ficha",
      key: "ficha",
      width: 10,
      // responsive: ["sm"]
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 40,
      render: (record) => (
        <>
          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#e8f3f8",
              margin: 1,
              padding: 0,
            }}
            onClick={() => onAccion(record, "edit")}
          >
            <EditOutlined
              style={{ fontSize: "16px", color: "#08c", margin: 5, padding: 0 }}
            />
          </Button>
          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#e8f3f8",
              margin: 1,
              padding: 0,
            }}
            onClick={() => onAccion(record, "delete")}
          >
            <CloseOutlined
              style={{
                fontSize: "16px",
                color: "#FF5733",
                margin: 5,
                padding: 0,
              }}
            />
          </Button>
        </>
      ),
    },
  ];

  // console.log(repartidores);
  const showModal = (accion) => {
    if (accion === "edit") {
      return (
        <CustomModal
          title={`Editando al repartidor ${modalData?.nombre}`}
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <EditRepartidor
            registro={modalData}
            reloadPage={reloadPage}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
          />
        </CustomModal>
      );
    } else if (accion === "delete") {
      return (
        <CustomModal
          title={`Eliminando al repartidor ${modalData?.nombre}`}
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <DeleteRepartidor
            registro={modalData}
            reloadPage={reloadPage}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
          />
        </CustomModal>
      );
    }
  };

  return (
    <div className="md:mx-10 mx-2">
      {showModal(accionMostrar)}
      <div className="mb-1.5">
        <HeaderModulo
          modulo={"repartidores"}
          registroEstado={"repartidores"}
          setReloadPage={setReloadPage}
          estado={false}
          reloadPage={reloadPage}
        />

        <Buscar
          setBuscar={setBuscar}
          modulo={"repartidores"}
          buscar={buscar}
          onChangeSearch={onChangeSearch}
        />
      </div>

      <div
        className="site-layout-background"
        style={{ padding: 0, minHeight: 200 }}
      >
        <Table
          loading={loading}
          rowKey={(record) => record._id}
          // rowSelection={rowSelection}
          dataSource={repartidores}
          columns={columns}
          size="small"
          pagination={{ position: ["bottomRight"] }}
          style={{ marginTop: 0 }}
          scroll={{ y: 480 }}
          bordered
        />
      </div>
    </div>
  );
}
