import { useEffect, useState } from "react";
import { Modal as ModalAntd } from "antd";
import { toast } from "react-toastify";
import { addRegistroApi, updateRegistroApi } from "../../../../api/despachoApi";
import { getParametros } from "../../../../api/facturasApi";
import { useAuth } from "../../../../hooks";

const { confirm } = ModalAntd;

export default function AddRegistroDevo(props) {
  const { registro, setReloadPage, setIsModalOpened, reloadPage } = props;
  const [tipo_accion, setTipo_accion] = useState([]);
  const [datos, setDatos] = useState({
    accion: "",
    comentario: "",
  });

  const { accessToken, user } = useAuth();
  const token = accessToken;

  // console.log(registro);

  useEffect(() => {
    getParametros().then((resp) => {
      // console.log(resp.tiposdev_accion);
      if (resp.tiposdev_accion.length >= 1) {
        setTipo_accion(
          resp.tiposdev_accion.filter((item) => {
            return item.tipo === "2";
          })
        );
      } else {
        setTipo_accion([]);
      }
    });
  }, [setIsModalOpened]);

  const onChangeHandled = (event) => {
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (formData) => {
    formData.preventDefault();
    // if (registro.modulo === "devolucion") {
    // } else {
    // }
    if (datos.accion === undefined || datos.accion === "") {
      toast("Debe seleccionar una opcion.");
    } else {
      setIsModalOpened(false);
      //  setIsModalOpened(false);
      //  setIsModalOpened(false);
      //  setIsModalOpened(false);
      //  setIsModalOpened(false);
      switch (datos.accion) {
        case "entregadacontabilidad":
          await confirm({
            title: "¿Esta seguro registrar Documento?",
            content: `Se esta envia a contabilidad el documento ${registro.factura} de ${registro.nombre_cliente}`,
            okText: "Correcto",
            okType: "link",
            // cancelText: "Cancelar"
            onOk() {
              addRegistroApi(token, {
                datos: {
                  idFactura: registro.idFactura,
                  factura: registro.factura,
                  codigo_barra: registro.codigo_barra,
                  total_factura: registro.total_factura,
                  vendedor: registro.vendedor,
                  nombre_vendedor: registro.nombre_vendedor,
                  cliente: registro.cliente,
                  nombre_cliente: registro.nombre_cliente,
                  ficha: registro.ficha,
                  nombre_repartidor: registro.repartidor,
                  modulo: "contabilidad",
                  RegistroEstado: "",
                  fecha_agendada: registro.fecha_agendada,
                  comprobante: registro.comprobante,
                  TipoDevolucion: "",
                  ConceptoDevolucion: "",
                  fecha_factura: registro.fecha_factura,
                  fechaRecibida: registro.fecha,
                  fechaVencimiento: registro.fecha,
                  usuario: user._id,
                  _factura: registro._id,
                  comentario: registro.comentario,
                  responsable: registro.responsable,
                  accion: `Documento Registrado ${datos.accion}`,
                  _idUltimo: registro._id,
                  estado: false,
                },
              }).then((resp) => {
                if (resp.code === 200) {
                  updateRegistroApi(
                    token,
                    {
                      estado: true,
                      usuario: user._id,
                      accion: `Documento entregado a Contabilidad}`,
                    },
                    registro._id
                  )
                    .then((resp) => {
                      setReloadPage(!reloadPage);

                      // console.log(resp);
                      toast(resp.message);
                    })
                    .catch((err) => {
                      toast.error("Hustion!, Hustion!, Error: ", err);
                    });
                } else {
                  toast.error(
                    "Hustion!, Hustion!, paso algo, pero ni cuenta nos dimos, revisar que el proceso concluyo correctamente"
                  );
                }
              });
            },
          });
          break;
        case "entregadadevolucion":
          if (registro.modulo === "devolucion") {
            await confirm({
              title: "Adevertencia!",
              content: `Documento ${registro.factura} de  ${registro.nombre_cliente} ya tiene un proceso de Devolucion`,
              okText: "Correcto",
              okType: "link",
              onOk() {
                setReloadPage(!reloadPage);

                // console.log(resp);
              },
            });
          } else {
            await confirm({
              title: "¿Documento presenta alguna devoluciones",
              content: `estara enviando el documento ${registro.factura} de  ${registro.nombre_cliente}`,
              okText: "Correcto",
              okType: "link",
              // cancelText: "Cancelar"
              onOk() {
                addRegistroApi(token, {
                  datos: {
                    idFactura: registro.idFactura,
                    factura: registro.factura,
                    codigo_barra: registro.codigo_barra,
                    total_factura: registro.total_factura,
                    vendedor: registro.vendedor,
                    nombre_vendedor: registro.nombre_vendedor,
                    cliente: registro.cliente,
                    nombre_cliente: registro.nombre_cliente,
                    comprobante: registro.comprobante,
                    ficha: registro.ficha,
                    nombre_repartidor: registro.repartidor,
                    modulo: "devolucion",
                    RegistroEstado: "",
                    fecha_agendada: registro.fecha_agendada,
                    TipoDevolucion: "",
                    ConceptoDevolucion: "",
                    fecha_factura: registro.fecha_factura,
                    fechaRecibida: registro.fecha,
                    fechaVencimiento: registro.fecha,
                    usuario: user.id,
                    _factura: registro._id,
                    comentario: registro.comentario,
                    responsable: registro.responsable,
                    _idUltimo: registro._id,
                    accion: `Documento Registrado para ${datos.accion}`,
                    estado: false,
                  },
                }).then((resp) => {
                  if (resp.code === 200) {
                    updateRegistroApi(
                      token,
                      {
                        estado: true,
                        usuario: user._id,
                        accion: `Documento Registrado a Devolucion`,
                      },
                      registro._id
                    )
                      .then((resp) => {
                        setReloadPage(!reloadPage);

                        // console.log(resp);
                        toast(resp.message);
                      })
                      .catch((err) => {
                        toast.error("Hustion!, Hustion!, Error: ", err);
                      });
                  } else {
                    toast.error(
                      "Hustion!, Hustion!, paso algo, pero ni cuenta nos dimos, revisar que el proceso concluyo correctamente"
                    );
                  }
                });
              },
            });
          }
          break;
        case "factura_pagada_caja":
          if (registro.modulo === "devolucion") {
            await confirm({
              title: "Adevertencia!",
              content: `Documento ${registro.factura} de  ${registro.nombre_cliente} ya tiene un proceso de Devolucion`,
              okText: "Correcto",
              okType: "link",
              onOk() {
                setReloadPage(!reloadPage);
              },
            });
          } else {
            await confirm({
              title: "¿Este documento fue pagado en caja?",
              content: `El documento ${registro.factura} de  ${registro.nombre_cliente} fue emitido un pago en caja.`,
              okText: "Correcto",
              okType: "link",
              // cancelText: "Cancelar"
              onOk() {
                addRegistroApi(token, {
                  datos: {
                    idFactura: registro.idFactura,
                    factura: registro.factura,
                    codigo_barra: registro.codigo_barra,
                    total_factura: registro.total_factura,
                    comprobante: registro.comprobante,
                    vendedor: registro.vendedor,
                    nombre_vendedor: registro.nombre_vendedor,
                    cliente: registro.cliente,
                    nombre_cliente: registro.nombre_cliente,
                    ficha: registro.ficha,
                    nombre_repartidor: registro.repartidor,
                    modulo: "encaja",
                    RegistroEstado: "",
                    fecha_agendada: registro.fecha_agendada,
                    TipoDevolucion: "",
                    ConceptoDevolucion: "",
                    fecha_factura: registro.fecha_factura,
                    fechaRecibida: registro.fecha,
                    fechaVencimiento: registro.fecha,
                    usuario: user.id,
                    _factura: registro._id,
                    comentario: registro.comentario,
                    responsable: registro.responsable,
                    _idUltimo: registro._id,
                    accion: `Documento Registrado para ${datos.accion}`,
                    estado: true,
                  },
                }).then((resp) => {
                  if (resp.code === 200) {
                    updateRegistroApi(
                      token,
                      {
                        estado: true,
                        usuario: user._id,
                        accion: `Documento Registrado pagada en Caja`,
                      },
                      registro._id
                    )
                      .then((resp) => {
                        setReloadPage(!reloadPage);

                        // console.log(resp);
                        toast(resp.message);
                      })
                      .catch((err) => {
                        toast.error("Hustion!, Hustion!, Error: ", err);
                      });
                  } else {
                    toast.error(
                      "Hustion!, Hustion!, paso algo, pero ni cuenta nos dimos, revisar que el proceso concluyo correctamente"
                    );
                  }
                });
              },
            });
          }

          break;
        case "reprogramar":
          if (registro.modulo === "devolucion") {
            await confirm({
              title: "Documento tiene proceso de Devolucion",
              content: `Documento ${registro.factura} de  ${registro.nombre_cliente} ya tiene un proceso de Devolucion`,
              okText: "Correcto",
              okType: "link",
              onOk() {
                setReloadPage(!reloadPage);
              },
            });
          } else {
            await confirm({
              title: "¿Desea reprogramar Despacho?",
              content: `estara enviando el documento ${registro.factura} de  ${registro.nombre_cliente} para ser re-agendado.`,
              okText: "Correcto",
              okType: "link",
              // cancelText: "Cancelar"
              onOk() {
                addRegistroApi(token, {
                  datos: {
                    idFactura: registro.idFactura,
                    factura: registro.factura,
                    codigo_barra: registro.codigo_barra,
                    total_factura: registro.total_factura,
                    vendedor: registro.vendedor,
                    nombre_vendedor: registro.nombre_vendedor,
                    comprobante: registro.comprobante,
                    cliente: registro.cliente,
                    nombre_cliente: registro.nombre_cliente,
                    ficha: registro.ficha,
                    nombre_repartidor: registro.repartidor,
                    modulo: "agendado",
                    RegistroEstado: "",
                    fecha_agendada: registro.fecha_agendada,
                    TipoDevolucion: "",
                    ConceptoDevolucion: "",
                    fecha_factura: registro.fecha_factura,
                    fechaRecibida: registro.fecha,
                    fechaVencimiento: registro.fecha,
                    usuario: user.id,
                    _factura: registro._id,
                    comentario: registro.comentario,
                    responsable: registro.responsable,
                    _idUltimo: registro._id,
                    accion: `Documento Registrado para ${datos.accion}`,
                    estado: false,
                  },
                }).then((resp) => {
                  if (resp.code === 200) {
                    updateRegistroApi(
                      token,
                      {
                        estado: true,
                        usuario: user._id,
                        accion: `Reprogramar envio de Documento`,
                      },
                      registro._id
                    )
                      .then((resp) => {
                        setReloadPage(!reloadPage);

                        // console.log(resp);
                        toast(resp.message);
                      })
                      .catch((err) => {
                        toast.error("Hustion!, Hustion!, Error: ", err);
                      });
                  } else {
                    toast.error(
                      "Hustion!, Hustion!, paso algo, pero ni cuenta nos dimos, revisar que el proceso concluyo correctamente"
                    );
                  }
                });
              },
            });
          }
          break;
        default:
          break;
      }
    }
  };

  // console.log(registro);

  return (
    <div className="form-container">
      <div className="form-container__title">{registro.nombre_cliente}</div>
      <form className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="accion" className="details">
              Realizar
            </span>
            <select
              onChange={onChangeHandled}
              name="accion"
              className="input"
              id="accion"

              // autoComplete
            >
              <option value="">Seleccione una accion</option>
              {tipo_accion?.map((item, index) => (
                <option
                  value={item.value}
                  style={{ fontSize: "18px" }}
                  key={index}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-container__details-input-box">
            <span htmlFor="comentario" className="details">
              Comentario
            </span>
            <textarea
              onChange={onChangeHandled}
              name="comentario"
              className="input textarea"
              id="fecha"
              rows="10"
              cols="50"
            ></textarea>
          </div>

          <div className="form__button">
            <input type="submit" value="Registrar" />
          </div>
        </div>
      </form>
    </div>
  );
}
