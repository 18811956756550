const routes = {
  home: "/",
  login: "/admin/login",
  register: "/register",
  account: "/admin/perfil",
  dashboard: "/admin/dashboard",
  ayudaVentas: "/ayuda",

  contabilidad: "/contabilidad",
  // project: (projectId) => (projectId ? `/project/:${projectId}` : '/project/:projectId'),
  admin: {
    users: "/admin/usuarios",
  },

  // //Mostrar Detalle del Cliente, Datos, facturas generada, Estado de las facturas
  // detalleCliente: (idCliente) => (idCliente ? `/almacen/cliente/:${idCliente}` : '/almacen/cliente/:idCliente'),

  /* Control de Despacho o Gestion de Almacen */
  almacen: {
    // dashboard: '/almacen/dashboard',
    gestionAlmacen: "/admin/almacen", //Home Pagina Inicial de Despacho - Dashbaord
    agendarDespacho: "/admin/almacen/agendar", //Agendar las facturas por despachar
    facturacionDepacho: "/admin/almacen/facturacion", //Agendar las facturas por despachar
    despachar: "/admin/almacen/despachar", //Despachar camion a la calle
    enruta: "/admin/almacen/camion", //Recibir En Ruta
    enlamcen: "/admin/almacen/enalmacen", // Facturas recibida en Almacen
    devoluciones: "/admin/almacen/devolucion", // Proceso de Devoluciones
    gestionFichas: "/admin/almacen/fichas",
    gestionRepartidores: "/admin/almacen/repartidores",
  },

  /* Control de Despacho o Gestion de Almacen */
  contab: {
    // dashboard: '/almacen/dashboard',
    dashboard: "/admin/contab", //Home Pagina Inicial de Despacho - Dashbaord
    recibirFactura: "/admin/contab/facturacion", //Agendar las facturas por despachar
    notasDeCredito: "/admin/contab/notasc",
    notascAplicada: "/admin/contab/notasc/aplicada/:id",

    // notasDeCreditoAplicada:  (id) =>
    //   id
    //     ? `/contab/notasc/aplicada/:${id}`
    //     : "/contab/notascredito/aplicada/:id", //Despachar camion a la call
    // notasDeCreditoAplicada: '/contab/notascredito/aplicada/:id',//Despachar camion a la call
  },
};

export default routes;
