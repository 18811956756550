import { ENV } from "../utils";

export class User {
  baseApi = ENV.BASE_API;
  baseUrl = ENV.BASE_PATH;

  async verificacionConexionApi(url) {
    try {
      const response = await fetch(url, { method: "HEAD" });
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async getMe(accessToken) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.USER_ME}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async createUser(accessToken, data) {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      if (data.fileAvatar) {
        formData.append("avatar", data.fileAvatar);
      }

      const url = `${this.baseApi}/${ENV.API_ROUTES.USER}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) {
        // Si la respuesta no es exitosa, agregar el mensaje de error al objeto result
        const errorMessage = result.message || "Error desconocido";
        result.error = `Error de la API: ${errorMessage}`;
      }

      return result;
    } catch (error) {
      // Capturar cualquier error de red u otros errores y agregarlos al objeto result
      const errorMessage = error.message || "Error de red";
      return { error: `Error: ${errorMessage}` };
    }
  }

  async getUsers(accessToken, active = undefined) {
    let result;
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.USERS}?active=${active}`;

      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      result = await response.json();

      if (response.status !== 200) {
        // Si la respuesta no es exitosa, agregar el mensaje de error al objeto result
        const errorMessage = result.message || "Error desconocido";
        result.error = `Error de la API: ${errorMessage}`;
      }

      return result;
    } catch (error) {
      // Capturar cualquier error de red u otros errores y agregarlos al objeto result
      const errorMessage = error.message || "Error de red";
      return { error: `Error: ${errorMessage}` };
    }
  }

  async getResponsables(accessToken, active = undefined) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.RESPONSABLE}?active=${active}`;
      const params = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) {
        // Si la respuesta no es exitosa, agregar el mensaje de error al objeto result
        const errorMessage = result.message || "Error desconocido";
        result.error = `Error de la API: ${errorMessage}`;
      }

      return result;
    } catch (error) {
      // Capturar cualquier error de red u otros errores y agregarlos al objeto result
      const errorMessage = error.message || "Error de red";
      return { error: `Error: ${errorMessage}` };
    }
  }

  async updateUser(accessToken, idUser, userData) {
    try {
      const data = userData;
      if (!data.password) {
        delete data.password;
      }

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      if (data.fileAvatar) {
        formData.append("avatar", data.fileAvatar);
      }

      const url = `${ENV.BASE_API}/${ENV.API_ROUTES.USER}/${idUser}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) {
        // Si la respuesta no es exitosa, agregar el mensaje de error al objeto result
        const errorMessage = result.message || "Error desconocido";
        result.error = `Error de la API: ${errorMessage}`;
      }

      return result;
    } catch (error) {
      // Capturar cualquier error de red u otros errores y agregarlos al objeto result
      const errorMessage = error.message || "Error de red";
      return { error: `Error: ${errorMessage}` };
    }
  }

  async deleteUser(accessToken, idUser) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${idUser}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) {
        // Si la respuesta no es exitosa, agregar el mensaje de error al objeto result
        const errorMessage = result.message || "Error desconocido";
        result.error = `Error de la API: ${errorMessage}`;
      }

      return result;
    } catch (error) {
      // Capturar cualquier error de red u otros errores y agregarlos al objeto result
      const errorMessage = error.message || "Error de red";
      return { error: `Error: ${errorMessage}` };
    }
  }
}
