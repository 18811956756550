import React from "react";
// import MenuWeb from "../components/menus/MenuWeb";
// import { Layout } from "antd";
import Navbar from "../components/menus/Navbar";
// import Footer from "../components/Public/Footer";

export default function LayoutBasic(props) {
  const { children } = props;

  return (
    <>
      {/* <Navbar /> */}
      {children}
    </>
  );
}
