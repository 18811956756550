import { useEffect, useState } from "react";
import { Layout, Row, Col, Button, Table } from "antd";
import { SendOutlined } from "@ant-design/icons";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { getSearchRegistroApi } from "../../../api/despachoApi";
import AddRegistroForm from "../../../components/Gestion/Almacen/EnRuta/AddRegistroForm";
import { useModalWithData } from "../../../hooks/useModal";
import CustomModal from "../../../components/modal/CustomModal";

import { HeaderModulo } from "../../../components/Buttons";
import Buscar from "../../../components/tools/Buscar";
import { useAuth } from "../../../hooks";

export default function EnRuta() {
  // console.log(props);
  const { accessToken } = useAuth();
  const token = accessToken;
  const { Content } = Layout;
  const [registros, setRegistros] = useState([]);
  // const [datos, setDatos] = useState([]);
  const [setIsModalOpened, isModalOpened, modalData, setModalData] =
    useModalWithData();

  const [loading, setLoading] = useState(false);
  const [accionMostrar, setAccionMostrar] = useState("");
  const [reloadPage, setReloadPage] = useState(false);
  const [buscar, setBuscar] = useState({
    tipo_busqueda: "factura",
    buscar: "",
  });

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    getSearchRegistroApi(token, {
      modulo: "enruta",
      query: buscar,
      RegistroEstado: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoading(false);
      } else {
        // console.log(resp);
        setRegistros(
          resp.datos.map((row) => ({
            //...row,
            _id: row._id,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            fecha_factura: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            cliente: row.cliente,
            nombre_vendedor: row.nombre_vendedor,
            comprobante: row.comprobante,
            comentario: row.comentario,
            repartidor: row.nombre_repartidor,
            total_factura: row.total_factura,
            idFactura: row._factura,
            _idUltimo: row._idUltimo,
            accion: row.accion,
            fecha_agendada: moment(row.fecha_agendada)
              .utc()
              .format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getSearchRegistroApi(token, {
      modulo: "enruta",
      query: "",
      RegistroEstado: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoading(false);
      } else {
        // console.log(resp);
        setRegistros(
          resp.datos.map((row) => ({
            //...row,
            _id: row._id,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            fecha_factura: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            cliente: row.cliente,
            nombre_vendedor: row.nombre_vendedor,
            comprobante: row.comprobante,
            comentario: row.comentario,
            repartidor: row.nombre_repartidor,
            total_factura: row.total_factura,
            idFactura: row._factura,
            _idUltimo: row._idUltimo,
            accion: row.accion,
            fecha_agendada: moment(row.fecha_agendada)
              .utc()
              .format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  }, [reloadPage]);

  const onAccion = (record, accion) => {
    setModalData(record);
    setIsModalOpened(true);
    setAccionMostrar(accion);
  };

  // console.log(registros.fecha_factura);

  const columns = [
    {
      title: "Nombre Cliente",
      width: 150,
      dataIndex: "nombre_cliente",
      key: "nombre",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Factura",
      width: 80,
      dataIndex: "factura",
      key: "factura",
      // fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Fecha",
      width: 80,
      dataIndex: "fecha",
      key: "fecha",
      // fixed: "left",
      responsive: ["sm"],
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
    },
    {
      title: "Despachada",
      width: 80,
      dataIndex: "fecha_agendada",
      key: "fecha_agendada",
      // fixed: "left",
      responsive: ["sm"],
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.fecha_agendada).unix() - moment(b.fecha_agendada).unix(),
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      width: 80,
      responsive: ["sm"],
    },
    {
      title: "Chofer",
      dataIndex: "repartidor",
      key: "repartidor",
      width: 100,
      responsive: ["sm"],
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 60,
      render: (record) => (
        <>
          <Button
            size="large"
            style={{
              backgroundColor: "#27ae60",
            }}
            onClick={() => onAccion(record, "accion")}
            icon={
              <SendOutlined
                style={{ fontSize: "28px", color: "#ffffff", padding: 0 }}
                theme="outlined"
              />
            }
          ></Button>
        </>
      ),
    },
  ];
  // console.log(modalData);
  const showModal = (accion) => {
    if (accion === "accion") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddRegistroForm
            registro={modalData}
            modulo={"enruta"}
            RegistroEstado={"enalmacen"}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
          />
        </CustomModal>
      );
    }
  };

  return (
    <>
      <div className="md:mx-10 mx-2">
        {showModal(accionMostrar)}
        <div className="mb-1.5">
          <HeaderModulo
            modulo={"enruta"}
            registroEstado={"enalmacen"}
            setReloadPage={setReloadPage}
            estado={true}
            reloadPage={reloadPage}
          />

          <Buscar
            setBuscar={setBuscar}
            modulo={"enruta"}
            buscar={buscar}
            onChangeSearch={onChangeSearch}
          />
        </div>

        {/* <Buscador setBuscar={setBuscar} modulo={"enruta"} buscar={buscar} /> */}

        <Row gutter={12} className="md:mx-10">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={registros}
              columns={columns}
              size="small"
              pagination={{ position: ["bottomRight"], pageSize: 50 }}
              style={{ marginTop: 0 }}
              scroll={{ y: 480 }}
              bordered
            />
          </Col>
        </Row>
        {/* <div className="site-layout-background"></div> */}
      </div>
    </>
  );
}
