import React from "react";
import LoginForm from "../../../components/Gestion/LoginForm/LoginForm";
// import { getAccessTokenApi } from "../../../api/auth";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../hooks";

export default function SignIn() {
  const { user } = useAuth();

  console.log(useAuth());

  if (user) {
    return <Navigate to="/admin" />;
  }

  // if (getAccessTokenApi()) {
  //   return <Navigate to="/admin" />;
  // }
  return (
    <>
      {/* <MenuWeb /> */}
      <div className="contenido">
        <LoginForm />
      </div>
    </>
  );
}
