import { useEffect, useState } from "react";
import { Layout, Button, Table } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";

import { getFichasApi } from "../../../../api/fichasApi";
import { useModalWithData } from "../../../../hooks/useModal";
import EditFicha from "../../../../components/Gestion/Almacen/Fichas/EditFicha";
import DeleteFichas from "../../../../components/Gestion/Almacen/Fichas/DeleteFichas";
import Buscar from "../../../../components/tools/Buscar";
import { HeaderModulo } from "../../../../components/Buttons";
import Modal from "../../../../components/modal/Modal";
import { useAuth } from "../../../../hooks";

export default function FichasPages() {
  const [fichas, setFichas] = useState([]);
  // const [isOpenModalAgregar, openModalAgregar, closeModalAgregar] = useModal();
  const [loading, setLoading] = useState(true);
  const [reloadPage, setReloadPage] = useState(false);
  const [accionMostrar, setAccionMostrar] = useState("");
  // const [setIsModalOpened] = useModalWithData();
  const [estadoModal, setEstadoModal] = useState(false);
  const [datos, setDatos] = useState(true);

  const { accessToken } = useAuth();
  const token = accessToken;

  const [buscar, setBuscar] = useState({
    tipo_busqueda: "fichas",
    buscar: "",
  });

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    getFichasApi(token, {
      modulo: "fichas",
      query: buscar,
      RegistroEstado: "fichas",
      activo: true,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setFichas([]);
        setLoading(false);
      } else {
        // console.log(resp);
        setFichas(resp.datos);
        setLoading(false);
        // setReloadPage(!reloadPage);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getFichasApi(token, {
      modulo: "fichas",
      query: "",
      RegistroEstado: "fichas",
      activo: true,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setFichas([]);
        setLoading(false);
      } else {
        setFichas(resp.datos);
        setLoading(false);
      }
    });
  }, [reloadPage]);

  // const handledChangeActive = (e) => {
  //   // console.log(e.target.checked)
  //   setActive(e.target.checked);
  // };

  const onAccion = (record, accion) => {
    setDatos(record);
    // setIsModalOpened(true);
    setAccionMostrar(accion);
    setEstadoModal(!estadoModal);
  };

  const columns = [
    // {
    //   title: "_id",
    //   width: 20,
    //   dataIndex: "_id",
    //   key: "_id",
    //   fixed: "left",
    //   showHeader: false,
    //   responsive: [""],
    // },
    {
      title: "Ficha",
      width: 10,
      dataIndex: "ficha",
      key: "ficha",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Placa",
      width: 10,
      dataIndex: "placa",
      key: "placa",
      fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Modelo",
      width: 10,
      dataIndex: "modelo",
      key: "modelo",
      fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Chasis",
      dataIndex: "chasis",
      key: "chasis",
      width: 10,
      // responsive: ["sm"]
    },
    {
      title: "Año",
      dataIndex: "anno",
      key: "anno",
      width: 6,
      // responsive: ["sm"]
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 10,
      render: (record) => (
        <>
          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#e8f3f8",
              margin: 1,
              padding: 0,
            }}
            onClick={() => onAccion(record, "edit")}
          >
            <EditOutlined
              style={{ fontSize: "16px", color: "#08c", margin: 5, padding: 0 }}
            />
          </Button>
          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#e8f3f8",
              margin: 1,
              padding: 0,
            }}
            onClick={() => onAccion(record, "delete")}
          >
            <CloseOutlined
              style={{
                fontSize: "16px",
                color: "#FF5733",
                margin: 5,
                padding: 0,
              }}
            />
          </Button>
        </>
      ),
    },
  ];

  // console.log(buscar);
  const showModal = (accion) => {
    if (accion === "edit") {
      return (
        <Modal
          estadoModal={estadoModal}
          setEstadoModal={setEstadoModal}
          titulo={`Editando la ficha ${datos.ficha}`}
          mostrarHeader={true}
          mostrarOverlay={true}
        >
          <EditFicha
            reloadPage={reloadPage}
            registro={datos}
            estadoModal={estadoModal}
            setEstadoModal={setEstadoModal}
            setReloadPage={setReloadPage}
          />
        </Modal>
      );
    } else if (accion === "delete") {
      return (
        <Modal
          estadoModal={estadoModal}
          setEstadoModal={setEstadoModal}
          titulo={`Eliminando la ficha ${datos.ficha}`}
          mostrarHeader={true}
          mostrarOverlay={true}
        >
          <DeleteFichas
            reloadPage={reloadPage}
            registro={datos}
            estadoModal={estadoModal}
            setEstadoModal={setEstadoModal}
            setReloadPage={setReloadPage}
          />
        </Modal>
      );
    }
  };
  //   console.log(fichas);
  return (
    <div className="md:mx-10 mx-2">
      {showModal(accionMostrar)}
      <div className="mb-1.5">
        <HeaderModulo
          modulo={"fichas"}
          registroEstado={"fichas"}
          setReloadPage={setReloadPage}
          estado={estadoModal}
          reloadPage={reloadPage}
        />

        <Buscar
          setBuscar={setBuscar}
          modulo={"fichas"}
          buscar={buscar}
          onChangeSearch={onChangeSearch}
        />
      </div>

      <div
        className="site-layout-background"
        style={{ padding: 5, minHeight: 380 }}
      >
        <Table
          loading={loading}
          rowKey={(record) => record._id}
          // rowSelection={rowSelection}
          dataSource={fichas}
          columns={columns}
          size="small"
          pagination={{ position: ["bottomRight"] }}
          style={{ marginTop: 0 }}
          scroll={{ y: 480 }}
          bordered
        />
      </div>
    </div>
  );
}
