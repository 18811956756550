import { useEffect, useState } from "react";
import { Layout, Row, Col, Button, Table } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { getSearchRegistroApi } from "../../../api/despachoApi";
import AddForm from "../../../components/Gestion/Almacen/Devolucion/AddRegistro";
import { useModalWithData } from "../../../hooks/useModal";
import CustomModal from "../../../components/modal/CustomModal";
import ReporteAlmacen from "../../../components/modal/ReporteAlmacen";
import { HeaderModulo } from "../../../components/Buttons";
import Buscar from "../../../components/tools/Buscar";
import { useAuth } from "../../../hooks";

export default function Recibir() {
  const { accessToken } = useAuth();
  const token = accessToken;
  const { Content } = Layout;
  const [registros, setRegistros] = useState([]);
  const [setIsModalOpened, isModalOpened, modalData, setModalData] =
    useModalWithData();
  const [loading, setLoading] = useState(false);
  const [accionMostrar, setAccionMostrar] = useState("");
  const [reloadPage, setReloadPage] = useState(false);
  const [buscar, setBuscar] = useState({
    tipo_busqueda: "factura",
    buscar: "",
  });

  // const stringCodigoBara = buscar?.buscarGlobal;
  // const codigoBarra = stringCodigoBara?.substring(
  //   0,
  //   stringCodigoBara.length - 1
  // );

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    getSearchRegistroApi(token, {
      modulo: "devolucion",
      query: buscar,
      RegistroEstado: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoading(false);
      } else {
        setRegistros(
          resp.datos.map((row) => ({
            //...row,
            _id: row._id,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            codigo_barra: row.codigo_barra,
            comprobante: row.comprobante,
            vendedor: row.vendedor,
            fecha_factura: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            cliente: row.cliente,
            nombre_vendedor: row.nombre_vendedor,
            comentario: row.comentario,
            repartidor: row.nombre_repartidor,
            total_factura: row.total_factura,
            idFactura: row._factura,
            _idUltimo: row._idUltimo,
            accion: row.accion,
            fecha_agendada: moment(row.fecha_agendada)
              .utc()
              .format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getSearchRegistroApi(token, {
      modulo: "devolucion",
      query: "",
      RegistroEstado: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoading(false);
      } else {
        setRegistros(
          resp.datos.map((row) => ({
            //...row,
            _id: row._id,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            codigo_barra: row.codigo_barra,
            comprobante: row.comprobante,
            vendedor: row.vendedor,
            fecha_factura: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            cliente: row.cliente,
            nombre_vendedor: row.nombre_vendedor,
            comentario: row.comentario,
            repartidor: row.nombre_repartidor,
            total_factura: row.total_factura,
            idFactura: row._factura,
            _idUltimo: row._idUltimo,
            createdAt: moment(row.createdAt).utc().format("YYYY-MM-DD"),
            accion: row.accion,
            fecha_agendada: moment(row.fecha_agendada)
              .utc()
              .format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  }, [reloadPage]);

  const onAccion = (record, accion) => {
    setModalData(record);
    setIsModalOpened(true);
    setAccionMostrar(accion);
  };

  const columns = [
    {
      title: "Nombre Cliente",
      width: 150,
      dataIndex: "nombre_cliente",
      key: "nombre",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Factura",
      width: 80,
      dataIndex: "factura",
      key: "factura",
      // fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Fecha",
      width: 80,
      dataIndex: "fecha",
      key: "fecha",
      // fixed: "left",
      responsive: ["sm"],
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
    },
    {
      title: "Fecha Recibida",
      width: 80,
      dataIndex: "createdAt",
      key: "createdAt",
      // fixed: "left",
      responsive: ["sm"],
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      width: 80,
      responsive: ["sm"],
    },
    {
      title: "Chofer",
      dataIndex: "repartidor",
      key: "repartidor",
      width: 100,
      responsive: ["sm"],
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 60,
      render: (record) => (
        <>
          <Button
            size="large"
            style={{
              backgroundColor: "#27ae60",
              // marginRight: 3,
              // paddgin: 0,
              padding: 5,
            }}
            onClick={() => onAccion(record, "agregar")}
            icon={
              <CheckOutlined
                style={{ fontSize: "20px", color: "#ffffff" }}
                theme="outlined"
              />
            }
          ></Button>
        </>
      ),
    },
  ];
  const showModal = (accion) => {
    if (accion === "agregar") {
      return (
        <CustomModal
          title="Proceso de Devolucion"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddForm
            registro={modalData}
            modulo={"devolucion"}
            RegistroEstado={"enalmacen"}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
          />
        </CustomModal>
      );
    } else if (accion === "reporte") {
      return (
        <CustomModal
          title="Generacion de Reportes"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <ReporteAlmacen
            registros={registros}
            setIsModalOpened={setIsModalOpened}
            //  setReloadPage={setReloadPage}
            modulo={accion}
          />
        </CustomModal>
      );
    }
  };

  return (
    <div className="md:mx-10 mx-2">
      {showModal(accionMostrar)}
      <div className="mb-1.5">
        <HeaderModulo
          modulo={"devolucion"}
          registroEstado={"enalmacen"}
          setReloadPage={setReloadPage}
          estado={true}
          reloadPage={reloadPage}
        />

        <Buscar
          setBuscar={setBuscar}
          modulo={"devolucion"}
          buscar={buscar}
          onChangeSearch={onChangeSearch}
        />
      </div>

      {/* <Buscador setBuscar={setBuscar} modulo={"devolucion"} buscar={buscar} /> */}

      <Row gutter={12} className="md:mx-10">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            loading={loading}
            rowKey={(record) => record.factura}
            dataSource={registros}
            columns={columns}
            size="small"
            pagination={{ position: ["bottomRight"], pageSize: 50 }}
            style={{ marginTop: 0 }}
            scroll={{ y: 480 }}
            bordered
          />
        </Col>
      </Row>
      {/* <div className="site-layout-background"></div> */}
    </div>
  );
}
