import { ENV } from "../utils";
export class Nacheros {
  baseApi = ENV.BASE_API;

  async getClientes(token, datos) {
    // console.log(datos);
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.GET_CLIENTES}`;

      // ?vendedor=${datos}

      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(datos),
      };

      const resp = await fetch(url, params);
      const result = await resp.json();

      if (resp.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getProductos(token, datos) {
    // console.log(datos);
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.GET_ARTICULOS}`;

      // ?nivelPrecio=${nivelPrecio}&versionPrecio=${versionPrecio}
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(datos),
      };

      const resp = await fetch(url, params);
      const result = await resp.json();

      if (resp.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getResumen(token, datos) {
    // console.log("paso por aqui");
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.GET_RESUMEN}`;

      // ?nivelPrecio=${nivelPrecio}&versionPrecio=${versionPrecio}
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(datos),
      };

      const resp = await fetch(url, params);
      const result = await resp.json();

      if (resp.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }
}
