import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../../hooks";
import { Nacheros } from "../../../api";
import { Layout, Row, Col, Table } from "antd";
import Buscar from "../../tools/Buscar";

import "./distribuccion.scss";

const dataController = new Nacheros();
const { Content } = Layout;

export default function ListadoClientes() {
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { user, accessToken } = useAuth();
  const token = accessToken;
  const { vendedor } = user;
  const { opcion, retail } = location.state;

  const [buscar, setBuscar] = useState({
    vendedor: vendedor,
    retail: retail,
    buscar: "",
  });

  console.log("Listado de Clientes");

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    dataController.getClientes(token, { query: buscar }).then((resp) => {
      if (!resp?.datos?.length) {
        setClientes([]);
        setLoading(false);
      } else {
        setClientes(
          resp.datos.map((row, index) => ({
            _id: index,
            cliente: row.CLIENTE,
            nombre: row.NOMBRE,
            ruta: row.RUTA,
            categoria_cliente: row.CATEGORIA_CLIENTE,
            direccion: row.DIRECCION,
            vendedor: row.VENDEDOR,
            nivel_precio: row.NIVEL_PRECIO,
            version: row.VERSION,
          }))
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    dataController
      .getClientes(token, {
        query: buscar,
      })
      .then((resp) => {
        if (!resp?.datos?.length) {
          setClientes([]);
          setLoading(false);
        } else {
          setClientes(
            resp.datos.map((row, index) => ({
              _id: index,
              cliente: row.CLIENTE,
              nombre: row.NOMBRE,
              ruta: row.RUTA,
              categoria_cliente: row.CATEGORIA_CLIENTE,
              direccion: row.DIRECCION,
              vendedor: row.VENDEDOR,
              nivel_precio: row.NIVEL_PRECIO,
              version: row.VERSION,
            }))
          );
          setLoading(false);
        }
      });
  }, [token, vendedor]);

  const columns = [
    {
      title: "Cliente",
      width: 30,
      dataIndex: "cliente",
      key: "cliente",
      fixed: "left",
      // responsive: ["sm"],
    },
    {
      title: "Nombre",
      width: 60,
      dataIndex: "nombre",
      key: "nombre",
      // fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Ruta",
      width: 20,
      dataIndex: "ruta",
      key: "ruta",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Categoria",
      width: 30,
      dataIndex: "categoria_cliente",
      key: "categoria_cliente",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Vendedor",
      width: 30,
      dataIndex: "vendedor",
      key: "vendedor",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Direccion",
      width: 120,
      dataIndex: "direccion",
      key: "direccion",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Nivel Precio",
      width: 30,
      dataIndex: "nivel_precio",
      key: "nivel_precio",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Version",
      width: 20,
      dataIndex: "version",
      key: "version",
      // fixed: "left",
      responsive: ["sm"],
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 60,
      render: (record) => (
        <>
          <Link
            className="btn-action"
            to="/admin/nacheros/vamos-hacer/productos"
            state={{ cliente: record, opcion: opcion }}
          >
            Agregar
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Content className="site-layout" style={{ margin: 20 }}>
        <div className="header">
          <Buscar
            setBuscar={setBuscar}
            modulo={"agendado"}
            buscar={buscar}
            onChangeSearch={onChangeSearch}
          />
        </div>

        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={clientes}
              columns={columns}
              size="small"
              pagination={{ position: ["bottomRight"], pageSize: 50 }}
              style={{ marginTop: 0 }}
              scroll={{ y: 480 }}
              bordered
            />
          </Col>
        </Row>
      </Content>
    </>
  );
}
