import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getConsecutivoApi,
  getConsecutivoFacturaApi,
  getDatosNacherosApi,
  insPedidos,
} from "../../../api/ayudaApi";
import { useAuth, useLocalStorage } from "../../../hooks";
import Loading from "../../Spinner/Loading";
import { zfill } from "../../../hooks/useFill";
import Modal from "../../modal/Modal";

import "./distribuccion.scss";

export default function ResumenProcesar() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState({
    cantidadDist: "",
  });
  const [datos, setDatos] = useLocalStorage("noventas", "");
  const [ofertas, setOfertas] = useState({
    factor_bonif: "",
    unidades_bonif: "",
  });
  const [inactivo, setInactivo] = useState(true);
  const [consecutivo, setConsecutivo] = useState(0);
  const [consecutivoFactura, setConsecutivoFactura] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);
  const [estadoModal, setEstadoModal] = useState(false);
  const { accessToken } = useAuth();
  const token = accessToken;

  const { opcion } = location.state;
  // console.log(location);
  let navigate = useNavigate();
  const {
    articulo,
    clasificacion,
    descripcion,
    impuesto,
    peso_bruto,
    grupo,
    precio,
  } = location.state.articulo;

  const { cliente, nombre, direccion, categoria_cliente, vendedor, ruta } =
    location.state.cliente;

  useEffect(() => {
    getConsecutivoApi(token, { vendedor: vendedor }).then((resp) => {
      setConsecutivo(resp.consecutivo);
      // console.log(resp.consecutivo);
    });
    getConsecutivoFacturaApi(token, { vendedor: vendedor }).then((resp) => {
      setConsecutivoFactura(resp.consecutivo);
      // console.log(resp.consecutivo);
    });
  }, [vendedor, loading]);

  const onFiltroChange = (field) => (e) => {
    const { value } = e.target;

    setFiltro({
      ...filtro,
      [field]: value,
    });
  };

  const backBtn = (placeToGo) => {
    navigate(placeToGo, { replace: true });
    setDatos("noventas", {});
    // setEstadoModal(!estadoModal);
  };

  const getDatosNacheros = async (e) => {
    e.preventDefault();

    const { cantidadDist } = filtro;

    try {
      if (cantidadDist === "") {
        toast("Debe introducir un valor.");
      } else {
        setLoading(true);
        const res = await getDatosNacherosApi(token, {
          anno: moment(new Date()).format("YYYY"),
          mes: moment(new Date()).format("MM"),
          dia: moment(new Date()).format("DD"),
          // anno: '2022',
          // mes: '11',
          // dia: '05',
          precio,
          peso_bruto,
          articulo,
          opcion,
          vendedor,
          categoria: clasificacion,
          retail: categoria_cliente,
          grupo,
          filtro, // [field]: value,
        }).then((resp) => resp);

        if (res.code !== 200) {
          console.log(res);
          setDatos(res);
          setLoading(false);
          setInactivo(false);
        } else {
          if (res.datos.length > 0) {
            setDatos(res);
            setInactivo(res.isMayor);
            setOfertas({
              factor_bonif: res.oferta[0].factor_bonif,
              unidades_bonif: res.oferta[0].unidades_bonif,
            });
            setLoading(false);
            console.log(res);
          } else {
            setDatos({});
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInsertPedido = async (e) => {
    e.preventDefault();
    let datosIns = [];

    if (datos.isMayor) {
      toast(
        "Houston!, La cantidad a distribuir es mayor a Cantidad de Cliente sin Ventas "
      );
    } else {
      setEstadoModal(!estadoModal);
      setLoadingModal(true);
      for (let i = 0; i < datos.datos?.length; i++) {
        datosIns.push({
          pedido:
            "PRIP" + vendedor + "-" + zfill(parseInt(consecutivo) + 1 + i, 10),
          conseFactura:
            "FACR" +
            vendedor +
            "-" +
            zfill(parseInt(consecutivoFactura) + 1, 10),
          cliente: datos.datos[i].cliente,
          cliente_corporac: datos.datos[i].cliente_corporac,
          cliente_direccion: datos.datos[i].cliente_direccion,
          cliente_origen: datos.datos[i].cliente_origen,
          nombre: datos.datos[i].nombre,
          categoria_cliente: datos.datos[i].categoria_cliente,
          vendedor: datos.datos[i].vendedor,
          cobrador: datos.datos[i].cobrador,
          ruta: datos.datos[i].ruta,
          zona: datos.datos[i].zona,
          pais: datos.datos[i].pais,
          condicion_pago: datos.datos[i].condicion_pago,
          direccion: datos.datos[i].direccion,
          nivel_precio: datos.datos[i].nivel_precio,
          cliente_factura: cliente,
          cliente_nombre_factura: nombre,
          cliente_direccion_factura: direccion,
          retail: categoria_cliente,
          ruta_factura: ruta,
          vendedor_factura: vendedor,
          articulo: articulo,
          articulo_bonif: datos.oferta[0].articulo_bonif
            ? datos.oferta[0].articulo_bonif
            : 0,
          factor_bonif: datos.oferta[0].factor_bonif,
          unidad_bonif: datos.oferta[0].unidades_bonif
            ? datos.oferta[0].unidades_bonif
            : 0,
          cantidad_cliente: datos.ClienteDistribuir,
          cantidad_unidad: datos.oferta[0].factor_bonif,
          precio: precio,
          descripcion: descripcion,
          peso_bruto: peso_bruto,
          total_a_facturar:
            precio * datos.oferta[0].factor_bonif * (1 + impuesto / 100),
          total_impuesto:
            precio * datos.oferta[0].factor_bonif * (1 + impuesto / 100) -
            precio * datos.oferta[0].factor_bonif,
          total_mercaderia: precio * datos.oferta[0].factor_bonif,
          opcion: opcion,
        });

        // let cont = 23;
        // console.log(
        //   "PRIP" + vendedor + "-" + zfill(parseInt(consecutivo) + 1 + i, 10)
        // );
      }

      const rs = await insPedidos(token, datosIns);
      if (rs.code === 200) {
        setLoadingModal(false);
      } else {
        toast("Houston!, Hubo un problema.", rs);
      }
      console.log(datosIns);
    }
  };

  console.log("Procesar - Fin");
  return (
    <>
      <div className="contenido-resumen">
        {/* <h1>Resumen y Procesar</h1> */}
        <div className="nacheros">
          <div className="text">
            <h3>{cliente}</h3> {/* Codigo del cliente */}
            <span>{nombre}</span>
            <h2>{descripcion}</h2>
            <h4>
              <strong> Compra :: </strong>
              {ofertas?.factor_bonif === ""
                ? "NO_OFERTA"
                : ofertas?.factor_bonif}
              <strong> :: Lleva :: </strong>
              {ofertas?.unidades_bonif === ""
                ? "NO_OFERTA"
                : ofertas?.unidades_bonif}
            </h4>
          </div>
          <div className="input-box-nacheros">
            <i className="bx bxs-edit"></i>
            <input
              type="text"
              placeholder="Cantidad a Distribuir..."
              onChange={onFiltroChange("cantidadDist")}
            />
            <button className="button" onClick={getDatosNacheros}>
              Calcular
            </button>
          </div>
          <div className="text-msg">{datos?.msg ? datos.msg : <></>}</div>
          <div className="resultado-nacheros">
            <ul className="box-info-nacheros">
              <li>
                <i className="data-no">
                  {loading ? <Loading /> : datos?.ClienteSinVentas}
                </i>
                <span className="text">
                  <h3>Sin Ventas</h3>
                </span>
              </li>
              <li>
                <i className="data-no">
                  {loading ? <Loading /> : datos?.ClienteDistribuir}
                </i>
                <span className="text">
                  <h3>Distribuir</h3>
                </span>
              </li>
            </ul>
          </div>
          <div className="input-field-nacheros btn-nacheros">
            {inactivo ? (
              <>
                <button
                  disabled
                  onClick={handleInsertPedido}
                  className="btn-action"
                >
                  Bloqueado
                </button>
              </>
            ) : (
              <>
                <button onClick={handleInsertPedido} className="btn-action">
                  Enviar
                </button>
              </>
            )}
            <button
              onClick={() => backBtn("/admin/nacheros")}
              className="btn-action btn-red"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>

      <Modal
        estadoModal={estadoModal}
        // setEstadoModal={backBtn}
        titulo={loadingModal ? "Cargando Documento...." : "Informacion"}
        mostrarHeader={true}
        mostrarOverlay={true}
      >
        {loadingModal ? (
          <>
            <h2>Cargado documentos, favor de esperar...</h2>
            <Loading />
          </>
        ) : (
          <div className="contenidoRipeo">
            <h1>{nombre} </h1>
            <h2>{descripcion}</h2>
            <div>
              <h2>Proceso Termino con exito!</h2>
            </div>

            <button
              onClick={() => backBtn("/admin/nacheros")}
              className="btn-action btn-red"
            >
              Generar nuevo proceso
            </button>
          </div>
        )}
      </Modal>
    </>
  );
}
