import React from "react";
import { Link } from "react-router-dom";

import { List } from "../../../components/Gestion/Almacen/List";

export default function Contabilidad() {
  return (
    <div className="w-full flex justify-center items-center flex-wrap mt-4">
      <ul className="w-full mx-3 space-y-2 md:flex md:justify-evenly md:w-full gap-1 md:flex-wrap md:items-center">
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <Link to="/admin/contab/notasc">
            <i className="bx bxs-dollar-circle w-[60px] h-[60px] m-0 p-0 rounded-full text-[48px] flex justify-center items-center"></i>
          </Link>
          <Link to="/admin/contab/notasc">
            <span>
              <h3 className="text-[20px] text-white">Nota de Credito</h3>
              {/* <p className="text-white">cantidad</p> */}
            </span>
          </Link>
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/contab/facturacion"
            titulo="Factura"
            icon="bx-check-square"
            modulo="contabilidad"
            estado={false}
          />
        </li>
      </ul>
    </div>
  );
}
