import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

import ListadoAplicadas from "../../../../../components/Gestion/Contabilidad/NotasCreditos/NotasAplicadas";
import conn from "../../../../../helpers/config";
const { basePathApi, apiVersion } = conn;

export default function NotasAplicadas(props) {
  const { match } = props;
  const id = match.params.id;

  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  const url = `${basePathApi}/${apiVersion}/notascredito/aplicadas`;

  useEffect(() => {
    async function notasData() {
      try {
        const data = await fetch(url);
        const datajson = await data.json();
        if (datajson !== []) {
          console.log(datajson);
          setDatas(datajson.doc.filter((data) => data.CODIGO === id));
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    notasData();
  }, [id, url]);

  // console.log(datas);

  // Suma total Aplicado
  let total = datas.map((valor) => ({
    total: valor.MONTO,
  }));

  const totalAplicado = total.reduce((prev, next) => prev + next.total, 0);

  // Fin de Formula Total Aplicado

  return (
    <>
      <Container className="mt-2">
        <h2>
          Listado Nota Credito Aplicada{" "}
          <NumericFormat
            thousandsGroupStyle="thousand"
            value={totalAplicado}
            prefix="$"
            decimalSeparator="."
            displayType="text"
            // type="text"
            thousandSeparator={true}
            allowNegative={true}
          />
        </h2>
        <ListadoAplicadas datas={datas} loading={loading} />
      </Container>
    </>
  );
}
