import React from "react";
import { Link } from "react-router-dom";

import { List } from "../../../components/Gestion/Almacen/List";

function Dashboard() {
  return (
    <div className="w-full flex justify-center items-center mb-2 mt-2">
      <ul className="w-full mx-3 space-y-2 md:flex md:justify-evenly md:w-full gap-1 md:flex-wrap md:items-center">
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <Link to="/admin/almacen/consulta">
            <i className="bx bx-search  w-[60px] h-[60px] m-0 p-0 rounded-full text-[48px] flex justify-center items-center"></i>
          </Link>
          <Link to="/admin/almacen/consulta">
            <span>
              <h3 className="text-[20px] text-black font-semibold mx-4">
                Consulta
              </h3>
              {/* <p>Consulta</p> */}
            </span>
          </Link>
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/facturacion"
            titulo="Facturacion"
            icon="bxs-dollar-circle"
            modulo="facturacion"
            estado={false}
          />
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/agendar"
            titulo="Agendar"
            icon="bxs-calendar-check"
            modulo="agendado"
            estado={false}
          />
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/despacho"
            titulo="Despacho"
            icon="bx-package"
            modulo="despacho"
            estado={false}
          />
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/enruta"
            titulo="En Ruta"
            icon="bxs-truck"
            modulo="enruta"
            estado={false}
          />
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/enalmacen"
            titulo="Almacen"
            icon="bx-archive-in"
            modulo="enalmacen"
            estado={false}
          />
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/devolucion"
            titulo="Devolucion"
            icon="bx-trash"
            modulo="devolucion"
            estado={false}
          />
        </li>

        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/fichas"
            titulo="Fichas"
            icon="bxl-facebook"
            modulo="ficha"
            estado={true}
          />
        </li>
        <li className="p-6 bg-blue-300 rounded-3xl flex items-center hover:bg-gray-300 md:w-[25%] md:hover:cursor-pointer">
          <List
            url="/admin/almacen/repartidores"
            titulo="Repartidores"
            icon="bx-registered"
            modulo="repartidor"
            estado={true}
          />
        </li>
      </ul>
    </div>
  );
}

export default Dashboard;
