import { useEffect, useState } from "react";
import { Layout, Row, Col, Button, Table } from "antd";
import { SendOutlined } from "@ant-design/icons";
import moment from "moment";
import { NumericFormat } from "react-number-format";
// import { toast } from "react-toastify";

// import { nanoid } from "nanoid";

import { getSearchRegistroApi } from "../../../../api/despachoApi";
import AddRegistroFrom from "../../../../components/Gestion/Contabilidad/RecibirFactura/AddRegistroForm";

import { useModalWithData } from "../../../../hooks/useModal";
import CustomModal from "../../../../components/modal/CustomModal";
import ReporteContabilidad from "../../../../components/modal/ReporteAlmacen";
import { HeaderModulo } from "../../../../components/Buttons";
import Buscar from "../../../../components/tools/Buscar";
import { useAuth } from "../../../../hooks";

export default function Facturas() {
  const { accessToken } = useAuth();
  const token = accessToken;
  const { Content } = Layout;
  const [registros, setRegistros] = useState([]);
  // const [datos, setDatos] = useState([]);
  const [setIsModalOpened, isModalOpened, modalData, setModalData] =
    useModalWithData();
  const [loading, setLoading] = useState(false);
  const [accionMostrar, setAccionMostrar] = useState("");
  const [reloadPage, setReloadPage] = useState(false);
  const [buscar, setBuscar] = useState({
    tipo_busqueda: "factura",
    buscar: "",
  });

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    getSearchRegistroApi(token, {
      modulo: "contabilidad",
      query: buscar,
      RegistroEstado: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoading(false);
      } else {
        setRegistros(
          resp.datos.map((row) => ({
            // ...row,
            _id: row._id,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            fecha_factura: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            nombre_vendedor: row.nombre_vendedor,
            cliente: row.cliente,
            comprobante: row.comprobante,
            comentario: row.comentario,
            repartidor: row.nombre_repartidor,
            total_factura: row.total_factura,
            idFactura: row._factura,
            _idUltimo: row._idUltimo,
            fecha_recibida: moment(row.createdAt).utc().format("YYYY-MM-DD"),
            fecha_agendada: moment(row.fecha_agendada)
              .utc()
              .format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getSearchRegistroApi(token, {
      modulo: "contabilidad",
      query: "",
      RegistroEstado: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoading(false);
      } else {
        setRegistros(
          resp.datos.map((row) => ({
            // ...row,
            _id: row._id,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            fecha_recibida: moment(row.createdAt).utc().format("YYYY-MM-DD"),
            fecha_factura: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            nombre_vendedor: row.nombre_vendedor,
            cliente: row.cliente,
            comprobante: row.comprobante,
            comentario: row.comentario,
            repartidor: row.nombre_repartidor,
            total_factura: row.total_factura,
            idFactura: row._factura,
            _idUltimo: row._idUltimo,
            fecha_agendada: moment(row.fecha_agendada)
              .utc()
              .format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  }, [reloadPage]);

  const onAccion = (record, accion) => {
    setModalData(record);
    setIsModalOpened(true);
    setAccionMostrar(accion);
  };

  const columns = [
    // {
    //   title: "_id",
    //   width: 20,
    //   dataIndex: "_id",
    //   key: "_id",
    //   fixed: "left",
    //   showHeader: false,
    //   responsive: [""],
    // },
    {
      title: "Nombre Cliente",
      width: 150,
      dataIndex: "nombre_cliente",
      key: "nombre",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Factura",
      width: 80,
      dataIndex: "factura",
      key: "factura",
      // fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Fecha",
      width: 100,
      dataIndex: "fecha",
      key: "fecha",
      // fixed: "left",
      // responsive: ["sm"],
    },
    {
      title: "Recibida",
      width: 100,
      dataIndex: "fecha_recibida",
      key: "fecha_recibida",
      // fixed: "left",
      // responsive: ["sm"],
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      width: 150,
      // responsive: ["sm"]
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (record) => (
        <>
          <Button
            size="large"
            style={{
              backgroundColor: "#27ae60",
            }}
            onClick={() => onAccion(record, "accion")}
            icon={
              <SendOutlined
                style={{ fontSize: "20px", color: "#ffffff" }}
                theme="outlined"
              />
            }
          ></Button>
        </>
      ),
    },
  ];
  const showModal = (accion) => {
    if (accion === "accion") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddRegistroFrom
            registro={modalData}
            RegistroEstado={"contabilidad"}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
          />
        </CustomModal>
      );
    } else {
      return (
        <CustomModal
          title="Generar Reporte de Agenda"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <ReporteContabilidad
            registros={registros}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
            modulo={accion}
          />
        </CustomModal>
      );
    }
  };

  return (
    <div className="md:mx-10 mx-2">
      {showModal(accionMostrar)}
      <div className="mb-1.5">
        <HeaderModulo
          modulo={"contabilidad"}
          registroEstado={"contabilidad"}
          setReloadPage={setReloadPage}
          estado={true}
          reloadPage={reloadPage}
        />

        <Buscar
          setBuscar={setBuscar}
          modulo={"contabilidad"}
          buscar={buscar}
          onChangeSearch={onChangeSearch}
        />
      </div>

      <Row gutter={12} className="md:mx-10">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            loading={loading}
            rowKey={(record) => record.factura}
            dataSource={registros}
            columns={columns}
            size="small"
            pagination={{ position: ["bottomRight"], pageSize: 50 }}
            style={{ marginTop: 0 }}
            scroll={{ y: 480 }}
            bordered
          />
        </Col>
      </Row>
    </div>
  );
}
