import { useState } from "react";

import { getReporteAgregadoApi } from "../../api/despachoApi";
import { toast } from "react-toastify";
import generatePDF from "../../hooks/generatorPDF";
import { useAuth } from "../../hooks";

export default function ReporteAlmacen(props) {
  const { accessToken } = useAuth();
  const token = accessToken;

  const { setIsModalOpened, modulo, registroEstado } = props;
  const { user } = useAuth();
  const [datos, setDatos] = useState({
    repartidor: "",
    ficha: "",
    fecha: "",
  });

  // console.log(modulo, registroEstado);

  const onChangeHandled = (event) => {
    // console.log(moment(event.target.value).utc().format("YYYY-DD-MM"));
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (formData) => {
    formData.preventDefault();
    await getReporteAgregadoApi(token, {
      modulo: modulo,
      userId: user._id,
      fechaFiltro: datos.fecha,
      estado: true,
      email: user.email,
    })
      .then((resp) => {
        // console.log(resp);
        if (resp?.code !== 200) {
          console.log("No hay datos que mostrar");
        } else {
          generatePDF(resp.datos, modulo);
          // console.log(resp);
        }
        // setLoader(false);
        setIsModalOpened(false);
      })
      .catch((err) => toast.error("Opps! Houston! tenemos un problema.", err));
  };

  return (
    <div className="form-container">
      <div className="form-container__title">Generar Reporte {modulo}</div>
      <form className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="fecha" className="details">
              Fecha
            </span>
            <input
              onChange={onChangeHandled}
              name="fecha"
              className="input"
              id="fecha"
              type="date"
              value={datos.fecha}
              onChangeCapture={(e) =>
                setDatos({ ...datos, fecha: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form__button">
          <input type="submit" value="Registrar" />
        </div>
      </form>
    </div>
  );
}
