import { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";

import { toast } from "react-toastify";

import { getFichasEstadoApi } from "../../../../api/fichasApi";
import { agregarRepartidorApi } from "../../../../api/repartidorApi";
import posicion from "../../../../helpers/posiciones";
import { useAuth } from "../../../../hooks";

export default function AddRepartidor(props) {
  const { setReloadPage, setIsModalOpened, reloadPage } = props;
  const [datos, setDatos] = useState({});
  const [fichas, setFichas] = useState([]);
  const { accessToken } = useAuth();
  const token = accessToken;

  const onSubmit = () => {
    // console.log(datos);

    if (
      !datos.nombre ||
      !datos.apellido ||
      !datos.flota ||
      !datos.telefono_personal ||
      !datos.posicion ||
      !datos.ficha
    ) {
      toast("Todos los campos son obligatorios.");
    } else {
      agregarRepartidorApi(token, datos).then((rest) => {
        if (rest === "La ficha se encuentra vinculada.") {
          toast(rest);
        } else {
          toast(rest);
          setIsModalOpened();
          setReloadPage(!reloadPage);
        }
      });
    }
  };

  useEffect(() => {
    getFichasEstadoApi(token, true).then((resp) => {
      setFichas(resp?.fichas);
    });
    // getRepartidoresEstadoApi(true).then((resp) => {
    //   setRepartidores(resp?.repartidores);
    //   // console.log(resp.repartidores)
    // });
  }, [setIsModalOpened]);

  // console.log(fichas);

  return (
    <div className="m-auto">
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size={"large"}
        onFinish={onSubmit}
        className="m-2"
      >
        <Form.Item label="Nombre" style={{ margin: 0 }}>
          <Input
            // prefix={<UserOutlined />}
            placeholder="Nombre"
            value={datos.nonbre}
            onChange={(e) => setDatos({ ...datos, nombre: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Apellido" style={{ margin: 0 }}>
          <Input
            // prefix={<UserOutlined />}
            placeholder="Apellido"
            value={datos.apellido}
            onChange={(e) => setDatos({ ...datos, apellido: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Numero de Flota" style={{ margin: 0 }}>
          <Input
            // prefix={<UserOutlined />}
            placeholder="Flota"
            value={datos.flota}
            onChange={(e) => setDatos({ ...datos, flota: e.target.value })}
          />
        </Form.Item>
        <Form.Item style={{ margin: 0 }} label="Telefono Personal">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Telefono"
            value={datos.telefono_personal}
            onChange={(e) =>
              setDatos({ ...datos, telefono_personal: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item style={{ margin: 0 }} label="Posicion">
          <Select
            value={datos.posicion}
            onChange={(e) => setDatos({ ...datos, posicion: e })}
          >
            {Object.keys(posicion)?.map((pos) => (
              <Select.Option key={pos} value={pos}>
                {pos}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Ficha Asignada">
          <Select
            value={datos.ficha}
            onChange={(e) => setDatos({ ...datos, ficha: e })}
          >
            {fichas?.map((ficha) => (
              <Select.Option key={ficha._id} value={ficha.ficha}>
                {ficha.ficha}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            className="bg-blue-800 text-2xl font-bold"
            type="primary"
            block
            htmlType="submit"
          >
            Agregar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
