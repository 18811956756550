import { useEffect, useState } from "react";
import { Table, Button } from "antd";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { DeleteOutlined } from "@ant-design/icons";
import { useModalWithData } from "../../../../hooks/useModal";
import { getDetalleNotasAplicadaApi } from "../../../../api/contabilidaApi";

import { exportToCSV } from "../../../../hooks/exportToExcel";
import CustomModal from "../../../modal/CustomModal";

export default function NotasDetalles(props) {
  const { registro, setEstadoModal } = props;

  const [isModalOpened, setModalData] = useModalWithData();
  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accionMostrar, setAccionMostrar] = useState("");
  const currentDate = moment(new Date()).utc().format("YYYYMMDD");
  const fileName = `DatosExportado${currentDate}`;

  useEffect(() => {
    setLoading(true);
    getDetalleNotasAplicadaApi({ query: registro._id }).then((resp) => {
      // console.log(resp);
      if (!resp.datos) {
        // console.log("No hay dato que mostrar");
        setDatos([]);
        setLoading(false);
      } else {
        setDatos(
          resp.datos.map((row) => ({
            DOCUMENTO: row.DOCUMENTO,
            CODIGO: row.CODIGO,
            CLIENTE: row.CLIENTE,
            ANULADO: row.ANULADO,
            APLICACION: row.APLICACION,
            MONTO: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.MONTO}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            FECHA_DOCUMENTO: moment(row.FECHA_DOUCMENTO)
              .utc()
              .format("YYYY-MM-DD"),
            NOMBRE: row.NOMBRE,
            TOTAL: row.MONTO,
          }))
        );
        setLoading(false);
      }
    });

    // setReloadPage(false);
  }, [registro, setEstadoModal]);

  // console.log(datos);
  //la Informaciones son Necesarias

  // const onAccion = (record, accion) => {
  //   setModalData(record);
  //   setEstadoModal(true);
  //   setAccionMostrar(accion);
  // };

  const columns = [
    // {
    //   title: "Cte",
    //   width: 40,
    //   dataIndex: "CLIENTE",
    //   key: "CLIENTE",
    //   // fixed: "left",
    //   responsive: ["sm"],
    // },
    {
      title: "Nombre",
      width: 80,
      dataIndex: "NOMBRE",
      key: "NOMBRE",
      // fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Concepto",
      width: 80,
      dataIndex: "APLICACION",
      key: "APLICACION",
      // fixed: "left",
      // responsive: ["lg"]
    },
    // {
    //   title: "Estado",
    //   width: 4,
    //   dataIndex: "ANULADO",
    //   key: "ANULADO",
    //   // fixed: "left",
    //   responsive: ["sm"],
    // },
    {
      title: "Monto",
      width: 60,
      dataIndex: "MONTO",
      key: "MONTO",
      // fixed: "right",
      // fixed: "left",
      // responsive: ["sm"],
    },
    {
      title: "Fecha",
      width: 40,
      dataIndex: "FECHA_DOCUMENTO",
      key: "FECHA_DOCUMENTO",
      // fixed: "left",
      responsive: ["sm"],
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 80,
    //   render: (record) => (
    //     <>
    //       {/* <Button
    //         style={{
    //           fontSize: "16px",
    //           backgroundColor: "#e8f3f8",
    //           margin: 1,
    //           padding: 0,
    //         }}
    //         // onClick={() => onAccion(record, "editar")}
    //       >
    //         <FormOutlined
    //           style={{ fontSize: "16px", color: "#08c", margin: 5, padding: 0 }}
    //         />
    //       </Button> */}

    //       <Button
    //         style={{
    //           fontSize: "16px",
    //           backgroundColor: "#e8f3f8",
    //           margin: 1,
    //           padding: 0,
    //         }}
    //         onClick={() => onAccion(record, "delete")}
    //       >
    //         <DeleteOutlined
    //           style={{
    //             fontSize: "16px",
    //             color: "#ff5733",
    //             margin: 5,
    //             padding: 0,
    //           }}
    //         />
    //       </Button>
    //     </>
    //   ),
    // },
  ];

  // console.log(notas)
  const showModal = (accion) => {
    if (accion === "delete") {
      return (
        <CustomModal
          title="Eliminar Nota de Credito"
          isActive={isModalOpened}
          handleClose={() => setEstadoModal(false)}
        >
          {/* <AnularNotasForm
            registro={modalData}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
          /> */}
        </CustomModal>
      );
    }
  };
  // console.log(datos.type);
  return (
    <>
      {showModal(accionMostrar)}
      <div style={{ marginBottom: "10px" }}>
        {/* <h2 style={{ display: "inline-block", marginRight: "5px" }}>
          Relacion de Facturas
        </h2> */}
        <Button
          onClick={(e) => exportToCSV(datos, fileName)}
          className="btn-block btn-lg bg-primary"
          style={{ color: "white", textDecoration: "none", margin: 0 }}
        >
          Exportar Excel
        </Button>
      </div>
      <Table
        loading={loading}
        rowKey={(record) => record.DOCUMENTO}
        dataSource={datos}
        columns={columns}
        size="small"
        scroll={{ y: 360 }}
        pagination={{ pageSize: 5 }}
      />
    </>
  );
}
