import { useEffect, useState } from "react";
// import { Modal, Form, Button, Alert } from "react-bootstrap";

import { toast } from "react-toastify";
import { updateUserApi } from "../../../api/loginApi";
import roles from "../../../helpers/roles";
import { useAuth, useForm } from "../../../hooks";
import { BiKey } from "react-icons/bi";

export function EditUser(props) {
  const { registro, setReloadPage, setIsModalOpened } = props;
  const [changePassword, setChangePassword] = useState(false);

  // const { errors } = formState;

  const { accessToken } = useAuth();

  const handleChangePassowrd = () => {
    setChangePassword(!changePassword);
  };

  useEffect(() => {
    if (registro)
      setForm({
        name: registro.name,
        lastname: registro.lastname,
        email: registro.email,
        role: registro.role,
        vendedor: registro.vendedor,
      });
  }, [setIsModalOpened, registro]);
  /* fin de la tabla */

  const validationConfig = {
    name: ["required"],
    lastname: ["required"],
    email: ["email"],
    password: ["minLength"],
    comments: ["minLength", "maxLength"],
  };

  const initialData = {
    name: "",
    lastname: "",
    email: "",
    vendedor: "",
    password: "",
    re_password: "",
  };

  const { form, loading, handleChange, handleSubmit, errors, setForm } =
    useForm(initialData, validationConfig);

  return (
    <div className="w-full">
      <div className="bg-blue-600 px-8 pt-6 pb-8 mb-4 flex justify-between">
        <h2 className="text-lg font-semibold m-auto">Editar Usuario</h2>
      </div>
      <form className="bg-white px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="grid grid-flow-row sm:grid-flow-col gap-3">
            <div className="sm:col-span-4 justify-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Nombre
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="name"
                id="name"
                value={form.name}
                onChange={handleChange}
              />
              {errors.name && <p className="text-red-500">{errors.name}</p>}
            </div>
            <div className="sm:col-span-4 justify-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Apellido
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="lastname"
                value={form.lastname}
                onChange={handleChange}
              />
              {errors.lastname && (
                <p className="text-red-500">{errors.lastname}</p>
              )}
            </div>
          </div>
          <div className="grid grid-flow-row sm:grid-flow-col gap-3 mt-3">
            <div className="sm:col-span-4 justify-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Correo
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="email"
                value={form.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
            </div>
            <div className="sm:col-span-4 justify-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Vendedor
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="vendedor"
                value={form.vendedor}
                onChange={handleChange}
              />
              {errors.vendedor && (
                <p className="text-red-500">{errors.vendedor}</p>
              )}
            </div>
          </div>
          <div className="grid grid-flow-row sm:grid-flow-col gap-3 mt-3">
            <div className="sm:col-span-4 justify-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Selecione un role
                <select
                  value={form.role}
                  name="role"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                  onChange={handleChange}
                >
                  <option value="">Seleccione un rol</option>
                  {Object.keys(roles)?.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </label>

              {errors.role && <p className="text-red-500">{errors.role}</p>}
            </div>
          </div>
          <div className="mt-4">
            <div className="justify-center">
              <button
                onClick={handleChangePassowrd}
                className="bg-yellow-400 font-semibold rounded p-2"
              >
                <BiKey className="m-0 p-0" />
              </button>
            </div>
          </div>
          <div
            className={`grid grid-flow-row sm:grid-flow-col gap-3 mt-3 ${
              !changePassword && "hidden"
            }`}
          >
            <div className="sm:col-span-4 justify-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Contraseña
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="password"
                value={form.password}
                onChange={handleChange}
              />
              {errors.password && (
                <p className="text-red-500">{errors.password}</p>
              )}
            </div>
            <div className="sm:col-span-4 justify-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Repetir Contraseña
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="re_password"
                value={form.re_password}
                onChange={handleChange}
              />
              {errors.password && (
                <p className="text-red-500">{errors.password}</p>
              )}
            </div>
          </div>
        </div>

        <button className="bg-blue-600 p-4 w-full rounded text-xl font-semibold">
          Actualizar
        </button>
      </form>
    </div>
  );
}
