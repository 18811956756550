import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { Nacheros } from "../../../api";
import { useAuth } from "../../../hooks";
import Modal from "../../../components/modal/Modal";

import "./dashbord.scss";

const dataController = new Nacheros();

export default function Dashboard() {
  const [resumen, setResumen] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user, accessToken } = useAuth();

  const token = accessToken;

  const { vendedor, retail } = user;

  const [estadoModal, setEstadoModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    dataController
      .getResumen(token, { vendedor: vendedor, retail: retail })
      .then((resp) => {
        setResumen(
          resp.datos?.map((row) => ({
            _id: row._id,
            cliente: row.cliente,
            nombre: row.nombre,
            articulo: row.articulo,
            descripcion: row.descripcion,
            cantidad_cliente: row.cantidad_cte,
            orden: row.facturaId,
          }))
        );
      });
    setLoading(false);
  }, []);

  // console.log(resumen);

  const confirmDelete = (data) => {
    setDeleteData(data);
  };

  const columns = [
    {
      title: "Cliente",
      width: 20,
      dataIndex: "cliente",
      key: "cliente",
      responsive: ["sm"],
    },
    {
      title: "Nombre",
      width: 80,
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Articulo",
      width: 40,
      dataIndex: "articulo",
      key: "articulo",
    },
    {
      title: "Descripcion",
      width: 40,
      dataIndex: "descripcion",
      key: "descripcion",
      responsive: ["sm"],
    },
    {
      title: "Ctad. Cliente",
      width: 40,
      dataIndex: "cantidad_cliente",
      key: "cantidad_cliente",
      // responsive: ["sm"],
    },
    // {
    //   title: "Accion",
    //   width: 40,
    //   key: "operacion",
    //   // fixed: "right",
    //   render: (record) => (
    //     <>
    //       <button
    //         className="btnAgregar"
    //         onClick={() => confirmDelete(record, setEstadoModal(!estadoModal))}
    //       >
    //         Eliminar
    //       </button>
    //     </>
    //   ),
    // },
  ];

  console.log("Dashboard");

  return (
    <div className="m-4">
      <h2 className="text-2xl m-4">Tablero de Resumen</h2>
      <div className="m-4">
        <Link
          to="vamos-hacer"
          state={{ retail: retail }}
          className="bg-blue-800 m-0 p-2 w-full rounded-lg text-[20px] text-white outline-0 text-center justify-center "
        >
          Agregar
        </Link>
      </div>
      <div className="m-4">
        <Table
          loading={loading}
          rowKey={(record) => record._id}
          dataSource={resumen}
          columns={columns}
          size="small"
          pagination={{ position: ["bottomRight"], pageSize: 50 }}
          style={{ marginTop: 0 }}
          scroll={{ y: 480 }}
          bordered
        />
      </div>
      <Modal
        estadoModal={estadoModal}
        setEstadoModal={setEstadoModal}
        titulo="¿Confirmar que quiere eliminar?"
        mostrarHeader={true}
        mostrarOverlay={true}
      >
        <h1>{deleteData.nombre}</h1>
        <h2>{deleteData.cliente}</h2>
        <div className="table-contenido">
          <table>
            <thead>
              <tr>
                <th>Descripcion</th>
                <th>Cantidad Cliente</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{deleteData.descripcion}</td>
                <td>{deleteData.cantidad_cliente}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <button className="btn">Confirmar</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
