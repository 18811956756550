import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Buttons";
import { Btnsave } from "../../../components/Buttons/Btnsave";
// import Login from "./Login";

export function HomePages() {
  const navigate = useNavigate();
  const goToAdmin = () => {
    navigate("/admin");
  };

  const goToDist = () => {
    navigate("/admin/nacheros");
  };

  return (
    <div className="w-full min-h-[100vh] flex justify-center items-center">
      {/* <video src="../../assets/videos/v1.mp4" autoPlay loop muted /> */}
      {/* <h1>INVERSIONES CATELLI</h1>
      <p>innovando</p> */}
      <div className="flex justify-center items-center gap-7">
        <Btnsave
          titulo="Gestion"
          bgcolor="#ffe000"
          funcion={goToAdmin}
          bcolor="#653df2"
          bzise="2px"
        />
        <Btnsave
          titulo="Ventas"
          bgcolor="#0401fe"
          funcion={goToDist}
          bcolor="#000000"
          bzise="2px"
          tcolor="#fff"
        />
      </div>
    </div>
  );
}
