export const tipoNc = [
    { value: "impulsams", label: "Impulsa MS" },
    { value: "impulsamayorista", label: "Impulsa Mayorista" },
    { value: "impulsa2", label: "Impulsa 2%" },
    { value: "impulsa4", label: "Impulsa 4%" },
    { value: "pagocabezal", label: "Pago Cabezal" },
    { value: "pagopromocionesautori", label: "Pago Promociones Autorizadas" },
    { value: "visibilidadminisuper", label: "Visibilidad MiniSuper" },
    { value: "visibilidadmayorista", label: "Visibilidad Mayorista" },
    { value: "planescolgate", label: "Planes Colgate" },
  ];
  
  export const subtipo = [
    { value: "homecare", label: "Home Care" },
    { value: "personalcare", label: "Personal Care" },
    { value: "oralcare", label: "Oral Care" },
  ];
  
 export  const meses = [
    { value: "enero", label: "Enero" },
    { value: "febrero", label: "Febrero" },
    { value: "marzo", label: "Marzo" },
    { value: "abril", label: "Abril" },
    { value: "mayo", label: "Mayo" },
    { value: "junio", label: "Junio" },
    { value: "julio", label: "Julio" },
    { value: "agosto", label: "Agosto" },
    { value: "septiembre", label: "Septiembre" },
    { value: "octubre", label: "Octubre" },
    { value: "noviembre", label: "Noviembre" },
    { value: "diciembre", label: "Diciembre" },
  ];