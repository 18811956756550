import React from "react";

// import "./table.scss";

function Table({
  data = null,
  columns = null,
  hover = true,
  striped = true,
  actions = null,
  estilo = "",
  loading = false,
}) {
  const getCaps = (head, field) => {
    if (head) return head.toUpperCase();
    return field.toUpperCase();
  };

  // console.log(actions.map((action) => action.label));

  return (
    <>
      <table className="w-full">
        <thead className="bg-gray-50 border-b-2 border-gray-200">
          <tr>
            {columns &&
              columns.map((column) => (
                <th
                  key={column.field}
                  className="p-3 text-sm font-semibold tracking-wide text-left"
                >
                  {getCaps(column.header, column.field)}
                </th>
              ))}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-100">
          {data &&
            data.map((row) => (
              <tr
                key={row._id}
                className={`${hover && "hover:bg-gray-200"} ${
                  striped && "even:bg-gray-200"
                }`}
              >
                {columns.map((col) => (
                  <td
                    key={col.field}
                    className="p-3 text-sm text-gray-700 whitespace-nowrap"
                  >
                    {row[col.field]}
                  </td>
                ))}
                <td className="text-sm text-center text-gray-700 whitespace-nowrap">
                  {actions.map((action) =>
                    action.icon ? (
                      <action.icon
                        key={action.label}
                        className={`inline-block m-1 text-[25px] ${estilo} ${
                          action.color
                        } ${hover && "hover:text-2xl hover:text-blue-400"}`}
                        onClick={() => action.onClick(row)}
                      />
                    ) : (
                      <button
                        key={action.label}
                        className={`${estilo}`}
                        onClick={() => action.onClick(row)}
                      >
                        {action.label}
                      </button>
                    )
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data ? null : <p>No hay data para mostrar :)</p>}
    </>
  );
}

export default Table;
