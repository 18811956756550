import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
// import "./index.css";
import App from "./App";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "antd/dist/reset.css";
import "react-toastify/dist/ReactToastify.css";
// // Callendar
// import "../node_modules/@syncfusion/ej2-base/styles/material.css";
// import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
// import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
// import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
// import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
// import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

import "./scss/styles.scss";


createRoot(document.getElementById("root")).render(<App />);
