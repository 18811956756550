import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks";
import { Nacheros } from "../../../api";
import { Layout, Row, Col, Table } from "antd";
import Buscar from "../../tools/Buscar";
const dataController = new Nacheros();

export default function ListadoProductos() {
  const location = useLocation();
  const [articulo, setArticulo] = useState([]);
  const [loading, setLoading] = useState(false);
  const { accessToken } = useAuth();
  const token = accessToken;
  const { version, nivel_precio } = location.state.cliente;
  const { cliente, opcion } = location.state;

  const [buscar, setBuscar] = useState({
    version: version,
    nivel_precio: nivel_precio,
    buscar: "",
    opcion: opcion,
  });

  const { Content } = Layout;

  console.log("Listado de Productos");

  const onChangeSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    dataController.getProductos(token, { query: buscar }).then((resp) => {
      if (!resp?.datos?.length) {
        setArticulo([]);
        setLoading(false);
      } else {
        setArticulo(
          resp.datos.map((row, index) => ({
            _id: index,
            articulo: row.ARTICULO,
            descripcion: row.DESCRIPCION,
            impuesto: row.IMPUESTO1,
            clasificacion: row.CLASIFICACION_2,
            peso_bruto: row.PESO_BRUTO,
            precio: row.PRECIO,
            grupo: row.GRUPO,
          }))
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    dataController.getProductos(token, { query: buscar }).then((resp) => {
      if (!resp?.datos?.length) {
        setArticulo([]);
        setLoading(false);
      } else {
        setArticulo(
          resp.datos.map((row, index) => ({
            _id: index,
            articulo: row.ARTICULO,
            descripcion: row.DESCRIPCION,
            impuesto: row.IMPUESTO1,
            clasificacion: row.CLASIFICACION_2,
            peso_bruto: row.PESO_BRUTO,
            precio: row.PRECIO,
            grupo: row.GRUPO,
          }))
        );
        setLoading(false);
      }
    });
  }, [token, nivel_precio, version]);

  const columns = [
    {
      title: "Codigo",
      width: 10,
      dataIndex: "articulo",
      key: "articulo",
      fixed: "left",
      // responsive: ["sm"],
    },
    {
      title: "Descripcion",
      width: 20,
      dataIndex: "descripcion",
      key: "descripcion",
      // fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Gr",
      width: 6,
      dataIndex: "grupo",
      key: "grupo",
      // fixed: "left",
      // responsive: ["sm"],
    },
    {
      title: "Cl",
      width: 6,
      dataIndex: "clasificacion",
      key: "clasificacion",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Precio",
      width: 10,
      dataIndex: "precio",
      key: "precio",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Empaque",
      width: 6,
      dataIndex: "peso_bruto",
      key: "peso_bruto",
      // fixed: "left",
      responsive: ["sm"],
    },

    {
      title: "Vamos",
      key: "operation",
      fixed: "right",
      width: 20,
      render: (record) => (
        <>
          <Link
            className="btn-action"
            to="/admin/nacheros/vamos-hacer/procesar"
            state={{ articulo: record, cliente: cliente, opcion: opcion }}
          >
            Agregar
          </Link>
        </>
      ),
    },
  ];

  // console.log(articulo);
  return (
    <>
      <Content className="site-layout" style={{ margin: 20 }}>
        <div className="header">
          <Buscar
            setBuscar={setBuscar}
            modulo={"agendado"}
            buscar={buscar}
            onChangeSearch={onChangeSearch}
          />
        </div>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={articulo}
              columns={columns}
              size="small"
              pagination={{ position: ["bottomRight"], pageSize: 50 }}
              style={{ marginTop: 0 }}
              scroll={{ y: 480 }}
              bordered
            />
          </Col>
        </Row>
      </Content>
    </>
  );
}
