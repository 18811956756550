import { useEffect, useState } from "react";

import { Layout, Button, Table } from "antd";
import {
  FormOutlined,
  EyeOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { Link } from "react-router-dom";
import moment from "moment";
import { NumericFormat } from "react-number-format";

import {
  getNotasCreditoApi,
  getCargaNotasCreditoApi,
} from "../../../../api/contabilidaApi";

import EditarNotasForm from "../../../../components/Gestion/Contabilidad/NotasCreditos/EditarNotasForm";
import NotasDetalles from "../../../../components/Gestion/Contabilidad/NotasCreditos/NotasDetalles";

import AnularNotasForm from "../../../../components/Gestion/Contabilidad/NotasCreditos/AnularNotasForm";
import { HeaderModulo } from "../../../../components/Buttons";
import Modal from "../../../../components/modal/Modal";
import Buscar from "../../../../components/tools/Buscar";

export function NotasCreditos() {
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [notas, setNotas] = useState([]);
  const [accionMostrar, setAccionMostrar] = useState("");

  const [buscar, setBuscar] = useState({
    buscarGlobal: "",
    fecha:
      moment(new Date()).subtract(1, "days").utc().format("YYYY-MM-DD") || "",
    page: 1,
    limit: 100,
  });
  const [isCopied, setIsCopied] = useState(false);
  const [datos, setDatos] = useState("");

  const [estadoModal, setEstadoModal] = useState(false);

  const { Content } = Layout;

  // const [igetsOpenModalAgregar, openModalAgregar, closeModalAgregar] = useModal();

  // console.log(reloadPage);

  const onChangeCarga = async () => {
    setLoading(true);
    getCargaNotasCreditoApi().then((resp) => {
      console.log(resp);
      if (resp.code === 404) {
        toast(resp);
        setLoading(false);
      } else {
        toast(resp);
        setLoading(false);
      }
    });
  };

  const onChangeSearch = async (e) => {
    setLoading(true);
    getNotasCreditoApi({
      query: buscar,
    }).then((resp) => {
      if (!resp?.datos.length) {
        setNotas([]);
        setLoading(false);
      } else {
        setNotas(
          resp.datos.map((row) => ({
            _id: row._id,
            descripcion: row.descripcion,
            mes: row.mes,
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.monto}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            estado: row.estado,
            tipo: row.tipo,
            createdAt: moment(row.createdAt).utc().format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getNotasCreditoApi({
      query: "",
    }).then((resp) => {
      if (!resp?.datos.length) {
        setNotas([]);
        setLoading(false);
      } else {
        setNotas(
          resp.datos.map((row) => ({
            _id: row._id,
            descripcion: row.descripcion,
            mes: row.mes,
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.monto}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            estado: row.estado,
            tipo: row.tipo,
            createdAt: moment(row.createdAt).utc().format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  }, [reloadPage]);

  const onAccion = (record, accion) => {
    setDatos(record);
    // setIsModalOpened(true);
    setAccionMostrar(accion);
    setEstadoModal(!estadoModal);
  };

  const handleCopy = (e) => {
    setIsCopied(true);
    toast(`Se ha copiado el ID # ${e}, use CTRL+V para pegarlo`);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 2000);
  }, [isCopied]);

  const columns = [
    {
      title: "Descripcion",
      width: 150,
      dataIndex: "descripcion",
      key: "descripcion",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Mes",
      width: 60,
      dataIndex: "mes",
      key: "mes",
      // responsive: ["lg"],
    },
    {
      title: "Concepto",
      width: 80,
      dataIndex: "tipo",
      key: "tipo",
      responsive: ["sm"],
    },
    {
      title: "Fecha Creada",
      width: 60,
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["sm"],
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      width: 80,
      // fixed: "right",
      // responsive: ["sm"]
    },
    // {
    //   title: "Aplicado",
    //   dataIndex: "_id",
    //   key: "_id",
    //   width: 80,
    //   responsive: ["sm"],
    //   render: (record) => <FaltanNotasAplicada registro={record} />,
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (record) => (
        <>
          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#e8f3f8",
              margin: 1,
              padding: 0,
            }}
            onClick={() => onAccion(record, "editar")}
          >
            <FormOutlined
              style={{ fontSize: "16px", color: "#08c", margin: 5, padding: 0 }}
            />
          </Button>
          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#e8f3f8",
              margin: 1,
              padding: 0,
            }}
            onClick={() => onAccion(record, "ver")}
          >
            <EyeOutlined
              style={{
                fontSize: "16px",
                color: "#33e6ff",
                margin: 5,
                padding: 0,
              }}
            />
          </Button>

          <Button
            style={{
              fontSize: "16px",
              backgroundColor: "#e8f3f8",
              margin: 1,
              padding: 0,
            }}
            onClick={() => onAccion(record, "delete")}
          >
            <DeleteOutlined
              style={{
                fontSize: "16px",
                color: "#ff5733",
                margin: 5,
                padding: 0,
              }}
            />
          </Button>

          <CopyToClipboard text={record._id} onCopy={handleCopy}>
            <Button
              style={{
                fontSize: "16px",
                backgroundColor: "#e8f3f8",
                margin: 1,
                padding: 0,
              }}
              // onClick={() => (
              //   <CopyToClipboard
              //     text={record._id}
              //     onCopy={handleCopy}
              //   ></CopyToClipboard>
              // )}
            >
              <CopyOutlined
                style={{
                  fontSize: "16px",
                  color: "#17202a",
                  margin: 5,
                  padding: 0,
                }}
              />
            </Button>
          </CopyToClipboard>
        </>
      ),
    },
  ];

  // <CopyOutlined />;

  // console.log(notas)
  const showModal = (accion) => {
    if (accion === "editar") {
      return (
        <Modal
          estadoModal={estadoModal}
          setEstadoModal={setEstadoModal}
          titulo={"Notas de Creditos Aplicada"}
          mostrarHeader={true}
          mostrarOverlay={true}
        >
          <EditarNotasForm
            registro={datos}
            // setIsModalOpened={setIsModalOpened}
            setEstadoModal={setEstadoModal}
            reloadPage={reloadPage}
            setReloadPage={setReloadPage}
          />
        </Modal>
      );
    } else if (accion === "ver") {
      return (
        <Modal
          estadoModal={estadoModal}
          setEstadoModal={setEstadoModal}
          titulo={"Notas de Creditos Aplicada"}
          mostrarHeader={true}
          mostrarOverlay={true}
        >
          <NotasDetalles
            registro={datos}
            // setIsModalOpened={setIsModalOpened}
            setEstadoModal={setEstadoModal}
            setReloadPage={setReloadPage}
          />
        </Modal>
      );
    } else if (accion === "delete") {
      return (
        <Modal
          estadoModal={estadoModal}
          setEstadoModal={setEstadoModal}
          titulo={"Notas de Creditos Aplicada"}
          mostrarHeader={true}
          mostrarOverlay={true}
        >
          <AnularNotasForm
            registro={datos}
            // setIsModalOpened={setIsModalOpened}
            setEstadoModal={setEstadoModal}
            reloadPage={reloadPage}
            setReloadPage={setReloadPage}
          />
        </Modal>
      );
    }
  };
  // console.log(reloadPage);
  return (
    <div className="md:mx-10 mx-2">
      {showModal(accionMostrar)}
      <div className="mb-1.5">
        <HeaderModulo
          modulo={"notacredito"}
          registroEstado={"notacredito"}
          setReloadPage={setReloadPage}
          estado={false}
          reloadPage={reloadPage}
          onChangeCarga={onChangeCarga}
        />

        <Buscar
          setBuscar={setBuscar}
          modulo={"notacredito"}
          buscar={buscar}
          onChangeSearch={onChangeSearch}
        />
      </div>

      <div
        className="site-layout-background"
        style={{ padding: 0, minHeight: 380 }}
      >
        <Table
          loading={loading}
          rowKey={(record) => record._id}
          dataSource={notas}
          columns={columns}
          size="large"
          pagination={{ position: ["bottomRight"] }}
          scroll={{ y: 480 }}
          // style={{ marginTop: 0 }}
        />
      </div>
    </div>
  );
}
