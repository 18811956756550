import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/img/LogoCatelli_Sin_Fondo.png";

function NavBarAdmin(props) {
  const { user } = props;
  const [click, setClick] = useState(false);

  const activeStyle = "underline underline-offset-4";

  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="bg-white text-lg font-extrabold">
      <div className="flex items-center font-medium justify-around md:justify-between md:mx-4">
        <div className="z-50 p-5 w-full md:w-auto  flex justify-between">
          <img src={Logo} alt="logo" className="md:cursor-pointer h-10" />
          <div className="text-3xl md:hidden" onClick={() => setClick(!click)}>
            <ion-icon name={`${click ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-8 ">
          <li>
            <NavLink
              to="/admin"
              className={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/contab"
              className={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Contabilidad
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/almacen"
              className={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Despacho
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/nacheros"
              className={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Ventas
            </NavLink>
          </li>
          {/* <NavLinks /> */}
        </ul>
        {/* Derecha */}
        <ul className="hidden md:flex items-center gap-3">
          <li className="font-semibold text-sm">
            <span>{user.email}</span>
          </li>
        </ul>
        {/* Mobile Nav */}
        <ul
          className={`md:hidden bg-white absolute w-full h-full bottom-0 py-24 pl-4 duration-500 z-10 ${
            click ? "left-0" : "left-[-100%]"
          }`}
        >
          <li>
            <NavLink
              to="/admin"
              className={({ isActive }) =>
                isActive ? activeStyle : `${undefined} py-7 px-3 inline-block`
              }
              onClick={closeMobileMenu}
            >
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/contab"
              className={({ isActive }) =>
                isActive ? activeStyle : `${undefined} py-7 px-3 inline-block`
              }
              onClick={closeMobileMenu}
            >
              Contabilidad
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/almacen"
              className={({ isActive }) =>
                isActive ? activeStyle : `${undefined} py-7 px-3 inline-block`
              }
              onClick={closeMobileMenu}
            >
              Despacho
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/nacheros"
              className={({ isActive }) =>
                isActive ? activeStyle : `${undefined} py-7 px-3 inline-block`
              }
              onClick={closeMobileMenu}
            >
              Ventas
            </NavLink>
          </li>
          {/* <NavLinks /> */}
        </ul>
      </div>
    </nav>
  );
}

export default NavBarAdmin;
