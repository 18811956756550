const roles = {
  admin: "admin",
  regular: "regular",
  contabilidad: "contabilidad",
  almacen: "almacen",
  despacho: "despacho",
  ventas: "ventas",
};

export default roles;
