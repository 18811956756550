import conn from "../helpers/config";
const { basePathApi, apiVersion } = conn;

//Obtener las Notas de Creditos Generada

export function getNotasCreditoApi(data) {
  const url = `${basePathApi}/${apiVersion}/notascredito/`;

  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export function addNotaCreditoApi(data) {
  const url = `${basePathApi}/${apiVersion}/add-notas-credito`;
  console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function upNotasCreditoApi(data, id) {
  const url = `${basePathApi}/${apiVersion}/up-notas-credito/${id}`;

  // console.log(data);
  // console.log({ ultimo });

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getDetalleNotasAplicadaApi(data) {
  const url = `${basePathApi}/${apiVersion}/notascredito/aplicadas`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
    body: JSON.stringify(data),
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getCargaNotasCreditoApi(status) {
  const url = `${basePathApi}/${apiVersion}/notasaplicada/cargar/`;

  console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}
