import { useState } from "react";
import { Modal as ModalAntd } from "antd";
import moment from "moment";

import { toast } from "react-toastify";

// import { getAccessTokenApi } from "../../../../api/auth";
import { addRegistroApi, updateRegistroApi } from "../../../../api/despachoApi";
import { addFechaEntrega } from "../../../../api/facturasApi";
import { useAuth } from "../../../../hooks";

const { confirm } = ModalAntd;

export default function AddFacturaForm(props) {
  const { accessToken, user } = useAuth();
  const token = accessToken;
  const { registro, setReloadPage, setIsModalOpened, reloadPage } = props;

  const [datos, setDatos] = useState({
    fecha: "",
    comentario: registro.comentario,
  });

  const onChangeHandled = (event) => {
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (formData) => {
    formData.preventDefault();

    let fechaRec = moment(datos.fecha).utc().format("YYYY-MM-DD");

    let condicion_pago = parseInt(registro.condicion_pago);
    let new_date = moment(fechaRec).add(condicion_pago, "days");
    let fecha_vencimiento = new_date.format("YYYY-MM-DD");
    // console.log(fecha_vencimiento)
    const data = {
      cliente: registro.cliente,
      factura: registro.factura,
      fechaVencimiento: fecha_vencimiento,
      fechaRecibida: fechaRec,
    };

    if (datos.fecha !== "") {
      setIsModalOpened(false);
      await confirm({
        title: `¿ Es el documento ${registro.factura} ?`,
        content: `registrado al cliente ${registro.nombre_cliente} con el numero de documento ${registro.factura}`,
        okText: "Correcto",
        okType: "link",
        // cancelText: "Cancelar"
        onOk() {
          addFechaEntrega(token, data)
            .then((resp) => {
              if (resp !== 200) {
                addRegistroApi(token, {
                  datos: {
                    idFactura: registro.idFactura,
                    factura: registro.factura,
                    codigo_barra: registro.codigo_barra,
                    total_factura: registro.total_factura,
                    vendedor: registro.vendedor,
                    fecha_agendada: registro.fecha_agendada,
                    cliente: registro.cliente,
                    nombre_cliente: registro.nombre_cliente,
                    ficha: registro.ficha,
                    nombre_repartidor: registro.repartidor,
                    modulo: "contabilidad",
                    RegistroEstado: "contabilidad",
                    TipoDevolucion: registro.tipo,
                    ConceptoDevolucion: registro.concepto,
                    fecha_factura: registro.fecha,
                    comprobante: registro.comprobante,
                    fechaRecibida: fechaRec,
                    fechaVencimiento: fecha_vencimiento,
                    usuario: user._id,
                    _factura: registro._id,
                    comentario: formData.comentario,
                    responsable: user.name + " " + user.lastname,
                    _idUltimo: registro._id,
                    accion: registro.accion,
                    estado: true,
                  },
                }).then((resp) => {
                  updateRegistroApi(
                    token,
                    { fechaVencimiento: fecha_vencimiento, estado: true },
                    registro._id
                  ).then((resp) => {
                    setReloadPage(!reloadPage);
                    toast(resp.message);
                  });
                });
              } else {
                // console.log(resp);
                toast(resp);
              }
            })
            .catch((error) =>
              toast.error("Ops! Hubo error en agregar fecha", error)
            );
        },
      });
    } else {
      toast.warning("Debe llenar todos los campos requerido.");
    }
  };

  // console.log(registro);
  return (
    <div className="form-container">
      <div className="form-container__title">{registro.nombre_cliente}</div>
      <form className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="fecha" className="details">
              Fecha
            </span>
            <input
              onChange={onChangeHandled}
              name="fecha"
              className="input"
              id="fecha"
              type="date"
              value={datos.fecha}
              onChangeCapture={(e) =>
                setDatos({ ...datos, fecha: e.target.value })
              }
            />
          </div>

          <div className="form-container__details-input-box">
            <span htmlFor="comentario" className="details">
              Comentario
            </span>
            <textarea
              onChange={onChangeHandled}
              name="comentario"
              className="input textarea"
              id="fecha"
              rows="10"
              cols="50"
              value={datos.comentario}
              onChangeCapture={(e) =>
                setDatos({ ...datos, comentario: e.target.value })
              }
            ></textarea>
          </div>

          <div className="form__button">
            <input type="submit" value="Registrar" />
          </div>
        </div>
      </form>
    </div>
  );
}
