import React from "react";
import { Link } from "react-router-dom";

export const CustomButton = ({ text, onClick, style, url }) => {
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <Link to={url} style={style}>
      <button onClick={handleClick}>{text}</button>
    </Link>
  );
};
