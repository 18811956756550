import { Layout, Row, Col, Card, Button } from "antd";

// import ChangePassword from "../../components/modal/ChangePassword";
// import DeleteAccount from "../../components/modal/DeleteAccount";
// import EditAccount from "../../components/modal/EditAccount";
// import ProfilePicModal from "../../components/modal/ProfilePicModal";
import roles from "../../helpers/roles";
import { useAuth } from "../../hooks";

export default function AcountPage() {
  const { Content } = Layout;
  const { user } = useAuth();

  return (
    <>
      <Content
        className="site-layout"
        style={{ padding: "0 50px", marginTop: 30 }}
      >
        <Row className="mt-4">
          <Col xs={12} className="text-center">
            <img
              src={user?.profilePic || "/img/male_avatar.svg"}
              alt="profile"
              onClick={"openProfilePic"}
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "50%",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          </Col>
          <Col className="mt-4">
            <Card
              style={{ maxWidth: "360px" }}
              className="mx-auto p-1 border-0"
            >
              <p className="text-center">
                {" "}
                <b>
                  {" "}
                  Nombre: {user.name} {user.lastname}
                </b>
              </p>
              <p className="text-center">
                {" "}
                <b> Correo: {user.email}</b>
              </p>
              <p className="text-center">
                {" "}
                <b> Rol: {user.role} </b>
              </p>

              <Button variant="warning" size="lg">
                {/* onClick={"openEditAcount"} */} Editar Cuenta
              </Button>
              <Button
                variant="link"
                className="mt-1"
                onClick={"openChangePasswordModal"}
              >
                {" "}
                Cambiar contraseña
              </Button>
              {user.role === roles.admin && (
                <Button
                  variant="link"
                  className="mt-3 text-danger"
                  onClick={"openModal"}
                >
                  Eliminar Cuenta
                </Button>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
      {/* <DeleteAccount isOpen={isOpen} closeModal={closeModal} user={user} />
      <ChangePassword
        isOpen={isOpenChangePassword}
        closeModal={closeChangePasswordModal}
        user={user}
      />
      <EditAccount
        isOpen={isOpenEditAcount}
        closeModal={closeEditAcount}
        user={user}
      />
      <ProfilePicModal
        isOpen={isOpenProfilePic}
        closeModal={closeProfilePic}
        user={user}
      /> */}
    </>
  );
}
