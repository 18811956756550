import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";

import { toast, ToastContainer } from "react-toastify";

import { upDateFichaApi } from "../../../../api/fichasApi";
import { useAuth } from "../../../../hooks";

export default function EditFicha(props) {
  const { registro, estadoModal, setReloadPage, reloadPage, setEstadoModal } =
    props;
  const [datos, setDatos] = useState({});

  const { accessToken } = useAuth();
  const token = accessToken;

  useEffect(() => {
    if (registro)
      setDatos({
        ficha: registro.ficha,
        placa: registro.placa,
        modelo: registro.modelo,
        chasis: registro.chasis,
        anno: registro.anno,
      });
  }, [registro, setEstadoModal]);

  const onSubmit = () => {
    upDateFichaApi(token, datos, registro._id).then((rest) => {
      toast(rest.message);
      setEstadoModal(!estadoModal);
      setReloadPage(!reloadPage);
    });
  };

  return (
    <div className="m-auto">
      {/* <ToastContainer /> */}
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size={"middle"}
        onFinish={onSubmit}
        className="m-2"
      >
        <Form.Item label="Ficha">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Ficha"
            value={datos.ficha}
            onChange={(e) => setDatos({ ...datos, ficha: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Placa">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Placa"
            value={datos.placa}
            onChange={(e) => setDatos({ ...datos, placa: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Modelo">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Modelo"
            value={datos.modelo}
            onChange={(e) => setDatos({ ...datos, modelo: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Chasis">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Chasis"
            value={datos.chasis}
            onChange={(e) => setDatos({ ...datos, chasis: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Año">
          <Input
            // prefix={<UserOutlined />}
            placeholder="Año"
            value={datos.anno}
            onChange={(e) => setDatos({ ...datos, anno: e.target.value })}
          />
        </Form.Item>
        <Form.Item className="m-0">
          <Button
            type="primary"
            block
            htmlType="submit"
            className="bg-blue-800 text-2xl font-bold"
          >
            Agregar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
