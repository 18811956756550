import React, { useState } from "react";
import { MdStayCurrentPortrait } from "react-icons/md";
import QRCode from "react-qr-code";

export function QRGenerator() {
  const [text, setText] = useState("");
  const handleChange = (e) => {
    console.log(e.target.value);
    setText(e.target.value);
  };
  return (
    <div className="flex items-center justify-center">
      <div className="w-full items-center flex-none md:w-auto">
        <h2 className="text-center text-3xl">QR Generador</h2>
        <div className="m-2 p-2 border-4 border-black rounded-lg">
          <QRCode
            value={text}
            className="w-full m-0 p-0"
            viewBox={`0 0 256 256`}
          />
        </div>
        <div className="mx-12 text-center rounded-full">
          <button className="bg-blue-800 hover:bg-gray-400 text-white font-bold py-2 px-2 mt-0 rounded-full inline-flex items-center w-full">
            <div className="rounded-full bg-white ">
              <MdStayCurrentPortrait className="m-2 text-black text-2xl" />
            </div>

            <span className="ml-2 text-2xl">SCAN ME</span>
          </button>
        </div>
        <div className="m-2">
          <p className="font-semibold text-center">Introducir el texto aqui</p>
          <input
            placeholder="escribir aqui el texto"
            className="w-full items-center text-2xl text-center p-2 font-semibold border border-spacing-6"
            type="text"
            value={text}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}
