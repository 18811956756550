import { useEffect, useState } from "react";
import { Row, Table, Col, Tag } from "antd";

import { getSearchRegistroApi } from "../../../api/despachoApi";

// import Buscador from "../../../components/tools/Search";
import moment from "moment";
import { NumericFormat } from "react-number-format";

import { nanoid } from "nanoid";

import "./Almacen.scss";
import Buscar from "../../../components/tools/Buscar";
import { HeaderModulo } from "../../../components/Buttons";
import { useAuth } from "../../../hooks";

export default function Almacen() {
  // const { user } = useAuth();
  const { accessToken } = useAuth();
  const token = accessToken;

  const [registro, setRegistros] = useState([]);
  // const [datos, setDatos] = useState([]);
  // const [reloadPage, setReloadPage] = useState(false);
  const [buscar, setBuscar] = useState({
    tipo_busqueda: "factura",
    buscar: "",
  });
  const [loading, setLoader] = useState(false);

  const [reloadPage, setReloadPage] = useState(false);

  const onChangeSearch = async (e) => {
    e.preventDefault();

    setLoader(true);
    getSearchRegistroApi(token, {
      modulo: "custom",
      query: buscar,
      activo: false,
      RegistroEstado: "",
    }).then((resp) => {
      // console.log(resp);
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoader(false);
      } else {
        setRegistros(
          resp?.datos.map((row) => ({
            // ...row,
            uuid: nanoid(),
            _id: row._id,
            cliente: row.cliente,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            total_factura: row.total_factura,
            nombre_repartidor: row.nombre_repartidor,
            fecha_recibida: moment(row.createdAt).utc().format("YYYY-MM-DD"),
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            nombre_vendedor: row.nombre_vendedor,
            comprobante: row.comprobante,
            comentario: row.comentario,
            ficha: row.ficha,
            RegistroEstado: [row?.RegistroEstado],
            modulo: [row?.modulo],
          }))
        );
        setLoader(false);
        // setReloadPage(!reloadPage);
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    getSearchRegistroApi(token, {
      modulo: "custom",
      query: "",
      activo: false,
      RegistroEstado: "",
    }).then((resp) => {
      // console.log(resp);
      if (!resp?.datos.length) {
        setRegistros([]);
        setLoader(false);
      } else {
        setRegistros(
          resp?.datos.map((row) => ({
            // ...row,
            uuid: nanoid(),
            _id: row._id,
            cliente: row.cliente,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            total_factura: row.total_factura,
            nombre_repartidor: row.nombre_repartidor,
            fecha_recibida: moment(row.createdAt).utc().format("YYYY-MM-DD"),
            codigo_barra: row.codigo_barra,
            vendedor: row.vendedor,
            nombre_vendedor: row.nombre_vendedor,
            comprobante: row.comprobante,
            comentario: row.comentario,
            ficha: row.ficha,
            RegistroEstado: [row?.RegistroEstado],
            modulo: [row?.modulo],
          }))
        );
        setLoader(false);
      }
    });
  }, [reloadPage]);

  // useEffect(() => {
  //   setRegistros(datos);
  // }, [datos]); //FQ0138730

  // console.log(registro);

  const columns = [
    {
      title: "Nombre Cliente",
      width: 60,
      dataIndex: "nombre_cliente",
      key: "nombre",
      fixed: "left",
    },
    {
      title: "Factura",
      width: 30,
      dataIndex: "factura",
      key: "factura",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Fecha Movimiento",
      width: 30,
      dataIndex: "fecha_recibida",
      key: "fecha_recibida",
      // fixed: "left",
      responsive: ["sm"],
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      width: 60,
      responsive: ["sm"],
    }, //fecha_factura nombre_repartidor
    {
      title: "Repartidor",
      dataIndex: "nombre_repartidor",
      key: "nombre_repartidor",
      width: 60,
      responsive: ["sm"],
    }, //fecha_factura nombre_repartidor
    {
      title: "Ultimo Estado",
      width: 80,
      dataIndex: "modulo",
      key: "modulo",
      // fixed: "left",
      // responsive: ["sm"],
      render: (estados) => (
        <>
          {/* {console.log(estados)} */}
          {estados?.map((estado) => {
            let e; // = estado === "undefined" ? "red" : "green";
            let est;
            if (estado === "undefined") {
              e = "#000";
              est = "Sin registro";
            } else if (estado === "agendado") {
              e = "#2db7f5";
              est = "Agendado";
            } else if (estado === "despacho") {
              e = "#87d068";
              est = "Despacho";
            } else if (estado === "enruta") {
              e = "#108ee9";
              est = "En el Camion";
            } else if (estado === "enalmacen") {
              e = "#f50";
              est = "En Almacen";
            } else if (estado === "devolucion") {
              e = "#f42366";
              est = "Devoluciones";
            } else if (estado === "contabilidad") {
              e = "#c48368";
              est = "Contabilidad";
            } else if (estado === "pagadaencaja") {
              e = "#f58365";
              est = "Pagada en Caja";
            }
            return (
              <>
                {estado ? (
                  <Tag color={e} key={estado}>
                    {est}
                  </Tag>
                ) : (
                  <Tag color={e} key={e}>
                    No Tiene Movimiento
                  </Tag>
                )}
              </>
            );
          })}
        </>
      ),
    },
  ];

  return (
    <div className="md:mx-10 mx-2">
      <div className="mb-1.5">
        <HeaderModulo
          modulo={"consulta"}
          registroEstado={"consulta"}
          setReloadPage={setReloadPage}
          estado={false}
          reloadPage={reloadPage}
        />

        <Buscar
          setBuscar={setBuscar}
          modulo={"custom"}
          buscar={buscar}
          onChangeSearch={onChangeSearch}
        />
      </div>
      <Row gutter={12} className="md:mx-10">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: 0 }}>
          <Table
            loading={loading}
            rowKey={(record) => record.uuid}
            // rowSelection={(rowSelection, { columnWidth: 30 })}
            dataSource={registro}
            columns={columns}
            size="small"
            pagination={{ position: ["bottomRight"], pageSize: 50 }}
            style={{ marginTop: 0 }}
            bordered
            // sticky={true}
            scroll={{ y: 480 }}
          />
        </Col>
      </Row>
    </div>
  );
}
