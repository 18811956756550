import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCantidadRegistroByModulo } from "../../../../api/despachoApi";
import { useAuth } from "../../../../hooks";
const STYLES = [
  "bx-ghost",
  "btn--outline",
  "btn--test",
  "bxs-dollar-circle",
  "bx-package",
  "bxs-calendar-check",
  "bxs-truck",
  "bx-archive-in",
  "bx-edit-alt",
  "bx-search",
  "bx-edit-alt",
  "bx-check-square",
  "bxl-facebook",
  "bx-registered",
  "bx-trash",
];

export const List = ({ modulo, url, titulo, icon, estado }) => {
  const [count, setCount] = useState();
  const checkStyle = STYLES.includes(icon) ? icon : STYLES[0];
  const { accessToken } = useAuth();
  const token = accessToken;
  //   const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  useEffect(() => {
    const cantidadRegistro = async () => {
      const res = await getCantidadRegistroByModulo(token, {
        modulo: modulo,
        estado: estado,
      }).then((resp) => resp);

      if (res?.code !== 200) {
        setCount({});
      } else {
        setCount(res.datos);
      }
    };
    cantidadRegistro();
  }, [modulo, estado]);

  //   console.log(count);

  return (
    <>
      <Link to={url}>
        <i
          className={`bx ${checkStyle} w-[60px] h-[60px] rounded-full text-[48px] flex justify-center items-center `}
        ></i>
      </Link>
      <Link to={url}>
        <span>
          <h3 className="text-[20px] text-black font-semibold mx-4">
            {titulo}
          </h3>
          <p className="font-semibold text-slate-600 text-lg mx-4">{count}</p>
        </span>
      </Link>
    </>
  );
};
