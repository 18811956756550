import conn from "../helpers/config";
const { basePathApi, apiVersion } = conn;

//Obtener los registro Segun Estado
export function getRegistrosByStateApi(token, filtro, estado) {
  const url = `${basePathApi}/${apiVersion}/registro/todas/?filtro=${filtro}&estado=${estado}`;

  // console.log(filtro);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // console.log(response)
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

//get All Registro
export function getRegistrosAll(token) {
  const url = `${basePathApi}/${apiVersion}/registro/`;

  // console.log(filtro);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // console.log(response)
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getRegistrosByEstadoApi(token, page, limit, filtro, estado) {
  const url = `${basePathApi}/${apiVersion}/registro/estado/?page=${page}&limit=${limit}&filtro=${filtro}&estado=${estado}`;

  // console.log(filtro, estado);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // console.log(response)
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getSearchRegistroApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/registro/search/`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // return console.log(response.json);
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getRegistroByModuloApi(token, modulo) {
  const url = `${basePathApi}/${apiVersion}/registro/bymodulo`;
  // console.log(modulo);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // return console.log(response.json);
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getCantidadRegistroByModulo(token, modulo) {
  const url = `${basePathApi}/${apiVersion}/registro/cantidad`;
  // console.log(modulo);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // return console.log(response.json);
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getCantidadRegistroAgregadoApi(token, modulo) {
  const url = `${basePathApi}/${apiVersion}/registro/cantidad_agregado`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // return console.log(response.json);
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getReporteAgregadoApi(token, modulo) {
  const url = `${basePathApi}/${apiVersion}/registro/reportes`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // return console.log(response.json);
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getRegistros(token) {
  const url = `${basePathApi}/${apiVersion}/registro/registros/`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    //  body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFiltroRegistroApi(token, page, limit, filtro, seccion) {
  const url = `${basePathApi}/${apiVersion}/registro/buscar/?page=${page}&limit=${limit}&filtro=${filtro}&seccion=${seccion}`;
  // console.log(filtro);

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    //  body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getRegistrosByFacturaApi(token, filtro) {
  const url = `${basePathApi}/${apiVersion}/registro/factura/?filtro=${filtro}`;

  // console.log(filtro);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    //  body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // console.log(response)
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getComentarioApi(token, id) {
  const url = `${basePathApi}/${apiVersion}/registro/comentario/?id=${id}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    //  body: JSON.stringify(modulo),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
      // console.log(response)
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

//Agregar Registros
export function addRegistroApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/registro/add-registro`;
  // console.log(data);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function updateRegistroApi(token, data, userId) {
  const url = `${basePathApi}/${apiVersion}/registro/update/${userId}`;

  // console.log(data)

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function addComentarioByIdApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/registro/add-comentario`;
  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function deleteComentarioApi(token, id) {
  const url = `${basePathApi}/${apiVersion}/registro/delete-comment/${id}`;
  // console.log(id);
  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}
