import { Badge, Alert, Space, Button } from "antd";
import { updateRepartidorActiveteApi } from "../../../../api/repartidorApi";
import { useAuth } from "../../../../hooks";
// import { toast } from "react-toastify";

export default function DeleteRepartidor(props) {
  const { setIsModalOpened, registro, setReloadPage, reloadPage } = props;
  const { accessToken } = useAuth();
  const token = accessToken;

  const handleDelete = () => {
    // console.log()
    updateRepartidorActiveteApi(token, registro._id, !registro.active).then(
      (resp) => {
        setReloadPage(!reloadPage);
        setIsModalOpened(!reloadPage);
      }
    );
  };

  return (
    <>
      <Alert
        message="Eliminando"
        description={`¿Esta seguro que desea ${
          !registro.active ? "activar" : "desactivar"
        }
          a ${registro.nombre}?`}
        type="warning"
        showIcon
        action={
          <Space direction="vertical">
            <Button size="small" danger block onClick={() => handleDelete()}>
              <Badge>Eliminar</Badge>
            </Button>
          </Space>
        }
        // closable
      />
      {/* <hr /> */}
    </>
  );
}
