import { useEffect, useState } from "react";
import { Layout, Row, Col, Button, Table, Modal as ModalAntd } from "antd";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { SendOutlined, DeleteOutlined } from "@ant-design/icons";

import {
  getSearchRegistroApi,
  updateRegistroApi,
} from "../../../api/despachoApi";
import AddRegistroForm from "../../../components/Gestion/Almacen/Agendar/AddReAgendarForm";

// import Buscador from "../../../components/tools/Search";
import { useModalWithData } from "../../../hooks/useModal";
import CustomModal from "../../../components/modal/CustomModal";
import ReporteAlmacen from "../../../components/modal/ReporteAlmacen";
import { HeaderModulo } from "../../../components/Buttons";
import { gestionarFacturaApi } from "../../../api/facturasApi";

import Buscar from "../../../components/tools/Buscar";
import { useAuth } from "../../../hooks";

const { confirm } = ModalAntd;

export default function Agendar() {
  const { accessToken, user } = useAuth();
  const token = accessToken;

  const { Content } = Layout;
  const [registros, setRegistros] = useState([]);
  // const [datos, setDatos] = useState([]);
  const [setIsModalOpened, isModalOpened, modalData, setModalData] =
    useModalWithData();

  const [loading, setLoading] = useState(false);
  const [accionMostrar, setAccionMostrar] = useState("");
  const [reloadPage, setReloadPage] = useState(false);
  const [buscar, setBuscar] = useState({
    tipo_busqueda: "factura",
    buscar: "",
  });

  // const stringCodigoBara = buscar?.buscarGlobal;
  // const codigoBarra = stringCodigoBara?.substring(
  //   0,
  //   stringCodigoBara.length - 1
  // );

  const showAnularConfirm = (record) => {
    confirm({
      title: `¿Esta seguro que quiere anular el documento ${record.factura}?`,
      content: `El documento registrado al cliente ${record.nombre_cliente} con el numero de documento ${record.factura}`,
      okText: "Anular",
      okType: "link",
      // cancelText: "Cancelar"
      onOk() {
        gestionarFacturaApi(token, record.idFactura, true, true)
          .then((resp) => {
            updateRegistroApi(
              token,
              {
                idFactura: record.idFactura,
                factura: record.factura,
                nombre_cliente: record.nombre_cliente,
                nombre_vendedor: record.nombre_vendedor,
                codigo_barra: record.codigo_barra,
                vendedor: record.vendedor,
                fecha_agendada: record.fecha_agendada,
                ficha: record.ficha || "",
                nombre_repartidor: record.repartidor || "",
                modulo: "agendar",
                RegistroEstado: "agendado",
                TipoDevolucion: "anulada",
                ConceptoDevolucion: "anulada",
                fechaRecibida: record.fecha,
                comprobante: record.comprobante,
                fechaVencimiento: record.fecha,
                usuario: user.id,
                _factura: record.idFactura,
                comentario: record.comentario,
                responsable: record.responsable,
                estado: true,
              },
              record._id
            )
              .then((resp) => {
                toast(`Documento ${record.factura} fue anulado correctamente.`);
              })
              .catch((error) =>
                toast.error(
                  "Ops! Hubo error en la actualizacion del estado de la factura"
                )
              );
            // toast(resp);
            setIsModalOpened(false);
            setReloadPage(!reloadPage);
          })
          .catch((error) =>
            toast.error(
              "Ops! Hubo error en la actualizacion del estado de la factura"
            )
          );
      },
    });
  };

  const onChangeSearch = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      getSearchRegistroApi(token, {
        modulo: "agendado",
        query: buscar,
        RegistroEstado: "",
        activo: false,
      }).then((resp) => {
        if (!resp?.datos?.length) {
          setRegistros([]);
          setLoading(false);
        } else {
          // console.log(resp.datos[0].accion);
          setRegistros(
            resp.datos.map((row) => ({
              //...row,
              _id: row._id,
              nombre_cliente: row.nombre_cliente,
              factura: row.factura,
              fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
              monto: (
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  value={row.total_factura}
                  prefix="RD$ "
                  decimalSeparator="."
                  displayType="text"
                  // type="text"
                  thousandSeparator={true}
                  allowNegative={true}
                />
              ),
              codigo_barra: row.codigo_barra,
              comprobante: row.comprobante,
              vendedor: row.vendedor,
              fecha_factura: moment(row.fecha_factura)
                .utc()
                .format("YYYY-MM-DD"),
              cliente: row.cliente,
              nombre_vendedor: row.nombre_vendedor,
              comentario: row.comentario,
              repartidor: row.nombre_repartidor,
              total_factura: row.total_factura,
              idFactura: row._factura,
              _idUltimo: row._idUltimo,
              accion: row.accion,
              fecha_agendada: moment(row.fecha_agendada)
                .utc()
                .format("YYYY-MM-DD"),
            }))
          );
          setLoading(false);
        }
      });
    } catch (error) {
      toast("Contactar Soporte....", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSearchRegistroApi(token, {
      modulo: "agendado",
      query: "",
      RegistroEstado: "",
      activo: false,
    }).then((resp) => {
      if (!resp?.datos?.length) {
        setRegistros([]);
        setLoading(false);
      } else {
        setRegistros(
          resp.datos.map((row) => ({
            //...row,
            _id: row._id,
            nombre_cliente: row.nombre_cliente,
            factura: row.factura,
            fecha: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            monto: (
              <NumericFormat
                thousandsGroupStyle="thousand"
                value={row.total_factura}
                prefix="RD$ "
                decimalSeparator="."
                displayType="text"
                // type="text"
                thousandSeparator={true}
                allowNegative={true}
              />
            ),
            codigo_barra: row.codigo_barra,
            comprobante: row.comprobante,
            vendedor: row.vendedor,
            fecha_factura: moment(row.fecha_factura).utc().format("YYYY-MM-DD"),
            cliente: row.cliente,
            nombre_vendedor: row.nombre_vendedor,
            comentario: row.comentario,
            repartidor: row.nombre_repartidor,
            total_factura: row.total_factura,
            idFactura: row._factura,
            _idUltimo: row._idUltimo,
            accion: row.accion,
            fecha_agendada: moment(row.fecha_agendada)
              .utc()
              .format("YYYY-MM-DD"),
          }))
        );
        setLoading(false);
      }
    });
  }, [reloadPage]);

  const onAccion = (record, accion) => {
    setModalData(record);
    setIsModalOpened(true);
    setAccionMostrar(accion);
  };

  const columns = [
    {
      title: "Nombre Cliente",
      width: 100,
      dataIndex: "nombre_cliente",
      key: "nombre",
      fixed: "left",
      // responsive: ["sm"]
    },
    {
      title: "Factura",
      width: 60,
      dataIndex: "factura",
      key: "factura",
      // fixed: "left",
      // responsive: ["lg"]
    },
    {
      title: "Fecha",
      width: 60,
      dataIndex: "fecha",
      key: "fecha",
      // fixed: "left",
      // responsive: ["sm"],
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
    },
    {
      title: "Agendada",
      width: 60,
      dataIndex: "fecha_agendada",
      key: "fecha_agendada",
      // fixed: "left",
      // responsive: ["sm"],
    },
    {
      title: "Accion",
      dataIndex: "accion",
      key: "accion",
      width: 60,
      // responsive: ["sm"],
    },
    {
      title: "Comentario",
      dataIndex: "comentario",
      key: "comentario",
      width: 80,
      // responsive: ["sm"],
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      width: 60,
      // responsive: ["sm"]
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (record) => (
        <>
          <Button
            size="large"
            style={{
              backgroundColor: "#27ae60",
              // marginRight: 3,
              // paddgin: 0,
              padding: 5,
            }}
            onClick={() => onAccion(record, "accion")}
            icon={
              <SendOutlined
                style={{ fontSize: "20px", color: "#ffffff" }}
                theme="outlined"
              />
            }
          ></Button>
          <Button
            size="large"
            style={{
              backgroundColor: "#e8f3f8",
              padding: 5,
            }}
            onClick={() => showAnularConfirm(record)}
            icon={
              <DeleteOutlined
                style={{ fontSize: "20px", color: "#f90000" }}
                theme="outlined"
              />
            }
          ></Button>
        </>
      ),
    },
  ];

  const showModal = (accion) => {
    if (accion === "accion") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddRegistroForm
            registro={modalData}
            modulo={"agendado"}
            RegistroEstado={"despacho"}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
          />
        </CustomModal>
      );
    } else {
      return (
        <CustomModal
          title="Generar Reporte de Agenda"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <ReporteAlmacen
            registros={registros}
            setIsModalOpened={setIsModalOpened}
            //  setReloadPage={setReloadPage}
            modulo={accion}
          />
        </CustomModal>
      );
    }
  };
  // console.log(registros);
  return (
    <>
      <div className="md:mx-10 mx-2">
        {showModal(accionMostrar)}
        <div className="mb-1.5">
          <HeaderModulo
            modulo={"agendado"}
            registroEstado={""}
            setReloadPage={setReloadPage}
            estado={false}
            reloadPage={reloadPage}
          />
          <Buscar
            setBuscar={setBuscar}
            modulo={"agendado"}
            buscar={buscar}
            onChangeSearch={onChangeSearch}
          />
        </div>

        {/* <Buscador setBuscar={setBuscar} modulo={"agendado"} buscar={buscar} /> */}

        <Row gutter={12} className="md:mx-10">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={loading}
              rowKey={(record) => record.factura}
              dataSource={registros}
              columns={columns}
              size="small"
              pagination={{ position: ["bottomRight"], pageSize: 50 }}
              style={{ marginTop: 0 }}
              scroll={{ y: 480 }}
              bordered
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
