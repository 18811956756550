import moment from "moment";
import { useEffect, useState } from "react";
import { getCantidadRegistroAgregadoApi } from "../api/despachoApi";
import { useAuth } from "./useAuth";

export const useCantidadRegistro = (
  modulo,
  registroEstado,
  reloadPage,
  estado
) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isPedding, setIsPedding] = useState(false);
  const { accessToken, user } = useAuth();
  const token = accessToken;
  const currentDate = moment().format("YYYY-MM-DD");
  moment.locale("es");

  // console.log(estado);

  useEffect(() => {
    const getData = async () => {
      setIsPedding(true);
      try {
        const getRegistro = async () => {
          if (modulo) {
            const result = await getCantidadRegistroAgregadoApi(token, {
              modulo: modulo,
              userId: user._id,
              fechaFiltro: currentDate,
              estado: estado,
            }).then((resp) => resp.datos);
            if (!result) {
              setData([]);
            } else {
              setData(result?.length);
              // console.log(result.length);
            }
          }
        };
        getRegistro();
      } catch (err) {
        setIsPedding(true);
        setError(err);
      }
    };
    getData();
  }, [modulo, reloadPage, currentDate, user._id, estado]);

  return { data, error, isPedding };
};
