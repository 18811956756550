import { useEffect, useState } from "react";
import { Form, Select, Input, Button } from "antd";
import { toast } from "react-toastify";
import { upNotasCreditoApi } from "../../../../api/contabilidaApi";
import { tipoNc, meses } from "../../../../helpers/datos";

export default function EditarNotasForm(props) {
  const { registro, setReloadPage, reloadPage, setEstadoModal } = props;
  // console.log(registro);

  const [datos, setDatos] = useState({});

  console.log(registro.monto.props.value);

  useEffect(() => {
    setDatos({
      _id: registro._id,
      tipo: registro.tipo,
      mes: registro.mes,
      monto: registro.monto.props.value,
    });
  }, [registro]);

  // console.log(datos.x//

  const onSubmit = () => {
    if (!datos.tipo || !datos.mes || !datos.monto) {
      toast("Todos los campos son requeridos");
    } else {
      upNotasCreditoApi(
        {
          descripcion: datos.tipo + " " + datos.mes,
          mes: datos.mes,
          monto: datos.monto,
          tipo: datos.tipo,
          subtipo: ["Home Care", "Personal Care", "Oral Care"],
        },
        datos._id
      ).then((resp) => {
        if (resp.code === 200) {
          toast(resp.message);
          // setIsModalOpened(false);
        } else {
          toast("Huston! Parece que hay un error, contactar al soporte");
        }

        setEstadoModal(false);
        setReloadPage(!reloadPage);
      });
    }

    // addNotaCreditoApi()
  };

  return (
    <>
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size={"large"}
        onFinish={onSubmit}
      >
        <Form.Item label="Concepto">
          <Select
            value={datos.tipo}
            onChange={(e) => setDatos({ ...datos, tipo: e })}
          >
            {tipoNc?.map((item) => (
              <Select.Option key={item.value} value={item.label}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Mes">
          <Select
            value={datos.mes}
            onChange={(e) => setDatos({ ...datos, mes: e })}
          >
            {meses?.map((item) => (
              <Select.Option key={item.value} value={item.label}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Monto">
          <Input
            value={datos.monto}
            onChange={(e) => setDatos({ ...datos, monto: e.target.value })}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            htmlType="submit"
            className="text-black bg-blue-600"
          >
            Agregar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
