import conn from "../helpers/config";
const { basePathApi, apiVersion } = conn;

export function getFacturasAllByStateApi(token, data) {
  const url = `${basePathApi}/${apiVersion}/facturas/`; //?page=${page}&limit=${limit}&filtro=${filtro}
  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getParametros(token) {
  const url = `/data/parametros.json`;
  // console.log(filtro)

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
    //  body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

// Agregar Fecha de Recibir la factura en Contabilidad
// export function updateFacturaEntregaApi(data) {
//   const url = `${basePathApi}/${apiVersion}/fecha-entrega/`;

//   // console.log(data);
//   // console.log({ ultimo });

//   const params = {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//       // Authorization: token,
//     },
//     body: JSON.stringify(data),
//   };

//   return fetch(url, params)
//     .then((response) => {
//       return response.json();
//     })
//     .then((result) => {
//       return result;
//     })
//     .catch((err) => {
//       return err.message;
//     });
// }

export function addFechaEntrega(token, data) {
  const url = `${basePathApi}/${apiVersion}/facturas/add-fecha-entrega`;
  // console.log(data);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

// Actualizar Estado y Movimiento de la factura
export function gestionarFacturaApi(token, id, status, movimiento) {
  const url = `${basePathApi}/${apiVersion}/factura/gestionar/${id}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      activo: status,
      movimiento: movimiento,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFacturasByStatusApi(token, filtro) {
  const url = `${basePathApi}/${apiVersion}/factura/estado/?estado=${filtro}`;
  // console.log(filtro)

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify({
    //   activo: status,
    //   movimiento: movimiento,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getCargaFacturasApi(token) {
  const url = `${basePathApi}/${apiVersion}/cargar-facturas/`;

  // console.log(status);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // Authorization: token,
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
