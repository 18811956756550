import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Badge } from "antd";
import { useModalWithData } from "../../hooks/useModal";
import CustomModal from "../modal/CustomModal";
import ReporteAlmacen from "../modal/ReporteAlmacen";
import { useCantidadRegistro } from "../../hooks/useCantidadRegitro";
import AddNotasForm from "../Gestion/Contabilidad/NotasCreditos/AddNotasForm";
import AddFicha from "../Gestion/Almacen/Fichas/AddFicha";
import AddRepartidor from "../Gestion/Almacen/Repartidor/AddRepartidor";

export function HeaderModulo(props) {
  const {
    modulo,
    registroEstado,
    setReloadPage,
    reloadPage,
    estado,
    onChangeCarga,
  } = props;
  const [accionMostrar, setAccionMostrar] = useState("");
  const [setIsModalOpened, isModalOpened, modalData, setModalData] =
    useModalWithData();
  const [title, setTitle] = useState("");
  const [ruta, setRuta] = useState({
    route: "/admin/almacen/",
  });

  let { data } = useCantidadRegistro(
    modulo,
    registroEstado,
    reloadPage,
    estado
  );

  useEffect(() => {
    // getRegistros();
    getRuta();
    switch (modulo) {
      case "agendado":
        setTitle("Agendar Documento");
        break;
      case "despacho":
        setTitle("Despacho");
        break;
      case "enruta":
        setTitle("En Ruta");
        break;
      case "devolucion":
        setTitle("Devolucion");
        break;
      case "enalmacen":
        setTitle("En Almacen");
        break;
      case "facturacion":
        setTitle("Facturacion");
        break;
      case "contabilidad":
        setTitle("Control de Facturas Entregada");
        break;
      case "notacredito":
        setTitle("Nota de Credito");
        break;
      case "fichas":
        setTitle("Mantenimiento de Ficha");
        break;
      case "repartidores":
        setTitle("Mantenimiento de repartidor");
        break;
      case "consulta":
        setTitle("Consulta Aplicacion de Documento");
        break;
      default:
        setTitle("Dashboard");
        break;
    }
  }, [modulo, reloadPage]);

  async function getRuta() {
    switch (modulo) {
      case "contabilidad":
        setRuta({
          ...ruta,
          route: "/admin/contab/",
        });

        break;
      case "notacredito":
        setRuta({
          ...ruta,
          route: "/admin/contab/",
        });
        break;

      default:
        setRuta({
          ...ruta,
          route: "/admin/almacen/",
        });
        break;
    }
  }

  const btnHeader = (m) => {
    switch (m) {
      case "notacredito":
        return (
          <button
            onClick={() => onAccion("notasadd")}
            type="primary"
            htmlType="submit"
            className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
          >
            <div className="text-3xl text-white ">
              <ion-icon name="add"></ion-icon>
            </div>
            {/* <FontAwesomeIcon icon={faAdd} style={{ margin: 10 }} /> */}
          </button>
        );
      // break;
      case "fichas":
        return (
          <button
            onClick={() => onAccion("fichas")}
            type="primary"
            htmlType="submit"
            className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
          >
            <div className="text-3xl text-white ">
              <ion-icon name="add"></ion-icon>
            </div>
          </button>
        );
      // break;
      case "repartidores":
        return (
          <button
            onClick={() => onAccion("repartidores")}
            type="primary"
            htmlType="submit"
            className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
          >
            <div className="text-3xl text-white ">
              <ion-icon name="add"></ion-icon>
            </div>
          </button>
        );
      // break;
      case "agendado":
        return (
          <button
            onClick={() => onAccion(modulo)}
            type="primary"
            htmlType="submit"
            className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
          >
            <div className="text-3xl text-white ">
              <ion-icon name="print"></ion-icon>
            </div>
            {/* <Badge
              count={data}
              shape="square"
              size="large"
              offset={[5, -1]}
              style={{ margin: 10 }}
            ></Badge> */}
          </button>
        );
      case "enalmacen":
        return (
          <button
            onClick={() => onAccion(modulo)}
            type="primary"
            className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
          >
            <div
              className={`${
                data ? "text-3xl text-orange-500 " : "text-3xl text-white "
              }`}
            >
              <ion-icon name="print"></ion-icon>
            </div>
            {/* <Badge
              count={data}
              shape="square"
              size="large"
              offset={[5, -1]}
              style={{ margin: 10 }}
            ></Badge> */}
          </button>
        );
      case "devolucion":
        return (
          <button
            onClick={() => onAccion(modulo)}
            type="primary"
            htmlType="submit"
            className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
          >
            <div
              className={`${
                data ? "text-3xl text-orange-500 " : "text-3xl text-white "
              }`}
            >
              <ion-icon name="print"></ion-icon>
            </div>
            {/* <Badge
              count={data}
              shape="square"
              size="large"
              offset={[5, -1]}
              style={{ margin: 10 }}
            ></Badge> */}
          </button>
        );
      case "despacho":
        return (
          <button
            onClick={() => onAccion(modulo)}
            type="primary"
            htmlType="submit"
            className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
          >
            <div
              className={`${
                data ? "text-3xl text-orange-500 " : "text-3xl text-white "
              }`}
            >
              <ion-icon name="print"></ion-icon>
            </div>
            {/* <Badge
              count={data}
              shape="square"
              size="large"
              offset={[5, -1]}
              style={{ margin: 10 }}
            ></Badge> */}
          </button>
        );
      // break;
      default:
        return <></>;
      // break;
    }
  };

  const btnDownload = (modulo) => {
    switch (modulo) {
      case "notacredito":
        return (
          <div className="header-seccion__div">
            <button
              onClick={() => onChangeCarga()}
              type="primary"
              // htmlType="submit"
              className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
            >
              {/* <FontAwesomeIcon icon={faDownload} style={{ margin: 10 }} /> */}
              <div className="text-3xl text-white ">
                <ion-icon name="cloud-download-outline"></ion-icon>
              </div>
            </button>
          </div>
        );
      case "facturacion":
        return (
          <div className="header-seccion__div">
            <button
              onClick={() => onChangeCarga()}
              type="primary"
              // htmlType="submit"
              className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-3xl w-[48px] h-[48px]"
            >
              <div className="text-3xl text-white ">
                <ion-icon name="cloud-download-outline"></ion-icon>
              </div>
            </button>
          </div>
        );
      // break;
      default:
        return <></>;
    }
  };

  const onAccion = (accion) => {
    setModalData();
    setIsModalOpened(true);
    setAccionMostrar(accion);
  };

  const showModal = (ejcutar) => {
    // console.log(ejcutar);
    if (ejcutar === "notasadd") {
      return (
        <CustomModal
          title="Agregar Notas de Creditos"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddNotasForm
            registro={modalData}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
            reloadPage={reloadPage}
          />
        </CustomModal>
      );
    } else if (ejcutar === "fichas") {
      return (
        <CustomModal
          title="Agregar Ficha"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddFicha
            // registro={fichas}
            reloadPage={reloadPage}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
          />
        </CustomModal>
      );
    } else if (ejcutar === "repartidores") {
      return (
        <CustomModal
          title="Agregar repartidor"
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <AddRepartidor
            // registro={fichas}
            reloadPage={reloadPage}
            setIsModalOpened={setIsModalOpened}
            setReloadPage={setReloadPage}
          />
        </CustomModal>
      );
    } else if (ejcutar === "agendado") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <ReporteAlmacen
            registro={modalData}
            registroEstado={registroEstado}
            setIsModalOpened={setIsModalOpened}
            modulo={modulo}
          />
        </CustomModal>
      );
    } else if (ejcutar === "enalmacen") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <ReporteAlmacen
            registro={modalData}
            registroEstado={registroEstado}
            setIsModalOpened={setIsModalOpened}
            modulo={modulo}
          />
        </CustomModal>
      );
    } else if (ejcutar === "devolucion") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <ReporteAlmacen
            registro={modalData}
            registroEstado={registroEstado}
            setIsModalOpened={setIsModalOpened}
            modulo={modulo}
          />
        </CustomModal>
      );
    } else if (ejcutar === "despacho") {
      return (
        <CustomModal
          title=""
          isActive={isModalOpened}
          handleClose={() => setIsModalOpened(false)}
        >
          <ReporteAlmacen
            registro={modalData}
            registroEstado={registroEstado}
            setIsModalOpened={setIsModalOpened}
            modulo={modulo}
          />
        </CustomModal>
      );
    }
  };

  return (
    <>
      {showModal(accionMostrar)}
      <div className="w-full">
        <div className="bg-white block items-center rounded-lg">
          <div>
            <span className="text-2xl mx-2 font-bold">{title}</span>
          </div>

          <div className="mx-2 py-2 flex justify-normal items-center gap-1">
            <div className="p-2.5 bg-[#05458f] font-semibold rounded-lg text-white text-2xl  w-[48px] h-[48px]">
              <Link to={ruta.route}>
                <ion-icon name="arrow-back"></ion-icon>
              </Link>
            </div>
            {btnHeader(modulo)}
            {btnDownload(modulo)}
          </div>
        </div>
      </div>
    </>
  );
}
