import { Space, Badge, Button, Alert } from "antd";
import { toast } from "react-toastify";
import { gestionarFacturaApi } from "../../../../api/facturasApi";
import { useAuth } from "../../../../hooks";

export default function AnularDeleteForm(props) {
  const { setIsModalOpened, registro, setReloadPage, reloadPage } = props;
  const { accessToken } = useAuth();
  const token = accessToken;

  const handleDelete = () => {
    gestionarFacturaApi(token, registro._id, true, true)
      .then((resp) => {
        toast("Se ha realizado correctamente");
      })
      .catch((error) =>
        toast.error(
          "Ops! Hubo error en la actualizacion del estado de la factura"
        )
      );
    // toast(resp);
    setIsModalOpened(false);
    setReloadPage(!reloadPage);
  };
  return (
    <>
      <>
        <Badge.Ribbon
          placement="start"
          size="large"
          text={registro.factura}
          style={{ fontSize: "20px", marginTop: 10, padding: 0 }}
          className="bg-danger"
        >
          <Alert
            style={{ width: "auto" }}
            message="..."
            description={`¿Esta seguro que deseas anular la factura ${registro.factura} de 
            ${registro.nombre_cliente}?`}
            type="warning"
            // showIcon
            action={
              <Space direction="vertical">
                <Button
                  size="small"
                  danger
                  block
                  onClick={() => handleDelete()}
                >
                  <Badge>Eliminar</Badge>
                </Button>
              </Space>
            }
            // closable
          />
        </Badge.Ribbon>
      </>
      {/* <hr /> */}
      {/* <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size={"large"}
        onFinish={handleDelete}
      >
        <Form.Item>
          <Button type="danger" block htmlType="submit">
            <Badge>Anular Factura {registro.factura}</Badge>
          </Button>
        </Form.Item>
      </Form> */}
    </>
  );
}
