import { useEffect, useState } from "react";
import { Modal as ModalAntd } from "antd";
import { toast } from "react-toastify";
import { addRegistroApi, updateRegistroApi } from "../../../../api/despachoApi";
import { getFichasEstadoApi } from "../../../../api/fichasApi";
import { getRepartidoresEstadoApi } from "../../../../api/repartidorApi";
import { useAuth } from "../../../../hooks";

const { confirm } = ModalAntd;

export default function AddReAgendarForm(props) {
  const { registro, setReloadPage, setIsModalOpened, reloadPage } = props;
  const [fichas, setFichas] = useState([]);
  const [repartidores, setRepartidores] = useState([]);
  const [datos, setDatos] = useState({
    repartidor: "",
    ficha: "",
    fecha: registro.fecha_agendada,
    comentario: registro.comentario,
  });
  const { accessToken, user } = useAuth();
  const token = accessToken;

  const currentDate = new Date();

  const { name, lastname } = user;

  useEffect(() => {
    getFichasEstadoApi(token, true).then((resp) => {
      setFichas(resp?.fichas);
    });
    getRepartidoresEstadoApi(token, true).then((resp) => {
      setRepartidores(resp?.repartidores);
    });
  }, [setIsModalOpened]);

  const onChangeHandled = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setDatos({
      repartidor: "",
      ficha: "",
      fecha: registro.fecha_agendada,
      comentario: registro.comentario,
    });
  }, [registro]);

  const onSubmit = async (formData) => {
    formData.preventDefault();

    // console.log(datos);

    if (
      datos.repartidor === undefined ||
      datos.repartidor === "" ||
      datos.fecha === undefined ||
      datos.fecha === "" ||
      datos.ficha === undefined ||
      datos.ficha === ""
    ) {
      toast.warning("Debe llenar todos los campos requerido.");
    } else {
      setIsModalOpened(false);
      await confirm({
        title: `¿El documento ${registro.factura} sera agendado para despacho?`,
        content: `El documento esta registrado al cliente ${registro.nombre_cliente}.`,
        okText: "Correcto",
        okType: "link",
        // cancelText: "Cancelar"
        onOk() {
          addRegistroApi(token, {
            datos: {
              idFactura: registro.idFactura,
              factura: registro.factura,
              codigo_barra: registro.codigo_barra,
              total_factura: registro.total_factura,
              vendedor: registro.vendedor,
              cliente: registro.cliente,
              nombre_cliente: registro.nombre_cliente,
              comprobante: registro.comprobante,
              ficha: datos.ficha,
              nombre_repartidor: datos.repartidor,
              modulo: "despacho",
              RegistroEstado: "",
              fecha_agendada: registro.fecha_agendada,
              fecha_nueva_agendada: datos.fecha,
              TipoDevolucion: "",
              ConceptoDevolucion: "",
              fecha_factura: registro.fecha_factura,
              fechaRecibida: registro.fecha || currentDate,
              fechaVencimiento: registro.fecha || currentDate,
              usuario: user._id,
              _factura: registro._id,
              comentario: `${datos.comentario}   -> Despachar en Fecha ${datos.fecha} `,
              responsable: name + " " + lastname,
              _idUltimo: registro._id,
              accion: `Documento Agendado para Despachar ${datos.fecha}`,
              estado: false,
            },
          }).then((resp) => {
            // console.log("Insertado Correctamente", resp);
            updateRegistroApi(
              token,
              {
                usuario: user._id,
                estado: true,
                nombre_repartidor: datos.repartidor,
                ficha: datos.ficha,
                comentario: datos.comentario,
                fecha_nueva_agendada: datos.fecha,
                accion: `Documento Agendado para Despachar ${datos.fecha}`,
              },
              registro._id
            )
              .then((resp) => {
                setReloadPage(!reloadPage);
                setDatos([]);
                toast(resp.message);
              })
              .catch((err) => {
                toast.error("Hustion!, Hustion!, Error: ", err);
              });
          });
        },
      });
    }
  };

  // console.log(registro);
  console.log(datos);

  return (
    <div className="w-[100%] h-[650px]">
      {" "}
      <div className="form-container__title">{registro.nombre_cliente}</div>
      <form className="form-container__form" onSubmit={onSubmit}>
        <div className="form-container__details">
          <div className="form-container__details-input-box">
            <span htmlFor="fecha" className="details">
              Fecha
            </span>
            <input
              onChange={onChangeHandled}
              name="fecha"
              className="input"
              id="fecha"
              type="date"
              defaultValue={datos.fecha}
              onChangeCapture={(e) =>
                setDatos({ ...datos, fecha: e.target.value })
              }
            />
          </div>
          <div className="form-container__details-input-box">
            <span htmlFor="repartidor" className="details">
              Repartidor
            </span>
            <select
              onChange={onChangeHandled}
              name="repartidor"
              className="input"
              id="repartidor"
              value={datos.repartidor}
              defaultValue={"nodata"}
              onChangeCapture={(e) =>
                setDatos({ ...datos, repartidor: e.target.value })
              }
            >
              <option value={"nodata"}>Selecciones un Repartidor</option>
              {repartidores?.map((item) => (
                <option
                  key={item._id}
                  value={item.nombre + " " + item.apellido}
                >
                  {item.nombre + " " + item.apellido}
                </option>
              ))}
            </select>
          </div>
          <div className="form-container__details-input-box">
            <span htmlFor="ficha" className="details">
              Ficha
            </span>
            <select
              onChange={onChangeHandled}
              name="ficha"
              className="input"
              id="ficha"
              defaultValue={"nodata"}
              value={datos.ficha}
              onChangeCapture={(e) =>
                setDatos({ ...datos, ficha: e.target.value })
              }
            >
              <option value={"nodata"}>Selecciones una ficha</option>
              {fichas?.map((item) => (
                <option key={item._id} defaultValue={item.ficha}>
                  {item.ficha}
                </option>
              ))}
            </select>
          </div>

          <div className="form-container__details-input-box">
            <span htmlFor="comentario" className="details">
              Comentario
            </span>
            <textarea
              onChange={onChangeHandled}
              name="comentario"
              className="input textarea"
              id="comentario"
              rows="3"
              cols="20"
              value={datos.comentario}
              onChangeCapture={(e) =>
                setDatos({ ...datos, comentario: e.target.value })
              }
            ></textarea>
          </div>
          <div className="form__button">
            <input type="submit" value="Registrar" />
          </div>
        </div>
      </form>
    </div>
  );
}
